import api from './index';

class VendorService {
  getVendor() {
    return api.get('/vendor').then((response) => {
      return response.data;
    });
  }

  updateVendor(data) {
    return api.put('/vendor', data).then((response) => {
      return response.data;
    });
  }

  listProducts() {
    return api.get('/vendor/product').then((response) => {
      return response.data;
    });
  }

  getProduct(id) {
    return api.get(`/vendor/product/${id}`).then((response) => {
      return response.data;
    });
  }

  createProduct() {
    return api.post('/vendor/product').then((response) => {
      return response.data;
    });
  }

  updateProduct({ id, data }) {
    return api.put(`/vendor/product/${id}`, data).then((response) => {
      return response.data;
    });
  }

  deleteProduct(id) {
    return api.delete(`/vendor/product/${id}`).then((response) => {
      return response.data;
    });
  }

  listOrders({ startDate, endDate }) {
    let path = `/vendor/order`;

    if (startDate && endDate) {
      path = `/vendor/order?startDate=${startDate}&endDate=${endDate}`;
    }

    return api.get(path).then((response) => {
      return response.data;
    });
  }

  getOrder(id) {
    return api.get(`/vendor/order/${id}`).then((response) => {
      return response.data;
    });
  }

  updateOrder({ id, data }) {
    return api.put(`/vendor/order/${id}`, data).then((response) => {
      return response.data;
    });
  }

  getStripeAccountLink(data) {
    return api.post('/vendor/stripeAccountLink', data).then((response) => {
      return response.data;
    });
  }

  listScheduleClose({ start, end }) {
    let path = `/vendor/schedule_close`;

    if (start && end) {
      path = `/vendor/schedule_close?start=${start}&end=${end}`;
    }

    return api.get(path).then((response) => {
      return response.data;
    });
  }

  createUpdateScheduleClose({ groupDate, dates }) {
    const data = { groupDate, dates };
    return api.post(`/vendor/schedule_close`, data).then((response) => {
      return response.data;
    });
  }

  listQuote(data) {
    return api.get(`/vendor/quote?date=${data}`).then((response) => {
      return response.data;
    });
  }

  createQuote(data) {
    return api.post('/vendor/quote', data).then((response) => {
      return response.data;
    });
  }

  getQuote(id) {
    return api.get(`/vendor/quote/${id}`).then((response) => {
      return response.data;
    });
  }
}

export default new VendorService();
