import { isNotNullUndefined } from './nullUndefined';

export function createUrlWithParams(path, params) {
  if (isNotNullUndefined(params)) {
    const flattenParams = (obj, parentKey = '') => {
      const queryParams = {};

      Object.keys(obj).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (isNotNullUndefined(obj[key]) && obj[key] !== '') {
          if (typeof obj[key] === 'object') {
            // Recursively flatten if the value is an object
            Object.assign(queryParams, flattenParams(obj[key], fullKey));
          } else {
            queryParams[fullKey] = obj[key];
          }
        }
      });

      return queryParams;
    };

    const queryParams = new URLSearchParams(flattenParams(params)).toString();
    return `${path}?${queryParams}`;
  }
  return `${path}`;
}
