import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import orderService from '../../services/order.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const submitOrder = createAsyncThunk('order/submit_order', async (data, { rejectWithValue }) => {
  try {
    return await orderService.submitOrder(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: {
    /** Submit order reducer **/
    [submitOrder.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [submitOrder.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [submitOrder.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
