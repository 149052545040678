import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';

import { showToast } from '../../../store/slices/components.slice';
import { getOrder, updateOrder } from '../../../store/slices/vendor.slice';
import { classNames, statusFormatter } from '../../../utils';
import { Rate } from 'antd';
import { ModalVendor } from '../../../components/Modal';
import CourierIcon from '../../../assets/images/icons8-courier-48.png';
import Image from '../../../components/Image';

const OrderInfo = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const { vendorApi } = useSelector((state) => state);
  const orderStatus = ['DELIVERED', 'PICKED_UP', 'COMPLETED'].includes(order.status);
  const [modalOpen, setModulOpen] = useState(false);

  useEffect(() => {
    dispatch(getOrder(id))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setOrder(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  }, []);

  const handleStatusText = (currentStatus) => {
    switch (currentStatus) {
      case 'PAID':
        if (order?.selfPickup) {
          return 'Customer 	picked up';
        }
        return 'On the way';
      case 'ON_THE_WAY':
        return 'Delivered';
      default:
        return '';
    }
  };

  const handleChangeStatus = (currentStatus) => {
    let data = {};

    switch (currentStatus) {
      case 'PAID':
        if (order?.selfPickup) {
          data['action'] = 'PICKED_UP';
        } else {
          data['action'] = 'ON_THE_WAY';
        }
        break;
      case 'ON_THE_WAY':
        let location = {};
        data['action'] = 'DELIVERED';
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(({ coords }) => {
            location['lat'] = coords.latitude;
            location['lng'] = coords.longitude;
          });
        }
        data['location'] = location;
        break;
      default:
        break;
    }

    if (data.action) {
      dispatch(updateOrder({ id, data }))
        .unwrap()
        .then((result) => {
          const { data } = result;
          setOrder(data);
          dispatch(showToast({ show: true, type: 'success', message: 'The order status has been updated!' }));
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    }
  };

  return (
    <>
      <LoadingScreen show={vendorApi.loading} />
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to="/store/orders">Orders</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium">{id}</li>
      </ul>
      <div className="space-y-6 max-w-3xl mx-auto py-6 mb-20 px-4 sm:px-6 lg:px-8 min-h-screen">
        <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md">
          <div className="grid grid-cols-4 py-6 px-4 sm:px-6 gap-4 sm:gap-4">
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">Order ID</span>
              <p className="text-sm text-gray-500">{id}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">Status</span>
              <p className="text-sm text-500" style={{ color: statusFormatter(order?.status).color }}>
                {statusFormatter(order?.status)?.text}
              </p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Customer</span>
              <p className="text-sm text-gray-500 capitalize">{order?.customer?.name}</p>
              <p className="text-sm text-gray-500">{order?.customer?.phoneNumber}</p>
              <p className="text-sm text-gray-500">{order?.customer?.email}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">{order?.selfPickup ? 'Pickup Date' : 'Delivery Date'}</span>
              <p className="text-sm text-gray-500">{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('DD MMM YYYY')}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">{order?.selfPickup ? 'Pickup Time' : 'Delivery Time'}</span>
              <p className="text-sm text-gray-500">{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('hh:mm A')}</p>
            </div>
            {order?.pickupTimeExpectation && (
              <div className="col-span-4 sm:col-span-1">
                <span className="font-medium text-sm">Pickup Time</span>
                <p className="text-sm text-gray-500">{order?.pickupTimeExpectation} +-</p>
              </div>
            )}
            <div className="col-span-4 sm:col-span-2">
              {order?.selfPickup ? (
                <>
                  <span className="text-sm text-green-500 ">SELF PICKUP</span>
                </>
              ) : (
                <>
                  <span className="font-medium text-sm">Delivery Address </span>
                  <p className="text-sm text-gray-500">{`${order?.address1}, ${order?.address2}, ${order?.postalCode} ${order?.city}, ${order?.state}`}</p>
                  <p className="text-xs mt-1 font-medium">{`(${order?.location?.lat}, ${order?.location?.lon}) ~ ${order?.distance?.toFixed(2)} KM`}</p>
                </>
              )}
            </div>
            {order?.lalamoveOrder?.shareLink && (
              <div className="col-span-4 sm:col-span-2">
                <span className="font-medium text-sm">Delivery Partner</span>
                <a href={`${order?.lalamoveOrder?.shareLink}`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className="h-5 w-5 rounded-full object-cover" src={CourierIcon} alt="" />
                    <span className="text-sm text-primary-600 hover:text-amber-600">Lalamove Id: {order?.lalamoveOrder?.orderId}</span>
                  </p>
                </a>
                Status : {order?.lalamoveOrder?.status}
              </div>
            )}
          </div>
          <div className={classNames(orderStatus ? 'block' : 'hidden')}>
            {order.reviewRating ? (
              <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4 ">
                <div className="grid grid-cols-4  sm:px-6 gap-4 sm:gap-4 ">
                  <div className="col-span-4 sm:col-span-2 ">
                    <p className="font-medium">Rate</p>
                    <Rate disabled value={order.reviewRating} />
                  </div>
                  <div className="col-span-4 sm:col-span-2">
                    <p className="font-medium">Comment</p>
                    <p className="mt-1 text-gray-500">{order.reviewComment || ''}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4 ">
                <p className="font-medium">Your order is not reviewed yet</p>
              </div>
            )}
          </div>
          <div className="border-t flex-1 py-6 overflow-y-auto px-4 sm:px-6">
            <div>
              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {order?.products &&
                    order?.products.map((product, index) => (
                      <li key={index} className="py-6 flex">
                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                          <img src={product.info.picture.path.s} alt={product.info.name} className="w-full h-full object-center object-cover" />
                        </div>

                        <div className="ml-4 flex-1 flex flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>{product.info.name}</h3>
                              <p className="ml-4">RM {Number(product.price * product.quantity).toFixed(2)}</p>
                            </div>
                            <p className="mt-1 text-sm text-gray-500">
                              Qty {product.quantity} x RM {product.price.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex-1 flex items-end justify-between text-sm">
                            <p className="text-gray-900 font-medium">
                              Remark: <span className="text-gray-500 font-normal">{product.remark || 'Not provided'}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  {(order?.deliveryPrice || order?.deliveryPriceTier?.price) && (
                    <li className="py-6 flex">
                      <div className="ml-4 flex-1 flex flex-col">
                        <div>
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              Delivery Charge <p className="text-gray-600">({order?.lalamoveOrder?.status ? 'Partner Manage' : 'Self Manage'})</p>
                            </h3>
                            <p className="ml-4">RM {order?.deliveryPrice.toFixed(2) || order?.deliveryPriceTier?.price.toFixed(2)}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="text-sm text-gray-900">
                <p className="font-medium">Special instruction:</p>
                <p className="mt-1 text-gray-500">{order?.orderRemark || 'Not provided'}</p>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="flex justify-between text-l font-medium text-gray-500">
                <p>Discount</p>
                <p className="font-bold">RM {order?.discountQuantity?.toFixed(2)}</p>
              </div>
              <p>{order?.discountDescription}</p>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="flex justify-between text-l font-medium text-gray-500">
                <p>Total Price</p>
                <p className="font-bold">RM {order?.totalPrice?.toFixed(2)}</p>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="flex justify-between text-xl font-medium text-gray-500">
                <p>Vendor Collection</p>
              </div>
              <div className="flex justify-between text-xl font-medium text-gray-900">
                <p>Order Price</p>
                <p className="font-bold">RM {order?.vendorCollectionTotalPrice?.toFixed(2) || order?.totalPrice?.toFixed(2)}</p>
              </div>
              <div className="flex justify-between text-l font-medium text-gray-500">
                <p>Service Fee {order?.serviceChargePercentage}</p>
                <p className="font-bold">(RM {order?.serviceChargeFee?.toFixed(2)})</p>
              </div>
              <div className="flex justify-between text-xl font-medium text-gray-900">
                <p>Net Price</p>
                <p className="font-bold">RM {order?.netPrice?.toFixed(2)}</p>
              </div>
            </div>
          </div>
          {!order?.lalamoveOrder?.status && (
            <div className={classNames(orderStatus && 'hidden', 'pb-4 sm:pb-6 px-4 sm:px-6')}>
              <button
                type="button"
                onClick={() => setModulOpen(true)}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {handleStatusText(order?.status)}
              </button>
              <ModalVendor
                show={modalOpen}
                title="Confirmation!"
                closeable={false}
                confirmText={handleStatusText(order?.status)}
                cancelText="back"
                confirmAction={() => {
                  handleChangeStatus(order?.status);
                  setModulOpen(false);
                }}
                value={order}
                closeAction={() => setModulOpen(false)}
              ></ModalVendor>
            </div>
          )}
        </div>
      </div>
      <div className="lg:ml-64 z-10 fixed bottom-0 bg-gray-900 inset-x-0 px-6 py-4 flex items-center justify-between border-t">
        <div className="grid grid-cols-4 gap-6 w-full">
          <a
            href={`https://api.whatsapp.com/send?phone=${order?.customer?.phoneNumber}`}
            target="_blank"
            rel="noreferrer"
            className="col-span-1 flex flex-col items-center justify-center text-center space-y-1 text-white hover:font-semibold"
          >
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>WhatsApp</title>
              <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
            </svg>
            <span className="text-xs">Whatsapp</span>
          </a>
          <a
            href={`tel:${order?.customer?.phoneNumber}`}
            target="_blank"
            rel="noreferrer"
            className="col-span-1 flex flex-col items-center justify-center text-center space-y-1 text-white hover:font-semibold"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              ></path>
            </svg>
            <span className="text-xs">Call</span>
          </a>
          <a
            href={`https://waze.com/ul?q=${`${order?.address1}, ${order?.address2}, ${order?.postalCode} ${order?.city}, ${order?.state}`}&ll=${order?.location?.lat},${
              order?.location?.lon
            }&navigate=yes`}
            target="_blank"
            rel="noreferrer"
            className="col-span-1 flex flex-col items-center justify-center text-center space-y-1 text-white hover:font-semibold"
          >
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>Waze</title>
              <path d="M13.218 0C9.915 0 6.835 1.49 4.723 4.148c-1.515 1.913-2.31 4.272-2.31 6.706v1.739c0 .894-.62 1.738-1.862 1.813-.298.025-.547.224-.547.522-.05.82.82 2.31 2.012 3.502.82.844 1.788 1.515 2.832 2.036a3 3 0 0 0 2.955 3.528 2.966 2.966 0 0 0 2.931-2.385h2.509c.323 1.689 2.086 2.856 3.974 2.21 1.64-.546 2.36-2.409 1.763-3.924a12.84 12.84 0 0 0 1.838-1.465 10.73 10.73 0 0 0 3.18-7.65c0-2.882-1.118-5.589-3.155-7.625A10.899 10.899 0 0 0 13.218 0zm0 1.217c2.558 0 4.967.994 6.78 2.807a9.525 9.525 0 0 1 2.807 6.78A9.526 9.526 0 0 1 20 17.585a9.647 9.647 0 0 1-6.78 2.807h-2.46a3.008 3.008 0 0 0-2.93-2.41 3.03 3.03 0 0 0-2.534 1.367v.024a8.945 8.945 0 0 1-2.41-1.788c-.844-.844-1.316-1.614-1.515-2.11a2.858 2.858 0 0 0 1.441-.846 2.959 2.959 0 0 0 .795-2.036v-1.789c0-2.11.696-4.197 2.012-5.861 1.863-2.385 4.62-3.726 7.6-3.726zm-2.41 5.986a1.192 1.192 0 0 0-1.191 1.192 1.192 1.192 0 0 0 1.192 1.193A1.192 1.192 0 0 0 12 8.395a1.192 1.192 0 0 0-1.192-1.192zm7.204 0a1.192 1.192 0 0 0-1.192 1.192 1.192 1.192 0 0 0 1.192 1.193 1.192 1.192 0 0 0 1.192-1.193 1.192 1.192 0 0 0-1.192-1.192zm-7.377 4.769a.596.596 0 0 0-.546.845 4.813 4.813 0 0 0 4.346 2.757 4.77 4.77 0 0 0 4.347-2.757.596.596 0 0 0-.547-.845h-.025a.561.561 0 0 0-.521.348 3.59 3.59 0 0 1-3.254 2.061 3.591 3.591 0 0 1-3.254-2.061.64.64 0 0 0-.546-.348z" />
            </svg>
            <span className="text-xs">Waze</span>
          </a>
          <a
            href={`https://maps.google.com/?q=${order?.location?.lat},${order?.location?.lon}`}
            target="_blank"
            rel="noreferrer"
            className="col-span-1 flex flex-col items-center justify-center text-center space-y-1 text-white hover:font-semibold"
          >
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>Google Maps</title>
              <path d="M19.527 4.799c1.212 2.608.937 5.678-.405 8.173-1.101 2.047-2.744 3.74-4.098 5.614-.619.858-1.244 1.75-1.669 2.727-.141.325-.263.658-.383.992-.121.333-.224.673-.34 1.008-.109.314-.236.684-.627.687h-.007c-.466-.001-.579-.53-.695-.887-.284-.874-.581-1.713-1.019-2.525-.51-.944-1.145-1.817-1.79-2.671L19.527 4.799zM8.545 7.705l-3.959 4.707c.724 1.54 1.821 2.863 2.871 4.18.247.31.494.622.737.936l4.984-5.925-.029.01c-1.741.601-3.691-.291-4.392-1.987a3.377 3.377 0 0 1-.209-.716c-.063-.437-.077-.761-.004-1.198l.001-.007zM5.492 3.149l-.003.004c-1.947 2.466-2.281 5.88-1.117 8.77l4.785-5.689-.058-.05-3.607-3.035zM14.661.436l-3.838 4.563a.295.295 0 0 1 .027-.01c1.6-.551 3.403.15 4.22 1.626.176.319.323.683.377 1.045.068.446.085.773.012 1.22l-.003.016 3.836-4.561A8.382 8.382 0 0 0 14.67.439l-.009-.003zM9.466 5.868L14.162.285l-.047-.012A8.31 8.31 0 0 0 11.986 0a8.439 8.439 0 0 0-6.169 2.766l-.016.018 3.665 3.084z" />
            </svg>
            <span className="text-xs">Google Maps</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
