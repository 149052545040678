class AuthService {
  isAuthenticated() {
    const token = JSON.parse(localStorage.getItem('token'));
    const access_token = token?.access_token;

    if (access_token) {
      return true;
    } else {
      return false;
    }
  }

  logout(history) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('guest');
    localStorage.removeItem('email-guest');
    sessionStorage.clear();
    // Log out endpoint here
    history.push('/');
    setTimeout(() => {
      window.location.reload(true);
    }, 500);
  }
}

export default new AuthService();
