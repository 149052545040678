import api from './index';
import { createUrlWithParams } from '../helper/url';

class CustomerService {
  listProducts() {
    return api.get('/customer/public/product').then((response) => {
      return response.data;
    });
  }

  listProductsV2(params) {
    // flatten location param
    if (params.location && params.location.lat && params.location.lon) {
      params.location = JSON.stringify(params.location);
    }
    let url = createUrlWithParams('/customer/public/product-v2', params);
    return api.get(url).then((response) => {
      return response.data;
    });
  }

  listMarketingBanner() {
    let url = createUrlWithParams('/customer/public/marketing');
    return api.get(url).then((response) => {
      return response.data;
    });
  }

  listProductsByLocation(coordinate) {
    return api.get(`/customer/public/product?location=${coordinate}`).then((response) => {
      return response.data;
    });
  }

  listVendorProducts(id) {
    return api.get(`/customer/public/product?vendorUid=${id}`).then((response) => {
      return response.data;
    });
  }

  getVendorProfile({ id, location }) {
    let path = `/customer/public/vendor/${id}`;

    if (location) {
      path = `/customer/public/vendor/${id}?location=${location}`;
    }

    return api.get(path).then((response) => {
      return response.data;
    });
  }

  getVendorScheduleClose({ id, start, end }) {
    let path = `/customer/public/vendor/${id}/schedule_close?start=${start}&end=${end}`;
    return api.get(path).then((response) => {
      return response.data;
    });
  }

  getVendorCapacity({ id, date }) {
    return api.get(`/customer/vendor_capacity?vendorUid=${id}&date=${date}`).then((response) => {
      return response.data;
    });
  }

  listOrders() {
    return api.get('/customer/order').then((response) => {
      return response.data;
    });
  }

  getOrder({ id }) {
    return api.get(`/customer/order/${id}`).then((response) => {
      return response.data;
    });
  }

  requestLalamoveQuotation({ vendorUid, deliverDate, deliverTime }) {
    return api.post(`/customer/lalamove_quotation`, { vendorUid, deliverDate, deliverTime }).then((response) => {
      return response.data;
    });
  }

  makePayment({ id, returnSuccessUrl, returnCancelUrl }) {
    return api.post(`/customer/order/${id}/payment`, { returnSuccessUrl, returnCancelUrl }).then((response) => {
      return response.data;
    });
  }

  cancelPayment(id) {
    return api.post(`/customer/order/${id}/cancel_payment`).then((response) => {
      return response.data;
    });
  }

  submitReview(id, data) {
    if (!data.reviewComment) {
      data.reviewComment = '';
    }
    return api.post(`/customer/order/${id}/submit_review`, data).then((response) => {
      return response.data;
    });
  }

  retrieveImpersonateSession(token) {
    return api.post(`/admin/service/retrieve-impersonate-session`, { token }).then((response) => {
      return response.data;
    });
  }

  getQuote({ id }) {
    return api.get(`/customer/quote/${id}`).then((response) => {
      return response.data;
    });
  }

  acceptQuote(data) {
    return api.post(`/customer/quote/accept`, data).then((response) => {
      return response.data;
    });
  }
}

export default new CustomerService();
