import { Route, Redirect } from 'react-router-dom';
import authService from '../../services/auth.service';
export const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />;
};

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const loginUrl = `${process.env.REACT_APP_AUTH_BASE_URL}/login?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;

  if (authService.isAuthenticated()) {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />;
  } else {
    localStorage.setItem('current_path', window.location.pathname);
    window.location.href = loginUrl;
    return null;
  }
};

const NotAuthorized = () => {
  return (
    <section id="protected-page" className="flex items-center justify-center relative bg-white h-screen">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-7xl">
        <p className="text-base font-semibold tracking-wider text-primary-500 uppercase">401 Error</p>
        <h2 className="mt-2 text-3xl sm:text-5xl font-extrabold text-primary-600 tracking-tight">Unauthorized.</h2>
        <p className="my-5 text-base text-gray-500 sm:leading-10 sm:text-xl lg:text-lg xl:text-xl">
          This page was is not publicly available. Please log in or sign up first to access it.
        </p>
        <a href="/" className="text-base text-primary-500 hover:text-primary-600 font-semibold sm:text-xl lg:text-lg xl:text-xl">
          Go back home →
        </a>
      </div>
    </section>
  );
};
