class TokenService {
  getLocalRefreshToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    return token?.refresh_token;
  }

  getLocalAccessToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    return token?.access_token;
  }

  updateLocalAccessToken(token) {
    let newToken = JSON.parse(localStorage.getItem('token'));
    newToken.access_token = token;
    localStorage.setItem('token', JSON.stringify(newToken));
  }

  getToken() {
    return JSON.parse(localStorage.getItem('token'));
  }

  setToken(token) {
    localStorage.setItem('token', JSON.stringify(token));
  }
}

export default new TokenService();
