import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import StoreProductsCreate from './Create';

import Table from '../../../components/Table';
import Image from '../../../components/Image';

import { listProducts } from '../../../store/slices/vendor.slice';
import { showToast } from '../../../store/slices/components.slice';

import StoreProductsUpdate from './Update';

const StoreProducts = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const { vendorApi } = useSelector((state) => state);

  useEffect(() => {
    handleListVendors();
  }, []);

  const handleListVendors = () => {
    dispatch(listProducts())
      .unwrap()
      .then((result) => {
        const { data } = result;
        setTableData(data.products);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const tableHeaders = [
    {
      title: 'Product name',
      objPropKey: 'uid',
      render: (uid, { isApproval, picture, name, newData }) => (
        <div className="flex items-center">
          {newData?.picture?.path?.s || picture?.path?.s ? (
            <Image
              src={newData?.picture?.path?.s || picture?.path?.s}
              alt={`${newData?.name}-productPhoto`}
              className="h-[48px] w-[48px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
            />
          ) : (
            <div className="rounded-full bg-primary-100 p-2 w-[48px] h-[48px] ring-1 ring-primary-600 ring-opacity-10">
              <svg className="w-full h-full text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
              </svg>
            </div>
          )}
          <Link className="ml-4" to={`/store/products/${uid}`}>
            <div className="text-sm font-medium text-primary-600 hover:text-primary-700">{newData?.name || name || uid}</div>
            {isApproval ? (
              newData ? (
                <span className="mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Awaiting Verification</span>
              ) : null
            ) : newData ? (
              <span className="mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-rose-100 text-rose-600 uppercase">Awaiting approval</span>
            ) : null}
          </Link>
        </div>
      ),
    },
    {
      title: 'Price (RM)',
      objPropKey: 'price',
      render: (price, { newData }) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700">{newData?.price?.toFixed(2) || price?.toFixed(2) || 'Not provided'}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Lead time',
      objPropKey: 'leadTime',
      render: (leadTime, { newData }) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700">{newData?.leadTime || leadTime || 'Not provided'}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Load usage',
      objPropKey: 'loadUsage',
      render: (loadUsage, { newData }) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700">{newData?.loadUsage || loadUsage || 'Not provided'}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Visibility',
      objPropKey: 'isVisible',
      render: (isVisible, { newData }) => (
        <span>
          {isVisible ? (
            <svg className="w-6 h-6 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg className="w-6 h-6 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </span>
      ),
    },
    {
      title: 'Availability',
      objPropKey: 'isAvailable',
      render: (isAvailable, { newData }) => (
        <span>
          {isAvailable ? (
            <svg className="w-6 h-6 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg className="w-6 h-6 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Products</h2>
        <Link
          to="/store/products/create"
          className="flex items-center text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 px-3 py-2 rounded-md text-sm font-medium"
        >
          <svg className="-ml-1 mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          New product
        </Link>
      </div>
      <div className="mt-6 flex flex-col">
        <Table dataInput={tableData} headers={tableHeaders} pagination={{ limit: 10, pageNeighbours: 1 }} loading={vendorApi.loading}>
          <div className="flex flex-col items-center justify-center py-10 bg-white w-full text-gray-600">
            <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"></path>
              <path
                fillRule="evenodd"
                d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <h3 className="mt-2 font-medium">No result found</h3>
          </div>
        </Table>
      </div>
    </>
  );
};

StoreProducts.Create = StoreProductsCreate;
StoreProducts.Update = StoreProductsUpdate;

export default StoreProducts;
