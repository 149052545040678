export function isArray(arr) {
  return Array.isArray(arr);
}

export function isArrayEmpty(arr) {
  return isArray(arr) && arr.length === 0;
}

export function isArrayNotEmpty(arr) {
  return isArray(arr) && arr.length !== 0;
}
