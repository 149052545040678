const TermOfUse = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto flex-start justify-center bg-white border-l border-r">
        <div className="w-full sm:grid sm:place-content-center ">
          <div className="max-w-6xl text-left px-8 lg:px-0 space-y-6">
            <h1 className="font-bold text-3xl pt-8">Platform Terms of Use</h1>

            <p className="font-bold">Last updated: 23 June 2023</p>

            <h2 className="font-bold text-xl ml-4">1. INTRODUCTION</h2>

            <p class="ml-6">
              These Platform Terms of Use govern your use of the websites provided by NUBESCOM Sdn Bhd (referred to as &quot;NUBES,&quot; &quot;we,&quot; or &quot;us&quot;)
              collectively known as the &quot;Platform.&quot; The Services we provide or make available include (a) the Platform, and (b) the services provided by the Platform
              and/or by NUBES and made available through the Platform. Any new features added to the Services are also subject to these Terms. These Terms govern your use of
              Services provided by NUBES.
            </p>

            <p class="ml-6">
              Please read these Terms carefully. By accessing and using the Platform, you agree that you have read, understood, and accepted the Terms and consent to the processing
              of your personal data in accordance with the terms set out in the Privacy Policy available at&nbsp;<a href="https://nubes.my/privacy-policy">NUBES Privacy Policy</a>.
              If you do not agree with the Terms, please do not access or use the Platform or the Services.
            </p>

            <p class="ml-6">
              The Platforms may be used by (i) persons who have reached 18 years of age (ii) persons who have not reached 18 years of age however with consent from parent(s) or
              legal guardian(s) and (ii) corporate legal entities under Malaysia SSM, e.g., companies.
            </p>

            <p class="ml-6">
              Users below the age of 18 must obtain consent from parent(s) or legal guardian(s), who, by accepting these Terms, agree to take responsibility for your actions and
              any charges associated with your use of the Platforms and/or purchase of Goods. If you do not have consent from your parent(s) or legal guardian(s), you must stop
              using/accessing the Platforms immediately.
            </p>

            <p class="ml-6">
              NUBES reserves the right to change or modify these Terms (including our policies which are incorporated into these Terms) at any time. You are strongly recommended to
              read these Terms regularly. You will be deemed to have agreed to the amended Terms by your continued use of the Platforms following the date on which the amended
              Terms are posted.
            </p>

            <p class="ml-6">1.1 WHO WE ARE</p>

            <p class="ml-6">These Terms form the contract between you and the following NUBES entities: NUBES Malaysia (Nubes Food).</p>

            <p class="ml-6">1.2 WHAT WE DO</p>

            <p class="ml-6">
              Through our Platform, NUBES links you to NUBES food vendors (&quot;NUBES Partners&quot;) for you to order a variety of goods, including prepared meals, non-prepared
              food, and miscellaneous food-related items (here in after collectively referred to as &quot;Goods&quot;), to be delivered or collected by you. When you place an order
              for Goods from our NUBES Partners (&quot;Order&quot;), NUBES acts as an agent on behalf of that NUBES Partner to facilitate, process, and conclude the order and
              subsequently for either us through our delivery partner or the NUBES Partners to deliver your Order to you. NUBES Partners may be owned and operated by third-party
              NUBES Partners, our affiliate companies, or us.
            </p>

            <p class="ml-6">1.3 HOW TO CONTACT US</p>

            <p class="ml-6">For customer support, you may reach out to us via email or through our website admin support contact details.</p>

            <h2 className="font-bold text-xl ml-4">2. USE OF THE PLATFORMS AND NUBES ACCOUNT</h2>

            <p class="ml-6">2.1 Registration and NUBES Account</p>

            <p class="ml-6">
              2.1.1 To access and use the Platform, you are required to register for a NUBES account. During the registration process, we will collect your personal information,
              including a valid email address, a mobile phone number, and a unique password. If you choose to make a purchase, you may also be asked to provide your credit or debit
              card details, depending on the selected payment method. You agree that your unique password will remain confidential and not be shared with anyone. You are solely
              responsible for maintaining the security of your password and ensuring its secrecy. Except in cases of fraud or abuse not attributable to you, you acknowledge that
              all Orders placed under your NUBES account are at your sole responsibility.
            </p>

            <p class="ml-6">2.2 Accuracy of Information</p>

            <p class="ml-6">
              2.2.1 NUBES shall not be held liable for any delivery issues related to Orders resulting from incomplete, incorrect, or missing information provided by you. It is
              your responsibility to provide complete, accurate, and truthful information to ensure the proper processing of the Order, including your delivery address and contact
              details.
            </p>

            <p class="ml-6">2.3 Account Deletion and Termination</p>

            <p class="ml-6">
              2.3.1 If you wish to delete your NUBES account, please send us an email with your request. We reserve the right to restrict, suspend, or terminate your NUBES account
              and/or your use of the Platforms if we reasonably believe that:
            </p>

            <p class="ml-6">2.3.1.1 Someone other than you is using your NUBES account, or</p>

            <p class="ml-6">
              2.3.1.2 You are suspected or found to have engaged in any activity or conduct that violates these Terms, our policies and guidelines, or is considered by us, at our
              sole discretion, as an abuse of the Platforms, or
            </p>

            <p class="ml-6">
              2.3.1.3 You are suspected or found to have engaged in any illegal activities which is prohibited under the local, state or federal laws and regulations, while using
              the Platform and your NUBES account
            </p>

            <h2 className="font-bold text-xl ml-4">3. RESTRICTIONS</h2>
            <p class="ml-6">3.1 Prohibited Activities on the Platforms</p>

            <p class="ml-6">
              The following list outlines prohibited activities on the Platforms. Please note that this is a non-exhaustive list, and NUBES reserves the right to take appropriate
              legal action, as determined in NUBES&apos;s sole discretion, against individuals engaged in any prohibited activities. Prohibited activities include, but are not
              limited to:
            </p>

            <p class="ml-6">3.1.1 Using the Platforms in violation of local, state, or federal laws or regulations.</p>

            <p class="ml-6">
              3.1.2 Posting or communicating any content that infringes upon the intellectual property rights, privacy rights, publicity rights, trade secret rights, or any other
              rights of any party.
            </p>

            <p class="ml-6">
              3.1.3 Posting or communicating unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful, or embarrassing content about any person or
              entity, as determined by NUBES in its sole discretion or pursuant to local community standards.
            </p>

            <p class="ml-6">3.1.4 Posting or communicating content that constitutes cyberbullying, as determined by NUBES in its sole discretion.</p>

            <p class="ml-6">3.1.5 Posting or communicating content that depicts dangerous, life-threatening, or otherwise risky behaviour.</p>

            <p class="ml-6">3.1.6 Posting or communicating telephone numbers, street addresses, or last names of any person.</p>

            <p class="ml-6">3.1.7 Posting or communicating URLs to external websites or any form of HTML or programming code.</p>

            <p class="ml-6">3.1.8 Posting or communicating anything that may be considered &quot;spam,&quot; as determined by NUBES in its sole discretion.</p>

            <p class="ml-6">3.1.9 Impersonating another person when posting content.</p>

            <p class="ml-6">3.1.10 Harvesting or collecting information about others, including email addresses, without their consent.</p>

            <p class="ml-6">3.1.11 Allowing any other person or entity to use your identification for posting or viewing comments.</p>

            <p class="ml-6">3.1.12 Harassing, threatening, stalking, or abusing any person on the Platforms.</p>

            <p class="ml-6">
              3.1.13 Engaging in any conduct that restricts or inhibits any other person from using or enjoying the Platforms, or that, in the sole discretion of NUBES, exposes
              NUBES, its customers, suppliers, or any other parties to any form of liability or detriment.
            </p>

            <p class="ml-6">3.1.14 Encouraging others to engage in any prohibited activities as described herein.</p>

            <p class="ml-6">3.2 NUBES&apos;s Rights and Actions</p>

            <p class="ml-6">
              3.2.1 NUBES reserves the right, but is not obligated, to investigate any allegation that content posted on the Platforms does not conform to these Terms and, at its
              sole discretion, remove or request the removal of such content.
            </p>

            <p class="ml-6">3.2.2 NUBES may remove content that is deemed abusive, illegal, disruptive, or fails to conform with these Terms.</p>

            <p class="ml-6">3.2.3 NUBES may suspend or terminate a user&apos;s access to the Platforms or their NUBES Account in the event of any breach of these Terms.</p>

            <p class="ml-6">3.2.4 NUBES may monitor, edit, or disclose any content on the Platforms.</p>

            <p class="ml-6">3.2.5 NUBES may edit or delete any content posted on the Platforms, regardless of whether such content violates these standards.</p>

            <h2 className="font-bold text-xl ml-4">&nbsp;4. Ownership of Intellectual Property</h2>
            <p class="ml-6">
              All trademarks, logos, images, and service marks displayed on the Platforms or in our marketing materials, including these Terms, whether registered or unregistered,
              are the intellectual property of NUBES and/or third parties who have authorised us to use them (collectively referred to as the &quot;Trademarks&quot;). You are
              prohibited from using, copying, reproducing, republishing, uploading, posting, transmitting, distributing, or modifying these Trademarks in any way without our prior
              express written consent. Unauthorised use of NUBES&apos;s trademarks on any other website not approved by us is strictly prohibited. NUBES will take vigorous measures
              to protect its intellectual property rights to the fullest extent permitted by law, including pursuing criminal prosecution when necessary. NUBES does not warrant or
              represent that your use of materials displayed on the Platforms will not infringe upon the rights of third parties who are not owned by or affiliated with NUBES. Your
              use of any materials on the Platforms is at your own risk.
            </p>

            <h2 className="font-bold text-xl ml-4">5. RESTRICTIONS ON GOODS</h2>

            <p class="ml-6">5.1 Restricted Goods</p>

            <p class="ml-6">
              Some of the Goods we offer on our Platforms are subject to restrictions for purchase (&quot;Restricted Goods&quot;), depending on the applicable laws of the country
              you purchase the Restricted Goods from, including restrictions imposed by Malaysia law. These restrictions include minimum age requirements for alcohol/alcoholic
              products and any other goods that we reserve the right not to deliver to you based on the relevant statutory requirements of the time being in force.
            </p>

            <p class="ml-6">5.2 Alcohol / Alcoholic Products</p>

            <p class="ml-6">The purchase of alcohol or alcoholic products is not allowed on our Platforms. We do not sell or deliver alcohol or alcoholic products.</p>

            <p class="ml-6">5.3 Cigarettes/Tobacco Products</p>

            <p class="ml-6">The purchase of cigarettes or tobacco products is not allowed on our Platforms. We do not sell or deliver cigarettes or tobacco products.</p>

            <h2 className="font-bold text-xl ml-4">6. ORDERS</h2>
            <p class="ml-6">6.1 Placing Orders:</p>

            <p class="ml-6">
              When you place an order with NUBES, NUBES will confirm your order by sending you a confirmation WhatsApp message containing the order receipt. Your order may include
              delivery fees and any applicable taxes, such as goods and services tax or value-added tax.
            </p>

            <p class="ml-6">
              6.2 NUBES reserves the right to cancel any Order and/or suspend, deactivate or terminate your NUBES account in its sole discretion if it reasonably suspects or
              detects fraudulent behaviour or activity associated with your NUBES account and/or with your Order.
            </p>

            <p class="ml-6">6.3 Allergens:</p>

            <p class="ml-6">
              NUBES is not obligated to provide ingredient information or allergen information on the platform. Furthermore, NUBES does not guarantee that the goods sold by NUBES
              Partners are free of allergens. If you have allergies, allergic reactions, or dietary restrictions and requirements, please contact the NUBES Partners before placing
              an order on our platforms.
            </p>

            <p class="ml-6">6.4 Additional Terms and Conditions:</p>

            <p class="ml-6">Please note that your order may be subjected to additional terms and conditions provided by the NUBES Partners.</p>

            <p class="ml-6">6.5 Prior to Placing the Order:</p>

            <p class="ml-6">6.5.1 You are required to provide the delivery address to display the available NUBES Partners in your delivery area on the platform.</p>

            <p class="ml-6">6.5.2 Once you select a NUBES Partner, you will be directed to their menu page where you can select and add your desired goods to the cart.</p>

            <p class="ml-6">6.6 Placing the Order:</p>

            <p class="ml-6">
              To complete an order, please follow the on-screen instructions after clicking &apos;Checkout&apos;. You may need to provide additional details to complete your order.
              It is your responsibility to review and confirm that all the information you provide, including the quantities, delivery details, personal details, payment
              information, and voucher codes (if applicable), is true, accurate, and complete before clicking &quot;PLACE ORDER.&quot; An order is considered successfully placed
              when you receive an email confirmation containing your order receipt from us.
            </p>

            <p class="ml-6">6.7 Cancellation:</p>

            <p class="ml-6">
              6.7.1 Customer: If you wish to cancel your order after it has been placed, please contact our customer support immediately. A 10% penalty fee will be charged based on
              the total price. However, after 168 hours (7 days), you are not allowed to cancel your order but can request to bring it forward to a later date.
            </p>

            <p class="ml-6">
              6.7.2 Vendor: Cancellation of accepted orders is NOT ALLOWED. In the event that a vendor cancels an order, the vendor will be responsible for bearing the full total
              order cost.
            </p>

            <p class="ml-6">6.9 Refunds:</p>

            <p class="ml-6">
              If you are entitled to a refund according to the refund and cancellation policies outlined in these terms, NUBES will submits refund requests to your customer&rsquo;s
              bank or card issuer. Your refund as a credit will be refunded approximately 5-10 business days later, depending upon the bank.&nbsp;
            </p>

            <p class="ml-6">6.10 Vendor Responsibility:</p>

            <p class="ml-6">
              Please note that the vendors are responsible for the quality, safety, and legality of the goods they provide. NUBES acts as an intermediary platform and does not
              assume any responsibility for any issues related to the goods sold by the vendors. Any complaints, disputes, or issues regarding the goods should be addressed
              directly with the respective vendors. NUBES will provide reasonable assistance and support in facilitating communication between customers and vendors to resolve any
              concerns.
            </p>

            <h2 className="font-bold text-xl ml-4">7. PRICES AND PAYMENTS</h2>

            <p class="ml-6">
              7.1 Prices quoted on the Platform shall be displayed in MYR (Malaysian Ringgit), the applicable country&apos;s national currency, and subject to applicable tax.
              Prices on the Platforms may vary depending on the inclusion of any promotions, discounts and/or offers by the NUBES partners (either on their own website, mobile
              applications, or at their brick-and-mortar outlets), however the final prices shall always be shown on the Checkout page.
            </p>

            <p class="ml-6">7.2 The way we display the prices of our Goods may vary depending on the NUBES Partners, and the prices reflected on our Platforms may:</p>

            <p class="ml-6">7.2.1 include SST, or such other equivalent tax; or</p>

            <p class="ml-6">7.2.2 exclude SST, or such other equivalent tax.</p>

            <p class="ml-6">7.3 Delivery fees are chargeable on every Order unless:</p>

            <p class="ml-6">7.3.1 you opt to collect your Order directly from the NUBES Partners (&quot;Pick-Up&quot;);</p>

            <p class="ml-6">7.3.2 you have a valid promotional or discount voucher which waives and/or is equivalent to delivery fee and apply it at Checkout; or</p>

            <p class="ml-6">7.3.3 unless stated otherwise.</p>

            <p class="ml-6">
              7.4 Prices and additional charges (including but not limited to Delivery, Small Order or Service fees) indicated on the Platforms are as at the time of each Order and
              may be subject to change.
            </p>

            <p class="ml-6">
              7.5 A breakdown of the prices and additional charges (including but not limited to Delivery, Small Order or Service fees) are displayed before Checkout. When you
              place an Order, you agree to all amounts, additional charges, and the final &apos;Total&apos; amount displayed to you.
            </p>

            <p class="ml-6">7.6 You can choose to pay for an Order using any of the different payment methods offered on the Platforms, including:</p>

            <p class="ml-6">7.6.1 Our payment partners</p>

            <p class="ml-6">7.6.2 Such other payment method we offer from time to time.</p>

            <p class="ml-6">
              7.7 If you have existing credit in your NUBES account or valid promotional or discount vouchers, you can use this to pay for part or all of your Order, as the case
              may be.
            </p>

            <p class="ml-6">
              7.8 After an Order is successfully placed, you will receive an email/WhatsApp confirmation from us with your Order receipt. Delivery fees will not appear in your
              Order receipt if you opt for Pick-Up.
            </p>

            <p class="ml-6">7.9 Payment Methods</p>

            <p class="ml-6">
              NUBES reserves the right to offer additional payment methods and/or remove existing payment methods at any time in its sole discretion. If you choose to pay using an
              online payment method, the payment shall be processed by our third-party payment service provider(s). With your consent, your credit card/payment information will be
              stored with our third-party payment service provider(s) for future orders. NUBES does not store your credit card or payment information.
            </p>

            <p class="ml-6">7.10 You must ensure that you have sufficient funds in MYR on your credit and debit card to fulfil payment of an Order.</p>

            <p class="ml-6">
              7.11 Payment Disputes: If you have any payment disputes or concerns regarding charges for your pre-order, please contact NUBES customer support for assistance. NUBES
              will investigate the matter and work towards resolving any payment-related issues in accordance with the applicable rules and regulations set forth by the Central
              Bank of Malaysia (BNM).
            </p>

            <h2 className="font-bold text-xl ml-4">8. DELIVERY, PICK-UP, AND NUBES PARTNER&apos;S DELIVERY</h2>

            <p class="ml-6">8.1 Delivery Areas</p>

            <p class="ml-6">
              You understand that our NUBES Partners offer their Goods in specific delivery areas, and the availability of NUBES Partners may vary from one delivery area to
              another. By entering your delivery address on the Platforms, you will see the NUBES Partners available to you within a specific distance.
            </p>

            <p class="ml-6">8.2 Delivery Time</p>

            <p class="ml-6">
              NUBES delivery partners will deliver your Order to the delivery address provided by you. You may choose your preferred delivery schedule. However, please note that
              delivery times may vary depending on factors beyond our control, such as weather conditions and traffic conditions.
            </p>

            <p class="ml-6">8.3 Unsuccessful or Failed Deliveries</p>

            <p class="ml-6">8.3.1 In cases where we attempt to deliver an Order but are unable to do so due to reasons caused by you, including but not limited to:</p>

            <p class="ml-6">(i) No one was present or available to receive the Order;</p>
            <p class="ml-6">(ii) You were uncontactable despite attempts to reach you via the phone number provided;</p>
            <p class="ml-6">(iii) Lack of appropriate or sufficient access to deliver the Order successfully;</p>
            <p class="ml-6">(iv) Lack of a suitable or secure location to leave the Order; or</p>
            <p class="ml-6">
              (v) In the case of Restricted Goods, you did not meet the statutory age requirements or it was deemed unsafe or inappropriate for you to receive the Restricted Goods.
            </p>

            <p class="ml-6">
              We will contact you via call, message, or email to inform you of the unsuccessful delivery and provide further instructions. In such cases, NUBES reserves the right
              to cancel the Order without refund or remedy.
            </p>

            <p class="ml-6">8.3.2 No-show Cancellations</p>

            <p class="ml-6">
              If you remain uncontactable or fail to receive the Order within ten (10) minutes from the time the Order arrives at your delivery address, NUBES reserves the right to
              either place the product at your requested address or cancel the Order without refund or remedy.
            </p>

            <p class="ml-6">8.4 Wrong Order, Missing Items, Defective Goods</p>

            <p class="ml-6">
              Upon receipt of your Order, if you discover any issues with your Order (e.g., wrong order, missing items, or defective goods), please contact customer support
              immediately using the methods indicated in Clause 1.3 above. In some cases, NUBES may request photographic proof and/or additional information to properly investigate
              the issue. If we determine that the Order and/or Goods you received are unsatisfactory in condition or quality, we will compensate you accordingly.
            </p>

            <p class="ml-6">8.5 Order Pick-Up</p>

            <p class="ml-6">
              8.5.1 Where available, you have the option to collect your Order in-person directly from the NUBES Partner&apos;s premises (&quot;Pick-Up&quot;) instead of having it
              delivered to you. Your email confirmation will indicate the designated time for you to pick up the Order (&quot;Collection Time&quot;). The NUBES Partner will prepare
              the Order by the Collection Time. Please note that a reasonable delay may be expected. The NUBES Partner agrees to hold the Order for you at their premises for a
              reasonable period of twenty (20) minutes from the Collection Time (&quot;Holding Time&quot;). If you fail to pick up your Order within the Holding Time, the NUBES
              Partner is not obliged to provide the Order to you.
            </p>

            <p class="ml-6">
              8.5.2 In the event of unreasonable delays in Pick-Up attributable to you, you bear the risk of any damage, loss of Goods, deterioration in quality, or change in
              condition of the Goods (e.g., changes in temperature suitable for consumption). In such cases, you are not entitled to a replacement, refund, or replacement of the
              Goods. It is your responsibility to inspect the Goods/Order when picking it up and report any issues or defects to the NUBES Partner before leaving their premises.
            </p>

            <p class="ml-6">8.6 NUBES Partners Delivery</p>

            <p class="ml-6">
              In some cases, our NUBES Partners may directly deliver the Order to you (&quot;NUBES Partners Delivery&quot;). While we will make reasonable efforts to provide prior
              notice of NUBES Partners Delivery, this may not always be possible. If NUBES Partners Delivery applies, we may ask you to contact the NUBES Partner directly in case
              of any issues or delivery delays. Please note that NUBES shall not be responsible for Orders or Goods that are delivered by NUBES Partners.
            </p>

            <h2 className="font-bold text-xl ml-4">9. VOUCHERS, DISCOUNTS AND PROMOTIONS</h2>

            <p class="ml-6">9.1 Vouchers</p>

            <p class="ml-6">
              9.1.1 NUBES may offer vouchers, coupon codes, or promotional codes (&quot;Vouchers&quot;) that can be applied to your Orders on the Platforms. Vouchers may have
              specific terms and conditions, including validity periods, usage limits, and restrictions on their applicability to certain Goods or NUBES Partners. Such terms and
              conditions will be clearly stated when the Voucher is provided.
            </p>

            <p class="ml-6">
              9.1.2 Vouchers are non-transferable, cannot be exchanged for cash, and cannot be combined with other vouchers or promotions unless expressly permitted by NUBES. Any
              unauthorised reproduction, transfer, sale, or purchase of Vouchers may result in their cancellation without notice.
            </p>

            <p class="ml-6">
              9.1.3 NUBES reserves the right to cancel, revoke, or refuse the use of any Voucher at its sole discretion, including, but not limited to, cases where NUBES suspects
              fraudulent activity or a violation of these Terms.
            </p>

            <p class="ml-6">9.2 Discounts and Promotions</p>

            <p class="ml-6">
              9.2.1 NUBES may offer discounts and promotions on the Platforms, which may include reduced prices, special offers, bundle deals, or other promotional activities
              (&quot;Promotions&quot;). Promotions may have specific terms and conditions, including validity periods, usage limits, and restrictions on their applicability to
              certain Goods or NUBES Partners. Such terms and conditions will be clearly stated when the Promotion is provided.
            </p>

            <p class="ml-6">9.2.2 Promotions are subject to availability and may be limited in quantity or duration.</p>

            <p class="ml-6">9.2.3 Promotions cannot be combined with other vouchers, discounts, or promotions unless expressly permitted by NUBES.</p>

            <p class="ml-6">
              9.2.4 NUBES reserves the right to refuse or cancel any Order that violates the terms and conditions of a Promotion or is suspected of fraudulent activity.
            </p>

            <p class="ml-6">9.3 Application of Vouchers, Discounts, and Promotions</p>

            <p class="ml-6">
              9.3.1 To apply a Voucher, discount, or Promotion to your Order, you must enter the valid code or select the applicable option during the checkout process on the
              Platforms.
            </p>

            <p class="ml-6">
              9.3.2 The value of any Voucher, discount, or Promotion will be applied as a deduction from the total amount payable for the Order, unless otherwise specified.
            </p>

            <p class="ml-6">9.3.3 Vouchers, discounts, and Promotions cannot be applied retroactively to Orders that have already been placed or processed.</p>

            <p class="ml-6">9.4 Modification or Cancellation of Vouchers, Discounts, and Promotions</p>

            <p class="ml-6">
              9.4.1 NUBES reserves the right to modify or cancel Vouchers, discounts, or Promotions at any time without prior notice, including but not limited to cases where NUBES
              suspects fraudulent activity, technical errors, or violations of these Terms.
            </p>

            <p class="ml-6">
              9.4.2 In the event of a modification or cancellation, NUBES shall not be liable for any loss, inconvenience, or disappointment incurred as a result, and you shall
              have no claim against NUBES in such cases.
            </p>

            <p class="ml-6">9.5 Governing Law</p>

            <p class="ml-6">
              These provisions related to Vouchers, discounts, and Promotions shall be subject to and construed in accordance with the laws of Malaysia, and any disputes arising
              out of or in connection with these provisions shall be subject to the exclusive jurisdiction of the courts of Malaysia.
            </p>

            <p class="ml-6">
              Please note that the above terms are provided as a general guideline and may need to be tailored or modified to align with the specific requirements and operations of
              your digital marketplace platform. It is advisable to consult legal experts to ensure the terms comply with applicable laws and regulations.
            </p>

            <h2 className="font-bold text-xl ml-4">10. REPRESENTATIONS, WARRANTIES AND LIMITATION OF LIABILITIES</h2>
            <p class="ml-6">10.1 NUBES Partner&apos;s Representations</p>

            <p class="ml-6">
              10.1.1 NUBES shall not be liable for any actions, omissions, or representations made by the NUBES Partners in relation to the provision of Goods, especially in cases
              where NUBES Partners Delivery applies to your Order. NUBES does not assume any liability for the quantity, quality, condition, or other representations of the Goods
              and/or services provided by NUBES Partners. NUBES also does not guarantee the accuracy or completeness of the menu information, photos, and images of the Goods,
              displayed on the NUBES Partners&apos; listing/offering on the Platform.
            </p>

            <p class="ml-6">
              10.1.2 Any disputes, claims, or liabilities arising from the actions, omissions, or representations of the NUBES Partners shall be solely between you and the
              respective NUBES Partner. NUBES shall not be a party to or responsible for resolving such disputes or liabilities.
            </p>

            <p class="ml-6">
              10.1.3 Notwithstanding the above, nothing in these Terms shall exclude the liability of the NUBES Partner for death or personal injury arising from the NUBES
              Partner&apos;s gross negligence or willful misconduct.
            </p>

            <p class="ml-6">10.2 NUBES&apos;s Limitation of Liability</p>

            <p class="ml-6">
              10.2.1 To the maximum extent permitted by applicable law, NUBES, its affiliates, and their respective officers, directors, employees, agents, and partners shall not
              be liable for any direct, indirect, incidental, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, data, or
              other intangible losses, arising out of or in connection with your use of the Platforms or the Goods purchased through the Platforms.
            </p>

            <p class="ml-6">10.2.2 NUBES shall not be liable for any damages or losses resulting from:</p>

            <p class="ml-6">
              (i) the actions, omissions, or representations of the NUBES Partners, including but not limited to the quantity, quality, condition, or other representations of the
              Goods and/or services provided by NUBES Partners;
            </p>

            <p class="ml-6">(ii) any disputes, transactions, or interactions between you and NUBES Partners;</p>

            <p class="ml-6">(iii) any unauthorised access, use, or alteration of your data or information on the Platforms;</p>

            <p class="ml-6">(iv) any viruses, malware, or other harmful components transmitted to or through the Platforms by any third party;</p>

            <p class="ml-6">(v) any delays, disruptions, or technical issues affecting the availability or functionality of the Platforms;</p>

            <p class="ml-6">(vi) any reliance on the information, including menu information, photos, and images, provided by NUBES Partners on the Platforms;</p>

            <p class="ml-6">(vii) any breaches of security or unauthorised access to your personal information;</p>

            <p class="ml-6">(viii) any loss or damage to your devices, equipment, or other property arising from your use of the Platforms;</p>

            <p class="ml-6">
              (ix) any events beyond NUBES&apos;s reasonable control, including but not limited to acts of God, natural disasters, strikes, or governmental actions.
            </p>

            <p class="ml-6">
              10.2.3 NUBES&apos;s total liability, whether in contract, tort, or otherwise, arising out of or in connection with your use of the Platforms or the Goods purchased
              through the Platforms shall be limited to the total amount paid by you for the specific Goods giving rise to the liability.
            </p>

            <p class="ml-6">10.3 Indemnification</p>

            <p class="ml-6">
              You agree to indemnify, defend, and hold harmless NUBES, its affiliates, and their respective officers, directors, employees, agents, and partners from and against
              any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys&apos; fees) arising out of or in connection with:
            </p>

            <p class="ml-6">(i) your use of the Platforms;</p>

            <p class="ml-6">(ii) any violation of these Terms or applicable laws;</p>

            <p class="ml-6">(iii) any disputes, transactions, or interactions between you and NUBES Partners;</p>

            <p class="ml-6">(iv) any breach of representations or warranties made by you;</p>

            <p class="ml-6">(v) any content or information you submit, post, transmit, or make available through the Platforms;</p>

            <p class="ml-6">(vi) any unauthorised access, use, or disclosure of your account or personal information.</p>

            <p class="ml-6">10.4 Governing Law</p>

            <p class="ml-6">
              These provisions related to representations, warranties, and limitation of liabilities shall be subject to and construed in accordance with the laws of Malaysia, and
              any disputes arising out of or in connection with these provisions shall be subject to the exclusive jurisdiction of the courts of Malaysia.
            </p>

            <h2 className="font-bold text-xl ml-4">11. NUBES Partners Liability</h2>
            <p class="ml-6">11.1 NUBES Partners represent and warrant that they are responsible for:</p>

            <p class="ml-6">
              11.1.1 The accuracy, completeness, and legality of the information provided in their listings/offers on the Platform, including but not limited to product
              descriptions, prices, availability, and delivery areas.
            </p>

            <p class="ml-6">
              11.1.2 The quality, quantity, condition, and safety of the Goods offered through the Platform, including compliance with applicable laws, regulations, and industry
              standards.
            </p>

            <p class="ml-6">
              11.1.3 Fulfilling Orders placed by customers in accordance with the terms and conditions specified in the listing/offering, including delivery timeframes, packaging,
              and any additional services promised.
            </p>

            <p class="ml-6">11.2 Vendor Responsibilities</p>

            <p class="ml-6">
              11.2.1 Vendors are responsible for managing their inventory, ensuring the availability of Goods listed on the Platform, and promptly updating the status of Goods that
              are out of stock or no longer available.
            </p>

            <p class="ml-6">
              11.2.2 Vendors are responsible for providing accurate and up-to-date information regarding their products, including product images, descriptions, ingredients,
              allergens, and any other relevant details required by law or necessary for customers to make informed purchasing decisions.
            </p>

            <p class="ml-6">11.2.3 Vendors are responsible for ensuring that their pricing is accurate and transparent, including any applicable taxes, fees, or surcharges.</p>

            <p class="ml-6">
              11.2.4 Vendors are responsible for handling customer inquiries, complaints, and requests for refunds or returns in a timely and satisfactory manner, in accordance
              with applicable laws and regulations.
            </p>

            <p class="ml-6">11.3 Delivery Partner Responsibilities</p>

            <p class="ml-6">
              11.3.1 Delivery partners engaged by NUBES Partners are responsible for timely and accurate delivery of Orders to customers&apos; designated delivery addresses.
            </p>

            <p class="ml-6">
              11.3.2 Delivery partners are responsible for handling the Goods with care during transportation to ensure that they arrive in the expected condition.
            </p>

            <p class="ml-6">
              11.3.3 Delivery partners are responsible for adhering to traffic regulations and ensuring the safety of themselves and others while delivering Orders.
            </p>

            <p class="ml-6">11.4 Customer Responsibilities</p>

            <p class="ml-6">
              11.4.1 Customers are responsible for providing accurate and complete delivery information, including the correct delivery address and contact details.
            </p>

            <p class="ml-6">
              11.4.2 Customers are responsible for being available to receive the Order at the designated delivery time or providing clear instructions for safe and secure delivery
              if they are unavailable.
            </p>

            <p class="ml-6">
              11.4.3 Customers are responsible for inspecting the delivered Goods upon receipt and promptly reporting any issues, discrepancies, or defects to the NUBES Partners or
              customer support, as specified in the platform&apos;s guidelines.
            </p>

            <p class="ml-6">
              11.4.4 Customers are responsible for ensuring that they meet any legal requirements or restrictions for purchasing certain Goods, such as age restrictions for the
              purchase of alcohol or tobacco products.
            </p>

            <p class="ml-6">11.5 Limitation of Liability</p>

            <p class="ml-6">
              11.5.1 NUBES shall not be liable for any damages, losses, or liabilities arising from the actions, omissions, or representations of NUBES Partners, vendors, or
              delivery partners, including but not limited to the quality, quantity, condition, or safety of the Goods, accuracy of information provided, or timeliness of delivery.
            </p>

            <p class="ml-6">
              11.5.2 NUBES shall not be liable for any damages, losses, or liabilities arising from customer interactions, disputes, or transactions with NUBES Partners, vendors,
              or delivery partners.
            </p>

            <p class="ml-6">11.5.3 NUBES&apos;s liability, if any, shall be limited to the extent required by applicable laws and regulations.</p>

            <h2 className="font-bold text-xl ml-4">12. PERSONAL DATA (PERSONAL INFORMATION) PROTECTION</h2>

            <p class="ml-6">
              12.1 NUBES is committed to protecting the privacy and personal data of its users and complies with the Personal Data Protection Act 2010 (&quot;PDPA&quot;) of
              Malaysia.
            </p>

            <p class="ml-6">
              12.2 By using the NUBES platform, you acknowledge and consent to the collection, use, storage, and processing of your personal data in accordance with the PDPA.
            </p>

            <p class="ml-6">
              12.3 NUBES will collect and process your personal data for the purpose of providing its services, managing your account, processing your orders, and improving user
              experience.
            </p>

            <p class="ml-6">
              12.4 NUBES may share your personal data with its NUBES Partners, delivery partners, and other third-party service providers for the purpose of fulfilling your orders
              and providing the requested services.
            </p>

            <p class="ml-6">
              12.5 NUBES will take reasonable measures to ensure the security and confidentiality of your personal data, including implementing appropriate technical and
              organisational measures to protect against unauthorised access, use, or disclosure.
            </p>

            <p class="ml-6">
              12.6 You have the right to access, correct, and update your personal data held by NUBES. You may also withdraw your consent for the collection and processing of your
              personal data by contacting NUBES customer support.
            </p>

            <p class="ml-6">
              12.7 NUBES may use cookies or similar technologies to collect information about your usage of the platform. You may manage your cookie preferences through your
              browser settings.
            </p>

            <p class="ml-6">12.8 NUBES will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by law.</p>

            <p class="ml-6">
              12.9 NUBES may transfer your personal data outside of Malaysia to its affiliates or service providers located in other jurisdictions. NUBES will ensure that such
              transfers are in compliance with applicable data protection laws and provide adequate protection for your personal data.
            </p>

            <p class="ml-6">12.10 For more information on the collection, use, and processing of your personal data, please refer to the NUBES Privacy Policy.</p>

            <h2 className="font-bold text-xl ml-4">13. INDEMNITY</h2>
            <p class="ml-6">
              13.1 You agree to indemnify, defend, and hold harmless NUBES, its affiliates, officers, directors, employees, agents, and service providers from and against any
              claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys&apos; fees) arising from:
            </p>

            <p class="ml-6">13.1.1 Your use of the Platform, including but not limited to any content you submit, post, transmit, or make available through the Platform.</p>

            <p class="ml-6">13.1.2 Your violation of these Terms, any applicable laws or regulations, or the rights of any third party.</p>

            <p class="ml-6">
              13.1.3 Your interaction, engagement, or transactions with NUBES Partners, vendors, or delivery partners, including any disputes or issues that may arise.
            </p>

            <p class="ml-6">
              13.1.4 Any claims or damages arising from the Goods purchased or services availed through the Platform, including but not limited to the quality, safety, accuracy, or
              legality of such Goods or services.
            </p>

            <p class="ml-6">
              13.2 NUBES reserves the right, at its own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which
              event you will cooperate with NUBES in asserting any available defences.
            </p>

            <p class="ml-6">13.3 This indemnity provision shall survive the termination or expiration of these Terms.</p>

            <h2 className="font-bold text-xl ml-4">14. THIRD PARTY LINKS AND WEBSITES</h2>
            <p class="ml-6">
              14.1 The Platform may contain links to third-party websites, applications, or services that are not owned or controlled by NUBES. NUBES does not endorse or assume any
              responsibility for the content, privacy policies, or practices of any third-party websites, applications, or services. By accessing or using such third-party links,
              you acknowledge and agree that NUBES shall not be liable for any damages, losses, or liabilities arising from your use of such third-party links, websites,
              applications, or services.
            </p>

            <p class="ml-6">
              14.2 You acknowledge and agree that NUBES is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright
              compliance, legality, decency, quality, or any other aspect of such third-party websites, applications, or services. NUBES does not warrant or endorse the offerings
              of any third-party websites, applications, or services, nor does it assume any responsibility or liability for the actions, products, services, or content of such
              third-party websites, applications, or services.
            </p>

            <p class="ml-6">
              14.3 Your interactions and transactions with third-party websites, applications, or services, including the delivery of Goods or services, and any terms, conditions,
              warranties, or representations associated with such interactions, are solely between you and the third party. You agree that NUBES shall not be responsible or liable
              for any loss or damage of any sort incurred as a result of any such interactions or the presence of such third-party links, websites, applications, or services on the
              Platform.
            </p>

            <p class="ml-6">
              14.4 We encourage you to review the terms of service, privacy policies, and other relevant policies of any third-party websites, applications, or services that you
              access through the Platform.
            </p>

            <p class="ml-6">
              14.5 If you decide to access or use any third-party links, websites, applications, or services, you do so at your own risk and discretion, and you should be aware
              that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any third-party
              website, application, or service to which you navigate from the Platform.
            </p>

            <h2 className="font-bold text-xl ml-4">15. TERMINATION</h2>
            <p class="ml-6">
              15.1 NUBES may, in its sole discretion, suspend, deactivate, or terminate your access to the Platform, including your NUBES account, if it believes that you have
              violated or acted inconsistently with the terms and conditions of the platform, engaged in fraudulent or illegal activities, or for any other reason without prior
              notice or liability.
            </p>

            <p class="ml-6">
              15.2 You may also terminate your NUBES account at any time by following the instructions provided on the Platform or by contacting customer support. Upon termination
              of your account, you will no longer have access to the Platform, and any pending orders or transactions may be cancelled.
            </p>

            <p class="ml-6">
              15.3 Termination of your account may result in the loss of certain features, privileges, or data associated with your account. NUBES shall not be liable to you or any
              third party for any termination of your access to the Platform or any associated loss of data or consequences thereof.
            </p>

            <p class="ml-6">
              15.4 Upon termination, any provisions of these terms that by their nature should survive termination, including but not limited to the provisions relating to
              intellectual property, disclaimer of warranties, limitations of liability, indemnity, and dispute resolution, shall continue to apply.
            </p>

            <p class="ml-6">
              15.5 NUBES reserves the right to modify, suspend, or discontinue the Platform or any part thereof at any time, with or without notice. NUBES shall not be liable to
              you or any third party for any modification, suspension, or discontinuation of the Platform.
            </p>

            <p class="ml-6">
              15.6 In the event of termination, you agree to cease all use of the Platform and any associated services and to return or destroy any confidential or proprietary
              information of NUBES in your possession.
            </p>

            <h2 className="font-bold text-xl ml-4">16. AMENDMENTS</h2>
            <p class="ml-6">
              16.1 NUBES reserves the right to modify or amend these terms and conditions at any time, in its sole discretion, without prior notice. Any changes to these terms will
              be effective immediately upon posting on the Platform. It is your responsibility to review these terms periodically for any updates or changes.
            </p>

            <p class="ml-6">
              16.2 By continuing to access or use the Platform after any modifications or amendments to these terms, you agree to be bound by the revised terms. If you do not agree
              with the revised terms, you must cease using the Platform and terminate your account.
            </p>

            <p class="ml-6">
              16.3 NUBES may, at its discretion, notify you of significant changes to these terms via email or other means of communication. However, it is your responsibility to
              regularly check for any updates or changes to these terms on the Platform.
            </p>

            <p class="ml-6">16.4 NUBES may also make changes to the Platform, including its features, functionalities, and services, at any time without prior notice.</p>

            <p class="ml-6">
              16.5 In the event of any conflict or inconsistency between these terms and any other terms or policies applicable to the Platform, these terms shall prevail.
            </p>

            <p class="ml-6">16.6 No amendment or modification to these terms by you shall be valid or binding unless made in writing and signed by both parties.</p>

            <p class="ml-6">16.7 NUBES reserves the right to assign or transfer its rights and obligations under these terms to any third party without your consent.</p>

            <p class="ml-6">
              16.8 You acknowledge and agree that NUBES shall not be liable to you or any third party for any modifications, amendments, or discontinuation of the Platform or any
              associated services.
            </p>

            <h2 className="font-bold text-xl ml-4">17. SEVERABILITY</h2>
            <p class="ml-6">
              17.1 If any provision of these terms and conditions is held to be invalid, illegal, or unenforceable by a court of competent jurisdiction, such provision shall be
              severed from these terms and conditions, and the remaining provisions shall continue in full force and effect to the maximum extent permitted by law.
            </p>

            <p class="ml-6">
              17.2 The invalidity, illegality, or unenforceability of any provision in any jurisdiction shall not affect the validity, legality, or enforceability of the remaining
              provisions of these terms and conditions or the validity, legality, or enforceability of that provision in any other jurisdiction.
            </p>

            <p class="ml-6">
              17.3 In the event that any provision is found to be invalid, illegal, or unenforceable, the parties shall negotiate in good faith to replace such provision with a
              valid, legal, and enforceable provision that achieves to the greatest extent possible the original purpose and intent of the severed provision.
            </p>

            <p class="ml-6">
              17.4 The failure of either party to enforce any provision of these terms and conditions shall not be deemed a waiver of future enforcement of that or any other
              provision.
            </p>

            <p class="ml-6">
              17.5 The provisions of these terms and conditions shall be interpreted in such a way as to give effect to their purpose to the fullest extent permitted by applicable
              law.
            </p>

            <p class="ml-6">
              17.6 The rights and obligations of the parties under these terms and conditions shall be binding upon and inure to the benefit of their respective successors,
              assigns, and legal representatives.
            </p>

            <h2 className="font-bold text-xl ml-4">18. GOVERNING LAW</h2>
            <p class="ml-6">
              18.1 These terms and conditions, and any disputes arising out of or in connection with them, shall be governed by and construed in accordance with the laws of
              Malaysia.
            </p>

            <p class="ml-6">
              18.2 Any legal action or proceeding arising out of or in connection with these terms and conditions shall be brought exclusively in the courts of Malaysia.
            </p>

            <p class="ml-6">
              18.3 The parties irrevocably submit to the exclusive jurisdiction of the courts of Malaysia for the purpose of any legal action or proceeding arising out of or
              relating to these terms and conditions.
            </p>

            <p class="ml-6">
              18.4 In the event of any inconsistency between the English version of these terms and conditions and any translation thereof, the English version shall prevail.
            </p>

            <h2 className="font-bold text-xl ml-4">19. WE USE STRIPE PAYMENT GATEWAY</h2>
            <p class="ml-6">
              19.1 NUBES utilises the services of Stripe, a third-party payment gateway, to facilitate secure and reliable payment transactions on the platform. We recommend
              reviewing&nbsp;<a href="https://stripe.com/en-my/legal/end-users">Stripe&rsquo;s End User Terms of Service</a>&nbsp;to understand how your payment information is
              handled.
            </p>

            <p class="ml-6">
              19.2 By making a purchase or accepting payments as a vendor on the NUBES platform, you acknowledge and agree to the terms and conditions of Stripe&apos;s services.
            </p>

            <p class="ml-6">
              19.3 When making a payment, you will be redirected to the Stripe payment gateway, where you will enter your payment information securely. NUBES does not store your
              credit card or payment details.
            </p>

            <p class="ml-6">19.4 Stripe will process the payment on behalf of NUBES and ensure the security and confidentiality of your payment information.</p>

            <p class="ml-6">
              19.5 As a vendor, when customers make a payment for your products or services, the funds will be processed and held by Stripe. NUBES will initiate payouts to vendors
              based on the agreed payout schedule, taking into account any applicable fees or commissions.
            </p>

            <p class="ml-6">
              19.6 NUBES is not responsible for any issues, errors, or disputes related to the payment processing handled by Stripe. Any questions or concerns regarding payments
              should be directed to Stripe&apos;s customer support.
            </p>

            <p class="ml-6">
              19.7 Stripe&apos;s terms and conditions, including their privacy policy, govern the handling and processing of payment information. It is recommended that you review
              and understand Stripe&apos;s terms before using the platform.
            </p>

            <p class="ml-6">19.8 NUBES does not assume any liability for the actions, omissions, or security breaches of Stripe or any third-party payment gateway providers.</p>

            <p class="ml-6">
              19.9 NUBES reserves the right to change or replace the payment gateway provider at any time, provided that any such change will not materially affect the payment
              process or security of user information.
            </p>

            <h2 className="font-bold text-xl ml-4">20. CONTACT US</h2>
            <p class="ml-6">
              If you have any questions about this Platform Terms of Use, please contact us by visiting our support channels stated on NUBES.MY and our social media platforms.
            </p>
            <p class="ml-6">Thank you for taking the time to read our Privacy Policy. We value your privacy and appreciate your trust in us.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermOfUse;
