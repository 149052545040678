import React, { useState } from 'react';

const ReadMore = ({ children, maxChar = 30 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div>
      {text.length > maxChar ? (
        <p className="text-sm font-normal">
          {isReadMore ? `${text.slice(0, maxChar)}...` : text}
          <a onClick={toggleReadMore} className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
            {isReadMore ? 'show more' : ' show less'}
          </a>
        </p>
      ) : (
        <p className="text-sm font-normal">{text}</p>
      )}
    </div>
  );
};

export default ReadMore;
