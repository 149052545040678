import { Form, Input } from '../../../components/Form';
import { submitReview } from '../../../store/slices/customer.slice';
import { showToast } from '../../../store/slices/components.slice';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const ModalSubmitReview = ({ show = false, id, closeable = true, loading = false, hide, closeAction = () => null }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();
  const formNode = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  const handleSubmitReview = (data) => {
    dispatch(submitReview({ id, data }))
      .unwrap()
      .then((result) => {
        setOpen(false);
        history.push(`/orders/${id}`);
        dispatch(showToast({ show: true, type: 'success', message: `Your review has been successfully send!` }));
        window.location.reload(true);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-600 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium text-white">Confirm Received</h2>
                  </div>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 ">
                <div className="w-full">
                  <Form onSubmit={handleSubmitReview} setRef={formNode}>
                    <div className="px-4 py-5 space-y-6 sm:p-6">
                      <div className="grid grid-cols-6">
                        <div className="col-span-6">
                          <Input.Rating name="reviewRating" label="Rate" />
                        </div>
                        <div className="col-span-6">
                          <Input.Textarea name="reviewComment" label="Comment" maxLength={300} />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={() => closeAction()}
                  disabled={loading}
                  type="button"
                  className={`disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    'Back'
                  )}
                </button>
                <button
                  onClick={() => formNode.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-red-600 hover:bg-red-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
