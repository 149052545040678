const NubesLogo = (props) => {
  return (
    <svg id="prefix__Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 800 600" xmlSpace="preserve" {...props}>
      <style>{'.prefix__st0{fill:#ffa80e}.prefix__st1{fill:#fff}'}</style>
      <path className="prefix__st0" d="M603.7 298.2H155.1c-76.9 0-139.3 62.4-139.3 139.3s62.4 139.3 139.3 139.3h448.6c76.9 0 139.3-62.4 139.3-139.3s-62.4-139.3-139.3-139.3z" />
      <circle className="prefix__st0" cx={446.8} cy={238.1} r={214.9} />
      <circle className="prefix__st0" cx={215.6} cy={227} r={140.5} />
      <path
        className="prefix__st0"
        d="M708.2 292.5c3.6 17.5 19.1 30.7 37.6 30.7 21.2 0 38.4-17.2 38.4-38.4s-17.2-38.4-38.4-38.4c-18.4 0-33.8 13-37.5 30.2h-50c-1 5.4-2.1 10.6-3.5 15.8h53.4zm37.6-31.7c13.3 0 24 10.8 24 24 0 13.3-10.8 24-24 24s-24-10.8-24-24 10.8-24 24-24zM661.9 202.6c3.6 17.5 19.1 30.7 37.6 30.7 21.2 0 38.4-17.2 38.4-38.4s-17.2-38.4-38.4-38.4c-18.4 0-33.8 13-37.5 30.2h-6.3c1.3 5.2 2.4 10.5 3.3 15.8h2.9zm37.6-31.8c13.3 0 24 10.8 24 24 0 13.3-10.8 24-24 24s-24-10.8-24-24 10.7-24 24-24zM700.3 111.1c3.6 17.5 19.1 30.7 37.6 30.7 21.2 0 38.4-17.2 38.4-38.4S759.1 65 737.9 65c-18.4 0-33.8 13-37.5 30.2h-92.9c4.5 5.1 8.8 10.4 12.8 15.8h80zm37.6-31.8c13.3 0 24 10.8 24 24s-10.8 24-24 24-24-10.8-24-24 10.7-24 24-24z"
      />
      <path
        className="prefix__st1"
        d="M364.3 228.4c-21.2 0-38.4 17.2-38.4 38.4s17.2 38.4 38.4 38.4c18.4 0 33.8-13 37.5-30.2h132.5V111.2h85.9c-4-5.5-8.3-10.8-12.8-15.8h-88.9v163.9H402c-3.6-17.7-19.1-30.9-37.7-30.9zm0 62.4c-13.3 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.7 24-24 24zM554.3 424.3c.3-.2.7-.4 1-.6h-1v.6zM515.9 385.8H603c4.8-5.1 9.3-10.3 13.6-15.8h-7v-77.4h45.2c1.4-5.2 2.5-10.5 3.5-15.8h-64.5V370h-77.9v15.8zM586.1 202.6h72.7c-.9-5.4-2-10.6-3.3-15.8H586v15.8z"
      />
      <g>
        <path
          className="prefix__st1"
          d="M416.4 416.4c18.4 0 33.8-13 37.5-30.2h279.4c-2.3-5.5-4.9-10.8-7.9-15.8H609.7v-69.8c-1.7-.1-3.4-.1-5.1-.1h-10.8v69.9H454c-3.6-17.5-19.1-30.7-37.6-30.7-21.2 0-38.4 17.2-38.4 38.4 0 21.1 17.2 38.3 38.4 38.3zm0-62.4c13.3 0 24 10.8 24 24 0 13.3-10.8 24-24 24-13.3 0-24-10.8-24-24s10.7-24 24-24zM744 439.4v-.2c0-5.3-.3-10.5-.9-15.6H554.4v55.1H402.2c-3.6-17.5-19.1-30.7-37.6-30.7-21.2 0-38.4 17.2-38.4 38.4s17.2 38.4 38.4 38.4c18.4 0 33.8-13 37.5-30.2h168.1v-55l173.8-.2zm-379.4 70.9c-13.3 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
        />
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M379.4 137c-34 0-65.5 10.9-91.2 29.3-1.7-16.5-15.6-29.3-32.5-29.3-18.1 0-32.7 14.6-32.7 32.7v321.5c0 17 13.7 30.7 30.7 30.7h4c17 0 30.7-13.7 30.7-30.7V303.1c0-51.4 41.6-93 93-93s93 41.6 93 93v188.1c0 17 13.7 30.7 30.7 30.7s30.7-13.7 30.7-30.7V293.4c0-86.4-70-156.4-156.4-156.4z"
        />
        <path
          className="prefix__st0"
          d="M379.4 135.1c-25.7 0-51.2 6.3-73.9 18.3-6.3 3.3-12.4 7.1-18.2 11.3.9.5 1.9 1.1 2.8 1.6-1.2-10.8-7.2-20.6-16.6-26.3-9.5-5.7-21.4-6.5-31.5-2.1-9.8 4.2-17.3 13-19.9 23.4-1 3.9-1 7.7-1 11.6v318c0 8.6 3.3 16.9 9.4 23.1 3.7 3.8 8.4 6.6 13.4 8.2 4.4 1.4 8.8 1.5 13.3 1.5 10.5 0 20.3-4.7 26.6-13.1 4.4-5.8 6.4-12.9 6.5-20.1v-8.3-37.1-47.6-38.1-14.8-40c0-19.1 5.2-37.5 16.1-53.3 12.1-17.6 30.4-30.6 51-36.2 21.2-5.8 44.4-3.7 64.2 6.1 19.1 9.4 34.4 25.3 43.1 44.7 5.7 12.7 7.9 26.1 7.9 39.9v182.7c0 3 0 5.9.6 8.9 2 10.5 9.5 19.6 19.3 23.7 10.1 4.3 22.1 3.1 31.1-3.1 8.8-6.1 14.1-16.1 14.2-26.9v-14.2-35.4-46.4V348v-37.2-17.3c0-19.4-3.5-38.7-10.4-56.8-7.6-19.9-19.2-38.1-34-53.4-14.8-15.3-32.6-27.6-52.2-35.8-19.6-8.1-40.6-12.3-61.8-12.4-2.4 0-2.4 3.7 0 3.8 21.8 0 43.5 4.6 63.3 13.5 19.2 8.6 36.4 21.2 50.6 36.7 14.2 15.5 25.2 33.8 32.1 53.7 5.7 16.5 8.5 33.9 8.5 51.4v183.6c0 4.8.1 9.5 0 14.3-.3 10.8-6.8 20.6-16.4 25.2-9.9 4.8-22.1 3.3-30.6-3.6-6.8-5.6-10.6-13.8-10.7-22.6v-5.2-28.5-43.3-47.3-40.2-21.9c0-15.7-3.4-31-10.6-45-9.6-18.5-25.4-33.7-44.3-42.5-19.8-9.2-42.6-11.3-63.7-5.8-20.4 5.3-38.7 17.5-51.5 34.2-12.7 16.5-19.5 36.9-19.5 57.7v173.9c0 7.2.9 15.4-1.2 22.4-3.1 10.3-12 18.1-22.6 20.1-3.8.7-8.2.7-12 .3-5.3-.5-10.4-2.6-14.6-5.8-7.2-5.5-11.2-14.1-11.3-23v-6.6-34.3-46.9-40.1-15.1-38.6-19.5-32.2-46.7-35.7-5.8c.1-10.6 5.5-20.6 14.6-26.2 9-5.6 20.6-6.2 30-1.4 9.3 4.7 15.8 13.9 16.9 24.3.1 1.3 1.5 2.5 2.8 1.6 20.4-14.6 44-24.1 68.8-27.6 7.1-1 14.3-1.5 21.4-1.5 2.4 0 2.4-3.8 0-3.8z"
        />
      </g>
    </svg>
  );
};

export default NubesLogo;
