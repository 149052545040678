import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/user.service';

const initialState = {
  loadingUserUpdate: false,
  loading: false,
  payload: null,
  error: null,
  deliverToAddress: {},
  addressChange: false,
  addresses: [],
  profilePicture: null,
  currentCoordinate: {},
  hasSetupStore: true,
  lastReducerAction: null,
};

export const getUser = createAsyncThunk('user/get_user', async (_, { rejectWithValue }) => {
  try {
    return await userService.getUser();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateUser = createAsyncThunk('user/update_user', async (data, { rejectWithValue }) => {
  try {
    return await userService.updateUser(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveDeliverToAddress: (state, action) => {
      state.deliverToAddress = action.payload;
      state.addressChange = !state.addressChange;
      state.lastReducerAction = 'saveDeliverToAddress';
    },
    saveAddresses: (state, action) => {
      state.addresses = action.payload;
      state.lastReducerAction = 'saveAddresses';
    },
    saveProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
      state.lastReducerAction = 'saveProfilePicture';
    },
    saveCurrentCoordinate: (state, action) => {
      state.currentCoordinate = action.payload;
      state.lastReducerAction = 'saveCurrentCoordinate';
    },
    saveHasSetupStore: (state, action) => {
      state.hasSetupStore = action.payload;
      state.lastReducerAction = 'saveHasSetupStore';
    },
  },
  extraReducers: {
    /** Get user reducer **/
    [getUser.fulfilled]: (state, { meta, payload }) => {
      localStorage.setItem('user', JSON.stringify(payload.data));
      sessionStorage.setItem('user', JSON.stringify(payload.data));
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getUser.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getUser.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update user reducer **/
    [updateUser.fulfilled]: (state, { meta, payload }) => {
      localStorage.setItem('user', JSON.stringify(payload.data));
      sessionStorage.setItem('user', JSON.stringify(payload.data));
      state.payload = payload.data;
      state.loading = false;
      state.loadingUserUpdate = false;
      state.error = null;
    },
    [updateUser.pending]: (state, { meta }) => {
      state.loading = true;
      state.loadingUserUpdate = true;
    },
    [updateUser.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.loadingUserUpdate = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export const { saveDeliverToAddress, saveProfilePicture, saveAddresses, saveCurrentCoordinate, saveHasSetupStore } = actions;
export default reducer;
