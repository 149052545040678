import api from './index';

class FileService {
  getUploadRequest(data) {
    return api.post('/file_storage/upload_request', data).then((response) => {
      return response.data;
    });
  }
  getPrivateFile(file_path) {
    return api.get(`file_storage/get_private_file?path=${file_path}`).then((response) => {
      return response.data;
    });
  }
  uploadFile({ url, file }) {
    return api.put(url, file, { headers: { 'content-type': 'application/octet-stream' } }).then((response) => {
      return response.data;
    });
  }
}

export default new FileService();
