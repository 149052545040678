import React, { useCallback, useEffect, useState } from 'react';
import { PinIcon } from '@iconicicons/react';
import { classNames, isSessionExist } from '../../utils';
import { isArrayNotEmpty, isNotNullUndefined } from '../../helper';
import './storeCard.css';
import Button from '../Buttons/Button/Button';
import { useIsMobile } from '../../hooks/hooks';
import { useHistory } from 'react-router-dom';
import { showMapModal } from '../../store/slices/components.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, CarouselContent } from '../Carousel/Carousel';
import { isObjectEmpty } from '../../helper/object';

function StoreCard({ store }) {
  const history = useHistory();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);
  const [emblaApi, setEmblaApi] = useState(null);
  const { userApi } = useSelector((state) => state);
  const storeTag = getStoreTags();
  const [showCheckAvailability, setShowCheckAvailability] = useState(false);
  const [showSetAddress, setShowSetAddress] = useState(false);

  const onScroll = useCallback((emblaApi, eventName) => {
    if (eventName != 'scroll') {
      return;
    }

    if (store.products.length <= 1) {
      emblaApi.off('scroll', onScroll);
      setDragging(true);
      return;
    }

    if (emblaApi.scrollProgress() * store.products.length > 0.07) {
      emblaApi.off('scroll', onScroll);
      setDragging(true);
    }
  }, []);

  useEffect(() => {
    if (emblaApi && isMobile) emblaApi.on('scroll', onScroll);
  }, [emblaApi]);

  useEffect(() => {
    setShowCheckAvailability(!isSessionExist() && isObjectEmpty(userApi?.currentCoordinate));
  }, [userApi.currentCoordinate]);

  useEffect(() => {
    setShowSetAddress(isSessionExist() && !isNotNullUndefined(userApi?.deliverToAddress?.location));
  }, [userApi?.deliverToAddress]);

  function getStoreTags() {
    let tag = [];

    if (store.deliveryEnable) {
      if (store.distance && store.distance > store.availabilityRadiusKm) {
        tag.push({ name: 'Out Of Range', className: 'bg-gray-400' });
      } else {
        tag.push({ name: 'Delivery', className: 'bg-primary-500' });
      }
    } else {
      tag.push({ name: 'Delivery Unavailable', className: 'bg-primary-500' });
    }

    if (store.selfPickupEnable) {
      tag.push({ name: 'Self Pickup', className: 'bg-primary-500' });
    } else {
      tag.push({ name: 'Self Pickup Unavailable', className: 'bg-gray-400' });
    }

    return tag;
  }

  return (
    <div className={classNames('flex gap-x-4 gap-y-4 sm:flex-col rounded', dragging ? 'flex-col' : 'flex-row-reverse')} onClick={() => history.push('/vendor/' + store.uid)}>
      {/* Image Carousel*/}
      <div className={classNames('overflow-hidden', isMobile && !dragging && 'basis-[40%]')}>
        <Carousel
          className={dragging ? 'slide-left' : ''}
          setApi={setEmblaApi}
          slides={
            isArrayNotEmpty(store.products) &&
            store.products.map((newProduct, index) => {
              return (
                <CarouselContent
                  key={index}
                  className={classNames(isMobile ? 'mr-3' : 'mr-8', dragging ? 'basis-2/5' : 'basis-full')}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <img key={index} className="border-2 rounded-xl w-full h-auto object-left object-cover" alt="button-icon" src={newProduct.picture.path.s} />
                </CarouselContent>
              );
            })
          }
          options={{ align: 'start', dragFree: true }}
        />
      </div>
      {/* Card Body */}
      <div className={classNames(dragging && 'slide-down', isMobile && !dragging && 'basis-[60%]')}>
        <div className="flex flex-row items-center">
          <div className="text-lg font-semibold pr-2 line-clamp-1">{store.storeName}</div>
        </div>
        <div>
          <div className="flex flex-wrap items-center gap-x-2 my-1">
            {store.distance && (
              <div className="flex flex-row gap-x-1 items-center">
                <PinIcon className="flex-shrink-0 text-primary-500 w-5 h-5" />
                <span className="font-semibold text-md">{store.distance.toFixed(2)} KM</span>
              </div>
            )}
          </div>
          <p className="text-[#9B9B9B] text-sm line-clamp-1">{store.storeDescription ?? ''}</p>
        </div>

        {/* Store tag */}
        {!showCheckAvailability && !showSetAddress && isArrayNotEmpty(storeTag) && (
          <div className="flex flex-wrap items-center gap-x-2 my-3 gap-y-2">
            {storeTag.map((tag, idx) => (
              <div key={idx} className={classNames('px-2 py-1 rounded text-sm text-white', tag.className)}>
                {tag.name}
              </div>
            ))}
          </div>
        )}

        {/* User not logged in and no location selected */}
        {showCheckAvailability && (
          <Button
            className={'w-40 bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 py-1 px-4 my-2'}
            text="Check Availability"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(showMapModal(true));
            }}
          />
        )}

        {/* User logged in and no address selected */}
        {showSetAddress && (
          <Button
            className={'w-40 bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 py-1 px-4 my-2'}
            text="Set Address"
            onClick={(e) => {
              e.stopPropagation();
              history.push('/addresses');
            }}
          />
        )}
      </div>
    </div>
  );
}

export default StoreCard;
