import React from 'react';
import { classNames } from '../../../utils';
import { isFunction } from 'lodash';

function Button(props) {
  const { className, text, isPillButton, onClick } = props;

  const handleOnClick = (e) => {
    if (isFunction(onClick)) onClick(e);
  };

  return (
    <button type="button" className={classNames('flex flex-col items-center justify-center', isPillButton ? 'rounded-full' : 'rounded-md', className)} onClick={handleOnClick}>
      {text && <div className="text-sm font-medium my-1">{text}</div>}
    </button>
  );
}

export default Button;
