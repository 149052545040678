import { Link, useParams } from 'react-router-dom';

export const PaymentSuccess = () => {
  const { id } = useParams();

  return (
    <div className="min-h-screen bg-amber-500 py-6 flex items-center justify-center relative overflow-hidden sm:py-12">
      <div className="flex flex-col items-center space-y-4">
        <svg className="w-20 h-20 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-white">Thank you for your order</h1>
          <p className="text-amber-100 text-base">Your order has been received</p>
          <Link
            to={`/orders/${id}`}
            className="mt-8 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Go to My Order
          </Link>
        </div>
      </div>
    </div>
  );
};

export const PaymentFailed = () => {
  const { id } = useParams();

  return (
    <div className="min-h-screen bg-amber-500 py-6 flex items-center justify-center relative overflow-hidden sm:py-12">
      <div className="flex flex-col items-center space-y-4">
        <svg className="w-20 h-20 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-white">Something went wrong</h1>
          <p className="text-amber-100 text-base">Your order payment was failed</p>
          <Link
            to={`/orders/${id}`}
            className="mt-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Try again
          </Link>
        </div>
      </div>
    </div>
  );
};
