import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import tokenService from '../../services/token.service';
import authService from '../../services/auth.service';

import { showToast } from '../../store/slices/components.slice';

import { retrieveImpersonateSession } from '../../store/slices/customer.slice';

import api from '../../services';

const CallbackRedirectAccess = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (token) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('guest');
      localStorage.removeItem('email-guest');
      handleCallback(token);
    } else {
      history.push('/');
    }
  }, []);

  const handleCallback = async (token) => {
    await dispatch(retrieveImpersonateSession(token))
      .unwrap()
      .then((result) => {
        const { data } = result;
        if (!data?.access?.auth?.access_token) {
          dispatch(showToast({ show: true, type: 'error', message: `Session Expired or Not Found` }));
          history.push('/');
          return;
        }
        const newToken = data.access.auth;
        newToken.impersonate_email = data.access.impersonateEmail;
        tokenService.setToken(newToken);
        dispatch(showToast({ show: true, type: 'success', message: `You've successfully log in as ${newToken.impersonate_email}` }));
        const currentPath = localStorage.getItem('current_path');
        if (currentPath) {
          localStorage.removeItem('current_path');
          history.push(currentPath);
        } else {
          history.push('/');
        }
        window.location.reload(true);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return <div className="sr-only">Callback</div>;
};

export default CallbackRedirectAccess;
