import { Link } from 'react-router-dom';
import facebook from '../../assets/images/icons8-facebook.svg';
import instagram from '../../assets/images/icons8-instagram.svg';
import linkedin from '../../assets/images/icons8-linkedin-circled.svg';
import twitter from '../../assets/images/icons8-twitter-circled.svg';
import TextButton from '../Buttons/TextButton/TextButton';
import Image from '../Image';
import NubesLogoV2 from '../LogoV2/LogoV2';
import BaseContainer from '../BaseContainer/BaseContainer';
import React from 'react';

const Footer = () => {
  return (
    <BaseContainer className="flex flex-col items-center py-6">
      <div className="flex flex-col items-start gap-5 mb-5 md:gap-0 md:mb-10 md:grid md:grid-cols-3 md:items-start">
        <div className="flex flex-col">
          <Link to="/" className="mb-3">
            <NubesLogoV2 />
          </Link>
          <p className="text-neutral-500 font-light text-justify">To create a unique product and solution that contribute to the betterment of the quality of human life</p>
        </div>
        <FooterSection>
          <FooterTitle text={'Explore Us'} />
          <TextButton>
            <Link to="/about-us" className="text-inherit">
              About Us
            </Link>
          </TextButton>
          <TextButton>
            <Link to="/privacy-policy" className="text-inherit">
              Privacy Policy
            </Link>
          </TextButton>
          <TextButton>
            <Link to="/term-of-use" className="text-inherit">
              Terms and Conditions
            </Link>
          </TextButton>
        </FooterSection>
        <FooterSection>
          <FooterTitle text={'Connect Us'} />
          <TextButton>support@nubes.com</TextButton>
          <TextButton>+60199322001</TextButton>
          <div className="flex flex-row gap-2 mt-1">
            <a href={`https://www.facebook.com/nubes.my`} target="_blank" rel="noreferrer" className=" hover:opacity-70">
              <p className="flex space-x-2">
                <Image className=" object-cover" src={facebook} alt="" />
              </p>
            </a>
            <a href={`https://www.linkedin.com/company/nubesmy`} target="_blank" rel="noreferrer" className=" hover:opacity-70">
              <p className="flex space-x-2">
                <Image className="  object-cover" src={linkedin} alt="" />
              </p>
            </a>
            <a href={`https://twitter.com/nubesmy`} target="_blank" rel="noreferrer" className=" hover:opacity-70">
              <p className="flex space-x-2">
                <Image className=" object-cover" src={twitter} alt="" />
              </p>
            </a>
            <a href={`https://www.instagram.com/nubes.my`} target="_blank" rel="noreferrer" className=" hover:opacity-70">
              <p className="flex space-x-2">
                <Image className=" object-cover" src={instagram} alt="" />
              </p>
            </a>
          </div>
        </FooterSection>
      </div>
      <p className="text-neutral-400 my-4 md:my-0">© 2022 Nubescom Sdn. Bhd. All rights reserved.</p>
    </BaseContainer>
  );
};

const FooterSection = ({ children }) => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-start gap-2">{children}</div>
    </div>
  );
};

const FooterTitle = ({ text }) => {
  return <p className="text-primary-400 font-medium uppercase">{text}</p>;
};

export default Footer;
