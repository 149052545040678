import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import _ from 'lodash';
import { Switch } from '@headlessui/react';
import { CloseIcon, CropIcon, EyeIcon, EyeOffIcon, PinIcon, RefreshIcon, StarIcon, TrashIcon, UploadIcon, ZoomInIcon, ZoomOutIcon } from '@iconicicons/react';
import GoogleMapReact from 'google-map-react';
import { classNames, dataURLtoBlob, validate } from '../../utils';

import 'cropperjs/dist/cropper.css';
import imgNotFound from '../../assets/images/img-not-found.png';

import { showToast } from '../../store/slices/components.slice';
import { getPrivateFile, getUploadRequest, uploadFile } from '../../store/slices/file.slice';
import Image from '../Image';
import Tooltip from '../Tooltip';
import { DatePicker } from 'antd';

export const Form = ({ children, onSubmit, onError, onChange, setErrors, defaultValues = {}, triggerUpdateData = false, validated, setRef, ...props }) => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    getValues,
    trigger,
    setValue,
    setError,
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: 'all',
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const formRef = useRef(null);

  useImperativeHandle(
    setRef,
    () => ({
      validateForm: async (fields = []) => {
        let formData = getValues();

        const handleDynamicFieldValidation = (name, data) => {
          // This function is required to handle dynamic field inputs validation.
          let newArray = [];
          data.forEach((d, index) => {
            let objs = Object.keys(d);
            objs.forEach((obj) => {
              newArray.push(`${name}[${index}].${obj}`);
            });
          });
          return newArray;
        };

        fields = fields.map((field) => (Array.isArray(formData[field]) ? handleDynamicFieldValidation(field, formData[field]) : field));
        const result = await trigger(Array.isArray(fields[0]) ? fields[0] : fields, { shouldFocus: true });

        return result;
      },
      getFormData: (event) => getValues(),
      dispatchEvent: (event) => formRef.current.dispatchEvent(event), // Use this function if submit button is not inside <Form>
      resetFields: (fields) => reset(fields),
    }),
    [trigger, getValues],
  );

  useEffect(() => {
    // This is use to avoid infinite loop when defaultValues updated
    if (!_.isEmpty(defaultValues) && !isInitialRender) {
      setIsInitialRender(false);
      reset(defaultValues);

      if (typeof setErrors === 'object' && setErrors.length > 0) {
        setErrors.forEach((error) => {
          // console.log(error.context.key, error.message)
          setError(error.context.key, { type: 'manual', message: error.message });
        });
      }
    }

    setIsInitialRender(false);
  }, [defaultValues, isInitialRender, reset, setErrors, setError, triggerUpdateData]);

  const handleFormUpdate = (type, name, index) => {
    let data = getValues();

    if (type === 'remove') {
      if (index >= 0 && name) {
        data[name].splice(index, 1);
      }
    }

    if (onChange) {
      onChange(data);
    }
  };

  let comp = null;

  const handleChildren = (child) => {
    // CKEditor
    if (child.type.name === 'Rr') {
      // console.log('For CKEditor');
      return React.createElement(child.type, { ...child.props });
    } else if (!_.isEmpty(child.props)) {
      // console.log('For any child with props value');
      return React.createElement(child.type, {
        ...{
          ...child.props,
          className: child.props.className,
          register: register,
          control: control,
          rules: constructRules(child.props.rules, getValues),
          errors: errors,
          key: child.props.name,
          onChange: handleFormUpdate,
          setValue: setValue,
          getValues: getValues,
          setError: setError,
          defaultValue: getValues(child.props.name),
        },
      });
    } else {
      // console.log('Any child without props value');
      return child;
    }
  };

  const loopChildren = (children) => {
    return React.Children.map(children, (child) => {
      if (typeof child === 'object') {
        const { children } = child.props;
        if (!children) {
          // console.log('No child component: ', child);
          if (typeof child.type === 'function') {
            // console.log('Type function: ', child);
            return handleChildren(child);
          } else if (typeof child.type === 'string') {
            // console.log('Type string: ', child);
            return React.createElement(child.type, {
              ...{
                ...child.props,
              },
            });
          } else {
            return React.cloneElement(child.type, {
              ...{
                ...child.props,
              },
            });
          }
        } else {
          if (Array.isArray(children)) {
            // console.log('Children: Array', child);
            if (child.type.name === 'DynamicField') {
              return handleChildren(child);
            } else {
              return React.createElement(
                child.type,
                {
                  ...{
                    ...child.props,
                  },
                },
                React.Children.map(children, (child) => {
                  return loopChildren(child);
                }),
              );
            }
          } else if (typeof children === 'object') {
            // console.log('Children: Object', child);
            return React.createElement(
              child.type,
              {
                ...{
                  ...child.props,
                },
              },
              loopChildren(children),
            );
          } else {
            // console.log('Children: Not Array / Object', child);
            return React.createElement(child.type, {
              ...{
                ...child.props,
              },
            });
          }
        }
      } else {
        return child;
      }
    });
  };

  comp = loopChildren(children);

  return (
    <form {...props} onSubmit={handleSubmit(onSubmit, onError)} ref={formRef} noValidate>
      {comp}
    </form>
  );
};

export const Input = {
  Text: ({ name, label, placeholder, maxLength, retrieveValue = () => null, disabled = false, hint = false, onBlur = () => null, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, value, className } = props;
    let [totalCharactersCounted, setTotalCharactersCounted] = useState(0);

    useEffect(() => {
      setTotalCharactersCounted();
      setValue(name, value);
    }, [value]);

    // console.log('TextInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    const countCharacters = (text) => {
      setTotalCharactersCounted(text.target.value.length);
    };

    const handleOnBlur = (e) => {
      setValue(name, e.target.value);
      onBlur(e);
    };

    return (
      <div className={className}>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(e) => retrieveValue(e)}
          onKeyUp={(e) => countCharacters(e)}
          onBlur={handleOnBlur}
          value={value}
        />
        <div className="flex items-center justify-between">
          {fieldError || dynamicFieldErrors ? (
            <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
          ) : (
            hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
          )}
          {maxLength && (
            <p className="ml-auto mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">
              {totalCharactersCounted} / {maxLength} characters
            </p>
          )}
        </div>
      </div>
    );
  },
  TextV2: ({ name, label, placeholder, maxLength, disabled = false, hint = false, onBlur, prefix, retrieveValue = () => null, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, value, className } = props;
    let [totalCharactersCounted, setTotalCharactersCounted] = useState(0);
    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);
    if (errors && errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    useEffect(() => {
      setTotalCharactersCounted();
      setValue(name, value);
    }, [value]);

    const countCharacters = (text) => {
      setTotalCharactersCounted(text.target.value.length);
    };

    return (
      <div className={className}>
        <label htmlFor={name} className={classNames('flex items-center text-sm font-medium mb-2 text-gray-700', !label && 'sr-only')}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <div
          className={classNames(
            'flex flex-row justify-center items-center border-2 rounded-md bg-white animate-all duration-400',
            fieldError || dynamicFieldErrors
              ? 'focus-within:border-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus-within:border-primary-500 focus:border-primary-500 border-gray-200',
          )}
        >
          {prefix}
          <input
            {...register(name, rules)}
            className={'block w-full sm:text-sm my-1 mr-1 rounded-md border-none focus:outline-none focus:ring-0 bg-transparent disabled:opacity-50 disabled:bg-gray-200'}
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            onKeyUp={(e) => countCharacters(e)}
            onBlur={onBlur}
            value={value}
            onChange={(e) => retrieveValue(e)}
          />
        </div>
        <div className="flex items-center justify-between">
          {fieldError || dynamicFieldErrors ? (
            <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
          ) : (
            hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
          )}
          {maxLength && (
            <p className="ml-auto mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">
              {totalCharactersCounted} / {maxLength} characters
            </p>
          )}
        </div>
      </div>
    );
  },
  Email: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('EmailInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="email"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => retrieveValue(e)}
        />
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Phone: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('PhoneInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="tel"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => retrieveValue(e)}
        />
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Password: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;
    const [showPassword, setShowPassword] = useState(false);

    // console.log('PasswordInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <div className="mt-1 flex rounded-md shadow-sm flex-row">
          <input
            {...register(name, rules)}
            className={`z-10 flex-1 block w-full rounded-none rounded-l-md sm:text-sm disabled:opacity-50 disabled:bg-gray-200 ${
              fieldError || dynamicFieldErrors
                ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
                : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
            }`}
            type={showPassword ? 'text' : 'password'}
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => retrieveValue(e)}
          />
          <div
            onClick={() => setShowPassword(!showPassword)}
            disabled={disabled}
            className={`z-0 inline-flex items-center px-3 rounded-r-md border border-l-0 bg-gray-50 active:bg-gray-100 text-sm disabled:opacity-50 disabled:bg-gray-200 focus:outline-none ${
              fieldError ? 'border-red-400 text-red-500' : 'border-gray-300 text-gray-500'
            }`}
          >
            {showPassword ? (
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                ></path>
              </svg>
            ) : (
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                ></path>
              </svg>
            )}
          </div>
        </div>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Textarea: ({ name, label, placeholder, maxLength, fieldRows = 3, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;
    let [totalCharactersCounted, setTotalCharactersCounted] = useState(0);

    // console.log('TextareaInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    const countCharacters = (text) => {
      setTotalCharactersCounted(text.target.value.length);
    };

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <textarea
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          name={name}
          id={name}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={fieldRows}
          onChange={(e) => retrieveValue(e)}
          onKeyUp={(e) => countCharacters(e)}
        />
        <div className="flex items-center justify-between">
          {fieldError || dynamicFieldErrors ? (
            <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
          ) : (
            hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
          )}
          {maxLength && (
            <p className="ml-auto mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">
              {totalCharactersCounted} / {maxLength} characters
            </p>
          )}
        </div>
      </div>
    );
  },
  Rating: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, options = [], ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('SelectInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <div className="flex">
          {/* <Rate type="number" name={name} id={name} onChange={setValue} value={value} defaultValue={value} /> */}
          {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (
              <label>
                <StarIcon
                  className="h-10 w-10 hover:bg-gray-50"
                  fill={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                  color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                />
                <input
                  {...register(name, rules)}
                  className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
                    fieldError || dynamicFieldErrors
                      ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
                      : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
                  }`}
                  type="radio"
                  class="invisible"
                  name={name}
                  id={name}
                  value={ratingValue}
                  onClick={() => setRating(ratingValue)}
                />
              </label>
            );
          })}
        </div>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Select: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, options = [], ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, value } = props;

    // console.log('SelectInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);
    useEffect(() => {
      setValue(name, value);
    }, [value]);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <select
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => retrieveValue(e)}
          value={value}
        >
          <option key={0} disabled selected value="">
            {placeholder}
          </option>
          {options.map((option, key) =>
            option.value && option.name ? (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ) : (
              <option key={option} value={option}>
                {option}
              </option>
            ),
          )}
        </select>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Radio: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, options = [], ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('Checkbox: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-1 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-1 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
        <div className="mt-4 space-y-4">
          {options.map((option, key) => (
            <div key={key} className="flex items-center">
              <input
                {...register(name, rules)}
                className={`h-4 w-4 border-gray-300 disabled:opacity-50 disabled:bg-gray-200 ${
                  fieldError || dynamicFieldErrors
                    ? 'focus:ring-primary-500 text-primary-500 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
                    : 'focus:ring-primary-500 text-primary-500'
                }`}
                type="radio"
                id={option.value && option.name ? option.value : option}
                name={name}
                value={option.value && option.name ? option.value : option}
                disabled={disabled}
                onChange={(e) => retrieveValue(e)}
              />
              <label htmlFor={option.value && option.name ? option.value : option} className="ml-3 block text-sm font-medium text-gray-700">
                {option.value && option.name ? option.name : option}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  },
  DatePicker: ({ name, label, placeholder, min, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('DatePicker: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="date"
          min={min}
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => retrieveValue(e)}
        />
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  DatePickerV2: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, disabledDate, dateRender, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    const [value, setValue] = useState(null);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <DatePicker
          size="large"
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          onChange={(current) => {
            const date = current.format('YYYY-MM-DD');
            setValue(date);
            retrieveValue(date);
          }}
          disabledDate={disabledDate}
          dateRender={dateRender}
          disabled={disabled}
          placeholder={placeholder}
          inputReadOnly={true}
        />
        <input
          {...register(name, rules)}
          className={`hidden mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          name={name}
          id={name}
          disabled={disabled}
          value={value}
        />
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  TimePicker: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('TimePicker: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="time"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => retrieveValue(e)}
        />
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Number: ({ name, label, placeholder, value, retrieveValue = () => null, disabled = false, hint = false, type = 'Integer', ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue } = props;

    // console.log('NumberInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="tel"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={(e) => retrieveValue(e)}
          onBlur={(e) => setValue(name, type === 'Integer' ? parseInt(e.target.value) : parseFloat(e.target.value))}
        />
        <div className="flex items-center justify-between">
          {fieldError || dynamicFieldErrors ? (
            <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
          ) : (
            hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
          )}
        </div>
      </div>
    );
  },
  Hidden: ({ name, label, placeholder, maxLength, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    // console.log('TextInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <input
          {...register(name, rules)}
          className={`hidden mt-1 w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
            fieldError || dynamicFieldErrors
              ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
              : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
          }`}
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(e) => retrieveValue(e)}
        />
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  PresetTimePicker: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, times = [], singleSelect = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, defaultValue } = props;

    // console.log('PresetTimePicker: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <div className="mt-1 grid grid-cols-4 gap-4">
          {times.map((time, index, array) => (
            <div>
              {singleSelect ? (
                <div>
                  <label
                    key={index}
                    id={`label_${name}_${time}`}
                    className={` group relative border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase active:bg-primary-500 active:text-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:flex-1 shadow-sm text-gray-700 cursor-pointer`}
                  >
                    <input
                      {...register(name, rules)}
                      type="radio"
                      name={name}
                      value={time}
                      id={`${name}_${time}`}
                      className="sr-only"
                      onChange={(e) => {
                        let thisLabel = document.getElementById(`label_${name}_${time}`);

                        if (e.target.checked) {
                          thisLabel.classList.add('bg-primary-500', 'text-white', 'border-primary-500');
                          let remainingItems = array.filter((e, i) => i !== index);
                          remainingItems.forEach((item, idx) => {
                            let otherLabel = document.getElementById(`label_${name}_${item}`);
                            otherLabel.classList.remove('bg-primary-500', 'text-white', 'border-primary-500');
                          });
                        }
                        retrieveValue(e);
                      }}
                    />
                    <p>{time}</p>
                  </label>
                </div>
              ) : (
                <div>
                  <label
                    key={index}
                    id={`label_${name}_${time}`}
                    className={`${
                      defaultValue && defaultValue.includes(time) ? 'bg-primary-500 text-white border-primary-500' : ''
                    } group relative border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase active:bg-primary-500 active:text-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:flex-1 shadow-sm text-gray-700 cursor-pointer`}
                  >
                    <input
                      {...register(name, rules)}
                      type="checkbox"
                      name={name}
                      value={time}
                      id={`${name}_${time}`}
                      className="sr-only"
                      onChange={(e) => {
                        let thisLabel = document.getElementById(`label_${name}_${time}`);
                        if (e.target.checked) {
                          thisLabel.classList.add('bg-primary-500', 'text-white', 'border-primary-500');
                        } else {
                          thisLabel.classList.remove('bg-primary-500', 'text-white', 'border-primary-500');
                        }
                      }}
                    />
                    <p>{time}</p>
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-3 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  GoogleMap: ({
    name,
    label,
    placeholder,
    retrieveValue = () => null,
    disabled = false,
    hint = false,
    fieldType = 'coordinate',
    hideCurrentLocationButton = false,
    showMap = false,
    tooltipComponent,
    ...props
  }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, defaultValue, setRef, className } = props;

    // console.log('GoogleMapInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [show, setShow] = useState(showMap);

    const mapNode = useRef();

    useImperativeHandle(
      setRef,
      () => ({
        map: mapNode.current,
      }),
      [defaultValue],
    );

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    useEffect(() => {
      if (fieldType === 'address') {
        setValue(name, defaultValue);
        setCoordinates({ lat: 0, lng: 0 });
      } else {
        if (defaultValue?.lat && defaultValue?.lon) {
          setCoordinates({ ...defaultValue, lng: defaultValue?.lon });
        }
      }

      setShow(showMap);
    }, [defaultValue, showMap]);

    const getCurrentLocationCoordinates = () => {
      // Get current location coordinates from browser
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            if (fieldType === 'address') {
              // setValue(name, { lat: coords.latitude, lon: coords.longitude });
              handleGeocode({ coordinates: { lat: coords.latitude, lng: coords.longitude } });
            } else {
              setValue(name, { lat: coords.latitude, lon: coords.longitude });
              setCoordinates({ lat: coords.latitude, lng: coords.longitude });
              retrieveValue({ lat: coords.latitude, lng: coords.longitude });
            }

            setShow(true);
          },
          (error) => {
            console.log('Geolocation Error: ', error);
            alert(`Please enable location access on your browser`);
          },
          { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true },
        );
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    };

    const handleCoordinates = ({ center, coords, manual }) => {
      // Handles coordinates changes when map dragged
      if (fieldType === 'address') {
        setCoordinates({ lat: center.lat(), lng: center.lng() });
        handleGeocode({ coordinates: { lat: center.lat(), lng: center.lng() } });
      } else {
        if (center) {
          // console.log('Center', { lat: center.lat(), lon: center.lng() })
          setValue(name, { lat: center.lat(), lon: center.lng() });
          // setCoordinates({ lat: center.lat(), lng: center.lng() });
          retrieveValue({ lat: center.lat(), lng: center.lng() });
        } else if (coords) {
          // console.log('Coords', { lat: coords.latitude, lon: coords.longitude })
          setValue(name, { lat: coords.latitude, lon: coords.longitude });
          // setCoordinates({ lat: coords.latitude, lng: coords.longitude });
          retrieveValue({ lat: coords.latitude, lng: coords.longitude });
        } else if (manual) {
          // console.log('Manual', { lat: parseFloat(manual.lat) || null, lon: parseFloat(manual.lon) || null })
          setValue(name, { lat: parseFloat(manual.lat) || null, lon: parseFloat(manual.lon) || null });
          // setCoordinates({ lat: parseFloat(manual.lat) || null, lng: parseFloat(manual.lon) || null });
          retrieveValue({ lat: parseFloat(manual.lat) || null, lng: parseFloat(manual.lon) || null });
        }
      }
    };

    const handleOnChange = (e) => {
      // Handle changes after input field blurred
      e.preventDefault();
      if (fieldType === 'address') {
        handleGeocode({ address: e.target.value });
      } else {
        handleCoordinates({ manual: { ...coordinates, [e.target.id.split('.')[1]]: e.target.value } });
        setCoordinates({ ...coordinates, [e.target.id.split('.')[1]]: e.target.value });
      }
    };

    const handleGeocode = async ({ address, coordinates }) => {
      // Handle geocode just like "onGoogleApiLoaded" props on <GoogleMapReact>
      if (mapNode.current) {
        const geocoder = new mapNode.current.maps_.Geocoder();
        if (address) {
          await geocoder.geocode({ address: address }).then(({ results }) => {
            // console.log(' address: address', results[0])
            const { geometry, formatted_address } = results[0];
            setValue(name, formatted_address);
            setCoordinates({ lat: geometry.location.lat(), lng: geometry.location.lng() });
            setShow(true);
            retrieveValue(results[0]);
          });
        } else {
          await geocoder.geocode({ location: coordinates }).then(({ results }) => {
            // console.log(' location: coordinate: ', results[0])
            setValue(name, results[0].formatted_address);
            setCoordinates(coordinates);
            setShow(true);
            retrieveValue(results[0]);
          });
        }
      }
    };

    return (
      <div className={className}>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        {fieldType === 'address' ? (
          <div className="grid grid-cols-5 gap-4">
            <input
              {...register(name, rules)}
              className={`col-span-4 mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
                fieldError || dynamicFieldErrors
                  ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
                  : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
              }`}
              type="text"
              name={name}
              id={name}
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => retrieveValue(e)}
              onBlur={handleOnChange}
            />
            <div className="flex items-center">
              <Tooltip />
              <button
                onClick={() => getCurrentLocationCoordinates()}
                type="button"
                className={classNames(!hideCurrentLocationButton ? 'mt-1 flex rounded-md focus:outline-none' : 'hidden')}
              >
                <span className="p-1.5 rounded-md flex items-center text-sm font-medium bg-white shadow border-gray-300">
                  <PinIcon className="flex-shrink-0 text-primary-500 w-6 h-6" />
                </span>
              </button>
              {(coordinates.lat || coordinates.lon) && (
                <button
                  onClick={() => setShow(!show)}
                  type="button"
                  className={classNames(!hideCurrentLocationButton ? 'ml-4' : 'ml-0', 'mt-1 flex rounded-md focus:outline-none')}
                >
                  <span className="p-1.5 rounded-md flex items-center text-sm font-medium bg-white shadow border-gray-300">
                    {show ? <EyeIcon className="flex-shrink-0 text-primary-500 w-6 h-6" /> : <EyeOffIcon className="flex-shrink-0 text-gray-300 w-6 h-6" />}
                  </span>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-5 gap-4">
            <input
              {...register(`${name}.lat`, rules)}
              className={`col-span-2 mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
                fieldError || dynamicFieldErrors
                  ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
                  : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
              }`}
              type="number"
              name={`${name}.lat`}
              id={`${name}.lat`}
              placeholder="Latitude"
              disabled={disabled}
              onChange={(e) => retrieveValue(e)}
              onBlur={handleOnChange}
            />
            <input
              {...register(`${name}.lon`, rules)}
              className={`col-span-2 mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${
                fieldError || dynamicFieldErrors
                  ? 'focus:ring-red-400 focus:border-red-400 border-red-400 placeholder-red-500 bg-red-50'
                  : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300'
              }`}
              type="number"
              name={`${name}.lon`}
              id={`${name}.lon`}
              placeholder="Longitude"
              disabled={disabled}
              onChange={(e) => retrieveValue(e)}
              onBlur={handleOnChange}
            />
            <div className="flex items-center">
              <div className="relative">
                <button
                  onClick={() => getCurrentLocationCoordinates()}
                  type="button"
                  className={classNames(!hideCurrentLocationButton ? 'mt-1 flex rounded-md focus:outline-none' : 'hidden')}
                >
                  <span className="p-1.5 rounded-md flex items-center text-sm font-medium bg-white shadow border-gray-300">
                    <PinIcon className="flex-shrink-0 text-primary-500 w-6 h-6" />
                  </span>
                </button>
                {tooltipComponent}
              </div>
              {(coordinates.lat || coordinates.lon) && (
                <button
                  onClick={() => setShow(!show)}
                  type="button"
                  className={classNames(!hideCurrentLocationButton ? 'ml-4' : 'ml-0', 'mt-1 flex rounded-md focus:outline-none')}
                >
                  <span className="p-1.5 rounded-md flex items-center text-sm font-medium bg-white shadow border-gray-300">
                    {show ? <EyeIcon className="flex-shrink-0 text-primary-500 w-6 h-6" /> : <EyeOffIcon className="flex-shrink-0 text-gray-300 w-6 h-6" />}
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
        <div className={`${show ? 'block' : 'hidden'} mt-6 h-96 relative rounded-lg overflow-hidden ring-1 ring-black ring-opacity-5`} style={{ width: '100%' }}>
          <GoogleMapReact
            ref={mapNode}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
            defaultCenter={coordinates}
            center={[coordinates.lat, coordinates.lng]}
            defaultZoom={17}
            onDragEnd={handleCoordinates}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={async ({ maps }) => {
              const geocoder = new maps.Geocoder();
              // console.log('coordinates', coordinates);
              if (coordinates.lat && coordinates.lng) {
                // To search address by coordinate
                await geocoder.geocode({ location: coordinates }).then(({ results }) => {
                  // console.log('onGoogleApiLoaded: ', results);
                  if (fieldType === 'address') {
                    setValue(name, results[0].formatted_address);
                    retrieveValue(results[0]);
                  } else {
                    coordinates['lon'] = coordinates.lng;
                    delete coordinates.lng;
                    setValue(name, coordinates);
                    retrieveValue(coordinates);
                  }
                });
              }
            }}
          />
          <svg className="absolute top-[44.5%] left-[48%] w-8 h-8 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
          </svg>
          {/* <span className="absolute top-0 left-0 py-1 px-2 text-primary-100 bg-primary-500 rounded-br-lg">{`${coordinates.lat.toFixed(5)}, ${coordinates.lng.toFixed(5)}`}</span> */}
        </div>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Avatar: ({ name, label, placeholder, retrieveValue = () => null, disabled = false, hint = false, apiParams = {}, overlayMode = () => null, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, defaultValue } = props;

    // console.log('AvatarInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);
    const dispatch = useDispatch();

    const [loadedImage, setLoadedImage] = useState({ image: null, show: false });
    const [cropper, setCropper] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (defaultValue && !croppedImage) {
        setCroppedImage(defaultValue?.path?.m || '');
      }
      overlayMode(loadedImage.show);
    }, [defaultValue, loadedImage.show]);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    const handleImageCrop = () => {
      if (typeof cropper !== 'undefined') {
        const croppedCanvas = cropper.getCroppedCanvas().toDataURL();
        const croppedCanvasBlob = dataURLtoBlob(croppedCanvas);
        const croppedFile = new File([croppedCanvasBlob], `${name}.jpeg`, { type: 'image/jpeg' });
        setCroppedImage(croppedCanvas);
        handleRequestUpload({ ...apiParams, filename: croppedFile.name }, croppedFile);
      }
    };

    const handleCancelCrop = () => {
      setLoadedImage({ image: null, show: false });
    };

    const handleLoadNewAvatar = () => {
      document.getElementById(`${name}-avatar-input`).click();
    };

    const handleChanges = (event) => {
      event.preventDefault();
      const reader = new FileReader();

      reader.onload = () => {
        setLoadedImage({ image: reader.result, show: true });
      };

      reader.readAsDataURL(event.target.files[0]);
    };

    const handleUploadButton = () => {
      if (croppedImage) {
        if (validate.url(croppedImage)) {
          handleLoadNewAvatar();
        } else {
          setLoadedImage({ ...loadedImage, show: true });
        }
      } else {
        handleLoadNewAvatar();
      }
    };

    const handleRequestUpload = (data, theFile) => {
      setLoading(true);
      dispatch(getUploadRequest(data))
        .unwrap()
        .then(async (result) => {
          const { file, uploadTo } = result.data;
          /** Set value to input field */
          setValue(name, file);
          /** Upload file to cloud */
          await handleUpload(uploadTo.presignedUrl, theFile, theFile.type);
          /** Reset loaded image */
          setLoadedImage({ ...loadedImage, show: false });
          setLoading(false);
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
          setLoading(false);
        });
    };

    const handleUpload = async (url, file) => {
      let params = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: file,
      };

      let resp = await fetch(url, params);
      return resp;
    };

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <div className={`${croppedImage || !loadedImage.show ? 'flex' : 'hidden'} mt-1 items-center`}>
          <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 ring-1 ring-gray-700 ring-opacity-10">
            {croppedImage ? (
              <Image src={croppedImage} alt="avatar" />
            ) : (
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            )}
          </span>
          <button
            type="button"
            onClick={handleUploadButton}
            disabled={disabled}
            className={`disabled:opacity-50 ml-4 bg-white py-2 px-3 border rounded-md shadow-sm text-sm leading-4 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              fieldError || dynamicFieldErrors ? 'border-red-400 text-red-500 bg-red-50' : 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-primary-500'
            }`}
          >
            {croppedImage ? 'Change' : 'Upload'}
          </button>
        </div>
        <div className={`${!loadedImage.show ? 'hidden' : 'block'} relative mt-1.5 border rounded-md shadow overflow-hidden`}>
          <div className={`${loading ? 'block' : 'hidden'} z-10 absolute bottom-11 bg-black bg-opacity-70 inset-0 flex items-center justify-center`}>
            <svg className="animate-spin h-20 w-20 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <input id={`${name}-avatar-input`} className="sr-only" type="file" accept=".jpeg,.jpg,.png,.webp,.gif,.svg" onChange={handleChanges} />
          <input {...register(name, rules)} className="sr-only" type="text" />
          <Cropper
            className="block w-full rounded-md cropper-avatar-view-box cropper-avatar-face"
            aspectRatio={1}
            cropBoxResizable={true}
            src={loadedImage.image}
            viewMode={1}
            dragMode="move"
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <div className="bg-white border-t grid grid-cols-3 divide-x">
            <button
              type="button"
              onClick={handleLoadNewAvatar}
              disabled={disabled || loading}
              className="disabled:opacity-50 flex items-center justify-center px-2 py-3 hover:bg-gray-50 text-sm"
            >
              <UploadIcon className="h-5 w-5 flex-shrink-0 mr-2" />
              Load new image
            </button>
            <button
              type="button"
              onClick={handleImageCrop}
              disabled={disabled || loading}
              className="disabled:opacity-50 flex items-center justify-center px-2 py-3 hover:bg-gray-50 text-sm"
            >
              <CropIcon className="h-5 w-5 flex-shrink-0 mr-2" />
              Crop
            </button>
            <button
              type="button"
              onClick={handleCancelCrop}
              disabled={disabled || loading}
              className="disabled:opacity-50 flex items-center justify-center px-2 py-3 hover:bg-gray-50 text-sm"
            >
              <CloseIcon className="h-5 w-5 flex-shrink-0 mr-2" />
              Cancel
            </button>
          </div>
          <div className="absolute z-20 top-0 right-0 mr-2 mt-2">
            <div className="flex flex-col bg-white border rounded-md divide-y">
              <button type="button" onClick={() => cropper.zoom(0.1)} disabled={disabled || loading} className="disabled:opacity-50 flex items-center justify-center h-8 w-8">
                <ZoomInIcon className="h-5 w-5 flex-shrink-0 hover:bg-gray-50" />
              </button>
              <button type="button" onClick={() => cropper.zoom(-0.1)} disabled={disabled || loading} className="disabled:opacity-50 flex items-center justify-center h-8 w-8">
                <ZoomOutIcon className="h-5 w-5 flex-shrink-0 hover:bg-gray-50" />
              </button>
            </div>
          </div>
        </div>
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  File: ({
    name,
    label,
    placeholder,
    accept,
    retrieveValue = () => null,
    disabled = false,
    hint = false,
    apiParams = {},
    overlayMode = () => null,
    withPhotoCropper = false,
    aspectRatio = 1,
    maxFiles = 0,
    imageDimension,
    imageSize = 's',
    ...props
  }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, defaultValue } = props;

    const [loadedImage, setLoadedImage] = useState({ image: null, show: false });
    const [cropper, setCropper] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [changeFile, setChangeFile] = useState(true);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);
    const dispatch = useDispatch();

    useEffect(() => {
      let files = [];
      if (defaultValue && acceptedFiles.length === 0) {
        if (withPhotoCropper) {
          setCroppedImage(defaultValue.path[imageSize]);
        } else {
          files.push(defaultValue);
          setUploadedFiles(files);
          setChangeFile(false);
        }
      }

      overlayMode(loadedImage.show);
    }, [defaultValue, loadedImage.show]);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (withPhotoCropper) {
            setLoadedImage({ image: reader.result, show: true });
          } else {
            setChangeFile(false);
            handleRequestUpload({ ...apiParams, filename: file.name }, file);
          }
        };
        reader.readAsDataURL(file);
      });
    }, []);

    const handleCancelCrop = () => {
      setLoadedImage({ image: null, show: false });
    };

    const handleImageCrop = () => {
      if (typeof cropper !== 'undefined' && withPhotoCropper) {
        const croppedCanvas = cropper.getCroppedCanvas().toDataURL();
        const croppedCanvasBlob = dataURLtoBlob(croppedCanvas);
        const croppedFile = new File([croppedCanvasBlob], `${name}.jpeg`, { type: 'image/jpeg' });
        handleRequestUpload({ ...apiParams, filename: croppedFile.name }, croppedFile, croppedCanvas);
      }
    };

    const handleLoadNewImage = () => {
      document.getElementById(`${name}-file-input`).click();
    };

    const handleRequestUpload = (data, theFile, preview) => {
      setLoading(true);
      dispatch(getUploadRequest(data))
        .unwrap()
        .then(async (result) => {
          const { file, uploadTo } = result.data;
          /** Set value to input field */
          setValue(name, file);
          /** Upload file to cloud */
          await handleUpload(uploadTo.presignedUrl, theFile, theFile.type);
          /** Reset loaded image */
          if (withPhotoCropper) {
            setLoadedImage({ ...loadedImage, show: false });
            setCroppedImage(preview);
          }
          setLoading(false);
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
          setLoading(false);
        });
    };

    const handleUpload = async (url, file) => {
      dispatch(uploadFile({ url: url, file: file }))
        .unwrap()
        .then((result) => {
          // Success upload
          return result;
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    };

    const handleGetPrivateFile = (file_path) => {
      setLoading(true);
      dispatch(getPrivateFile(file_path))
        .unwrap()
        .then(async (result) => {
          const { signUrl } = result.data;
          const win = window.open(signUrl, '_blank');
          win.focus();
          setLoading(false);
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
          setLoading(false);
        });
    };

    const handleFileChange = (index) => {
      setChangeFile(true);
      setUploadedFiles([]);
      if (typeof index === 'number') {
        setValue(name, '');
        acceptedFiles.splice(index, 1);
      }
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: accept,
      maxFiles: maxFiles,
      disabled: disabled,
    });

    const handleFileRemove = (index) => {
      setChangeFile(true);
      setValue(name, '');
      acceptedFiles.splice(index, 1);
    };

    const localFileLists = acceptedFiles.map((file, index) => (
      <li key={file.path} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
        <div className="w-0 flex-1 flex items-center">
          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
              clipRule="evenodd"
            />
          </svg>
          {file.hasOwnProperty('isPublic') ? (
            <button
              type="button"
              onClick={() => handleGetPrivateFile(file.path)}
              className="ml-2 truncate text-primary-600 dark:text-primary-500 hover:text-primary-700 dark:hover:text-primary-600 font-medium border-b border-dotted border-primary-600 dark:border-primary-500 hover:border-primary-700 dark:hover:border-primary-600"
            >
              {`${file.path.split('/')[2]}.${file.path.split('/')[3].split('.')[1]}`}
            </button>
          ) : (
            <span className="ml-2 truncate">{file.path}</span>
          )}
        </div>
        <div className="ml-4 flex-shrink-0 flex space-x-3">
          <button
            type="button"
            onClick={() => handleFileChange(index)}
            disabled={disabled || loading}
            className="disabled:opacity-50 font-medium text-primary-600 hover:text-primary-500"
          >
            <RefreshIcon className="w-5 h-5 flex-shrink-0" />
          </button>
          <button
            type="button"
            onClick={() => handleFileRemove(index)}
            disabled={disabled || loading}
            className="disabled:opacity-50 font-medium text-primary-600 hover:text-primary-500"
          >
            <TrashIcon className="w-5 h-5 flex-shrink-0" />
          </button>
        </div>
      </li>
    ));

    const uploadedFileLists = uploadedFiles.map((file, index) => (
      <li key={file.path} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
        <div className="w-0 flex-1 flex items-center">
          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
              clipRule="evenodd"
            />
          </svg>
          {file.hasOwnProperty('isPublic') ? (
            <button
              type="button"
              onClick={() => handleGetPrivateFile(file.path)}
              className="ml-2 truncate text-primary-600 dark:text-primary-500 hover:text-primary-700 dark:hover:text-primary-600 font-medium border-b border-dotted border-primary-600 dark:border-primary-500 hover:border-primary-700 dark:hover:border-primary-600"
            >
              {`${file.path.split('/')[2]}.${file.path.split('/')[3].split('.')[1]}`}
            </button>
          ) : (
            <span className="ml-2 truncate">{file.path}</span>
          )}
        </div>
        <div className="ml-4 flex-shrink-0 flex space-x-3">
          <button type="button" onClick={handleFileChange} disabled={disabled || loading} className="disabled:opacity-50 font-medium text-primary-600 hover:text-primary-500">
            <RefreshIcon className="w-5 h-5 flex-shrink-0" />
          </button>
        </div>
      </li>
    ));

    return (
      <div>
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <div className={classNames(imageDimension, `${withPhotoCropper ? (croppedImage ? 'block' : 'hidden') : 'hidden'} mt-2 relative`)}>
          <div className={classNames(imageDimension, 'relative rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden')}>
            <Image
              src={croppedImage}
              onError={(e) => {
                e.target.src = imgNotFound;
                e.target.classList.add('opacity-80', 'p-1');
              }}
              alt="img-preview"
              className="absolute h-full w-full object-cover object-center"
            />
          </div>
          <div className="absolute z-20 top-0 right-0 mr-2 mt-2">
            <div className="flex flex-col bg-white border rounded-md overflow-hidden shadow">
              <button
                type="button"
                onClick={handleLoadNewImage}
                disabled={disabled || loading}
                className={`disabled:opacity-50 text-sm font-semibold bg-white text-gray-700 py-1 px-2 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
              >
                Change
              </button>
            </div>
          </div>
        </div>
        <div
          {...getRootProps({
            className: classNames(
              !changeFile || croppedImage ? 'sr-only' : '',
              disabled ? 'cursor-not-allowed' : '',
              'relative',
              fieldError || dynamicFieldErrors ? 'text-red-500 bg-red-50' : '',
            ),
          })}
        >
          <div
            className={classNames(
              fieldError || dynamicFieldErrors ? 'border-red-500' : 'border-gray-300',
              'mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md',
            )}
          >
            <div className="space-y-1 text-center">
              <svg
                className={classNames(fieldError || dynamicFieldErrors ? 'text-red-500' : 'text-gray-400', 'mx-auto h-12 w-12')}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className={classNames(fieldError || dynamicFieldErrors ? 'text-red-500' : 'text-gray-600', 'flex text-sm')}>
                <input {...getInputProps({ id: `${name}-file-input` })} />
                <p>Click or drag and drop the file here to upload</p>
              </div>
              <p className={classNames(fieldError || dynamicFieldErrors ? 'text-red-500' : 'text-gray-500', 'text-xs')}>
                {`Only ${accept.replace(/([.])/g, ' ').toUpperCase()} accepted`}
              </p>
            </div>
          </div>
          <div
            aria-hidden={disabled}
            className={classNames(!disabled ? 'hidden' : '', 'absolute -inset-px rounded-md border-2 border-gray-200 pointer-events-none bg-gray-50 bg-opacity-80')}
          >
            <svg className="absolute inset-0 w-full h-full text-gray-200 stroke-2" viewBox="0 0 100 100" preserveAspectRatio="none" stroke="currentColor">
              <line x1="0" y1="100" x2="100" y2="0" vectorEffect="non-scaling-stroke" />
            </svg>
          </div>
        </div>
        {withPhotoCropper ? (
          <div className={`${!loadedImage.show ? 'hidden' : 'block'} relative mt-1.5 border rounded-md shadow overflow-hidden`}>
            <div className={`${loading ? 'block' : 'hidden'} z-10 absolute bottom-11 bg-black bg-opacity-70 inset-0 flex items-center justify-center`}>
              <svg className="animate-spin h-20 w-20 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
            <input {...register(name, rules)} className="sr-only" type="text" />
            <Cropper
              className="block w-full rounded-md cropper-avatar-view-box cropper-avatar-face"
              aspectRatio={aspectRatio}
              cropBoxResizable={true}
              src={loadedImage.image}
              viewMode={1}
              dragMode="move"
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
            <div className="bg-white border-t grid grid-cols-3 divide-x">
              <button
                type="button"
                onClick={handleLoadNewImage}
                disabled={disabled || loading}
                className="disabled:opacity-50 flex items-center justify-center px-2 py-3 hover:bg-gray-50 text-sm"
              >
                <UploadIcon className="h-5 w-5 flex-shrink-0 mr-2" />
                Load new image
              </button>
              <button
                type="button"
                onClick={handleImageCrop}
                disabled={disabled || loading}
                className="disabled:opacity-50 flex items-center justify-center px-2 py-3 hover:bg-gray-50 text-sm"
              >
                <CropIcon className="h-5 w-5 flex-shrink-0 mr-2" />
                Crop
              </button>
              <button
                type="button"
                onClick={handleCancelCrop}
                disabled={disabled || loading}
                className="disabled:opacity-50 flex items-center justify-center px-2 py-3 hover:bg-gray-50 text-sm"
              >
                <CloseIcon className="h-5 w-5 flex-shrink-0 mr-2" />
                Cancel
              </button>
            </div>
            <div className="absolute z-20 top-0 right-0 mr-2 mt-2">
              <div className="flex flex-col bg-white border rounded-md divide-y">
                <button type="button" onClick={() => cropper.zoom(0.1)} disabled={disabled || loading} className="disabled:opacity-50 flex items-center justify-center h-8 w-8">
                  <ZoomInIcon className="h-5 w-5 flex-shrink-0 hover:bg-gray-50" />
                </button>
                <button type="button" onClick={() => cropper.zoom(-0.1)} disabled={disabled || loading} className="disabled:opacity-50 flex items-center justify-center h-8 w-8">
                  <ZoomOutIcon className="h-5 w-5 flex-shrink-0 hover:bg-gray-50" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <ul className={`${localFileLists.length > 0 ? 'block' : 'hidden'} mt-2 border border-gray-200 rounded-md divide-y divide-gray-200`}>{localFileLists}</ul>
            <ul className={`${uploadedFileLists.length > 0 ? 'block' : 'hidden'} mt-2 border border-gray-200 rounded-md divide-y divide-gray-200`}>{uploadedFileLists}</ul>
          </>
        )}
        {fieldError || dynamicFieldErrors ? (
          <p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{fieldError?.message || dynamicFieldErrors?.message}</p>
        ) : (
          hint && <p className="mt-2 text-xs text-gray-500 tracking-wide font-medium sm:font-normal">{hint}</p>
        )}
      </div>
    );
  },
  Switch: ({ name, label, placeholder, maxLength, tooltipComponent, retrieveValue = () => null, disabled = false, hint = false, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue, defaultValue } = props;

    // console.log('TextInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);
    const [enabled, setEnabled] = useState(false);
    const [hover, setHover] = useState(false);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    useEffect(() => {
      setEnabled(defaultValue || false);
      setValue(name, defaultValue || false);
    }, [defaultValue]);

    const handleSwitch = (isEnable) => {
      setEnabled(isEnable);
      setValue(name, isEnable);
      retrieveValue(isEnable);
    };

    return (
      <div>
        <div className="flex items-center justify-between">
          <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
            {rules.required && <span className="text-red-500 mr-1">*</span>}
            {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
            {label}
          </label>

          <input {...register(name, rules)} type="checkbox" className="sr-only" />
          {!disabled ? (
            <Switch
              checked={enabled}
              onChange={handleSwitch}
              className={classNames(
                enabled ? 'bg-primary-600' : 'bg-gray-400',
                disabled ? 'opacity-30 cursor-not-allowed ' : '',
                'relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
              pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          ) : (
            <Switch
              checked={enabled}
              onChange={() => !disabled && handleSwitch}
              className={classNames(
                enabled ? 'bg-primary-600' : 'bg-primary-400',
                disabled ? 'opacity-30 cursor-not-allowed ' : '',
                'relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
              )}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-4' : 'translate-x-4'}
              pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          )}
        </div>
        {hover && <div className="text-primary-600">{tooltipComponent}</div>}
      </div>
    );
  },
  Quantity: ({ name, label, placeholder, maxLength, retrieveValue = () => null, disabled = false, hint = false, canAddMore, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName, setValue } = props;

    // console.log('NumberInput: ', name, register, errors, rules, groupName, groupIndex, fieldName);

    let dynamicFieldErrors;
    let fieldError = _.get(errors, name);

    if (errors.hasOwnProperty(groupName)) {
      dynamicFieldErrors = errors[groupName][groupIndex] && errors[groupName][groupIndex][fieldName];
    }

    const handleQuantity = (action) => {
      let newValue = parseInt(document.getElementById(name).value);

      if (action === 'minus') {
        if (newValue > 0) {
          newValue -= 1;
          setValue(name, newValue);
          retrieveValue({ value: newValue });
        }
      } else if (action === 'add') {
        newValue += 1;
        setValue(name, newValue);
        retrieveValue({ value: newValue });
      } else {
        // DO NOTHING
      }
    };

    return (
      <div className="flex rounded-md">
        <button
          onClick={() => handleQuantity('minus')}
          type="button"
          // disabled={disabled}
          className="disabled:opacity-50 disabled:bg-gray-200 z-10 flex items-center px-1.5 sm:px-2 rounded-l-md border border-gray-300 bg-gray-100 text-gray-900 text-sm focus:outline-none focus:bg-primary-200 focus:text-primary-600 focus:border-primary-500"
        >
          <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
          </svg>
        </button>
        <input
          {...register(name, rules)}
          type="tel"
          name={name}
          id={name}
          className="z-0 py-1 w-14 -mx-0.5 border focus:z-20 focus:ring-primary-500 focus:border-primary-500 shadow-sm sm:text-sm border-gray-300 text-center"
          step={1}
          min={0}
          inputMode="numeric"
          defaultValue={0}
          onBlur={(e) => setValue(name, parseInt(e.target.value))}
          readOnly
          // disabled={disabled}
        />
        <button
          onClick={() => handleQuantity('add')}
          type="button"
          disabled={disabled || !canAddMore}
          className="disabled:opacity-50 disabled:bg-gray-200 z-10 flex items-center px-1 sm:px-1.5 rounded-r-md border border-gray-300 bg-gray-100 text-gray-700 text-sm focus:outline-none focus:bg-primary-200 focus:text-primary-600 focus:border-primary-500"
        >
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
          </svg>
        </button>
      </div>
    );
  },
  QuoteQuantity: ({ name, currentValue, label, placeholder, maxLength, retrieveValue = () => null, disabled = false, hint = false, canAddMore, ...props }) => {
    const { register, errors, rules, groupName, groupIndex, fieldName } = props;

    const [value, setValue] = useState(currentValue);

    const handleQuantity = (action) => {
      let newValue = currentValue;

      if (action === 'minus') {
        if (value > 0) {
          newValue -= 1;
          setValue(parseInt(newValue));
          retrieveValue({ value: parseInt(newValue) });
        }
      } else if (action === 'add') {
        newValue += 1;
        setValue(newValue);
        retrieveValue({ value: parseInt(newValue) });
      } else {
        // DO NOTHING
      }
    };

    return (
      <div className="flex rounded-md">
        <button
          onClick={() => handleQuantity('minus')}
          type="button"
          className="disabled:opacity-50 disabled:bg-gray-200 z-10 flex items-center px-1.5 sm:px-2 rounded-l-md border border-gray-300 bg-gray-100 text-gray-900 text-sm focus:outline-none focus:bg-primary-200 focus:text-primary-600 focus:border-primary-500"
        >
          <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
          </svg>
        </button>
        <input
          type="tel"
          className="z-0 py-1 w-14 -mx-0.5 border focus:z-20 focus:ring-primary-500 focus:border-primary-500 shadow-sm sm:text-sm border-gray-300 text-center"
          step={1}
          min={0}
          inputMode="numeric"
          value={currentValue}
          readOnly
        />
        <button
          onClick={() => handleQuantity('add')}
          type="button"
          disabled={disabled || !canAddMore}
          className="disabled:opacity-50 disabled:bg-gray-200 z-10 flex items-center px-1 sm:px-1.5 rounded-r-md border border-gray-300 bg-gray-100 text-gray-700 text-sm focus:outline-none focus:bg-primary-200 focus:text-primary-600 focus:border-primary-500"
        >
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
          </svg>
        </button>
      </div>
    );
  },
};

export const DynamicField = ({ children, register, control, errors, ...props }) => {
  const { className, name, onChange, fieldNames } = props;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: name,
  });

  const handleChild = (child, field, index) => {
    return React.cloneElement(child, {
      ...child.props,
      key: field?.id,
      groupName: name,
      groupIndex: index,
      fieldName: child.props.name,
      name: `${name}[${index}].${child.props.name}`,
      className: child.props.className,
      register: register,
      rules: constructRules(child.props.rules),
      errors: errors,
      onChange: onChange,
    });
  };

  const loopChildren = (children, field, index) => {
    return React.Children.map(children, (child) => {
      if (typeof child === 'object') {
        const { children } = child.props;
        if (!children) {
          // console.log('[DynamicField] - No child component: ', child);
          if (typeof child.type === 'function') {
            return handleChild(child, field, index);
          } else if (typeof child.type === 'string') {
            return React.createElement(child.type, {
              ...{
                ...child.props,
              },
            });
          } else {
            return React.cloneElement(child.type, {
              ...{
                ...child.props,
              },
            });
          }
        } else {
          if (Array.isArray(children)) {
            // console.log('[DynamicField] - Have children (Array): ', child);
          } else if (typeof children === 'object') {
            // console.log('[DynamicField] - Have children (Object): ', child);
            return React.createElement(
              child.type,
              {
                ...{
                  ...child.props,
                },
              },
              loopChildren(children, field, index),
            );
          } else {
            // console.log('[DynamicField] - Have children (Array/Object): ', child);
            return React.createElement(child.type, {
              ...{
                ...child.props,
              },
            });
          }
        }
      } else {
        return child;
      }
    });
  };

  const handleRemove = (name, index) => {
    remove(index);
    onChange('remove', name, index);
  };

  const handleAppend = (name) => {
    let dataObj = {};
    fieldNames.forEach((name) => {
      dataObj[name] = '';
    });
    append(dataObj);
    onChange('append', name, null);
  };

  return (
    <>
      <div className={className}>
        {fields.map((field, index) => {
          return (
            <>
              {loopChildren(children, field, index)}
              <button type="button" onClick={() => handleRemove(name, index)} className="col-span-1 focus:outline-none">
                <span className="sr-only">Remove field</span>
                <svg className="w-5 h-5 text-red-500 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </button>
            </>
          );
        })}
      </div>
      <button
        type="button"
        onClick={() => handleAppend(name)}
        className={`${fields.length > 0 && 'mt-6'} flex items-center text-sm text-primary-700 font-medium focus:outline-none`}
      >
        <span className="sr-only">Append field</span>
        <svg className="w-6 h-6 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
        </svg>
        Assign new role
      </button>
    </>
  );
};

const constructRules = (rules = [], getValues) => {
  let newRule = {};

  rules.map((rule) => {
    let keys = Object.keys(rule);
    let values = Object.values(rule);

    if (keys[0] === 'validate') {
      newRule[keys[0]] = {};
      newRule[keys[0]] = () => getValues(values[0].first) === getValues(values[0].second) || values[1];
    } else {
      newRule[keys[0]] = {};
      newRule[keys[0]].value = values[0];
      newRule[keys[0]].message = values[1];
    }
  });

  return newRule;
};
