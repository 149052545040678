import api from './index';

class OrderService {
  submitOrder(data) {
    return api.post('/customer/order', data).then((response) => {
      return response.data;
    });
  }
}

export default new OrderService();
