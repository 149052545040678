import { isNotNullUndefined } from './nullUndefined';

export function isObject(obj) {
  return typeof obj === 'object';
}

export function isObjectValid(obj) {
  return isNotNullUndefined(obj) && isObject(obj);
}

export function isObjectEmpty(obj) {
  return isObjectValid(obj) && Object.keys(obj).length == 0;
}

export function isObjectNotEmpty(obj) {
  return isObjectValid(obj) && Object.keys(obj).length > 0;
}
