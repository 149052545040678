import { RWebShare } from 'react-web-share';

const ShareButton = ({ vendorPath, storeName }) => {
  const shareUrl = `${window.location.protocol}//${window.location.host}/vendor/${vendorPath}`;
  return (
    <RWebShare
      data={{
        text: '',
        url: shareUrl,
        title: `Share ${storeName} `,
      }}
      sites={['whatsapp', 'facebook', 'twitter', 'telegram', 'copy']}
      onClick={() => console.log('shared successfully!')}
    >
      <button className="ml-auto mt-1 px-2 inline-flex  leading-5 font-semibold rounded-md bg-primary-100 text-primary-600">Share Now 🔗</button>
    </RWebShare>
  );
};
export default ShareButton;
