import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import { showToast } from '../../../store/slices/components.slice';
import { listScheduleClose, createUpdateScheduleClose } from '../../../store/slices/vendor.slice';
import Tooltip from '../../../components/Tooltip';

import { DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
import './index.css';

const { RangePicker } = DatePicker;

const StoreProductsUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { groupDate } = useParams();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [scheduleCloseObj, setScheduleCloseObj] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([moment(`${groupDate}-01`), null]);

  useEffect(() => {
    getScheduleClose({ start: groupDate, end: groupDate });
  }, []);

  const getScheduleClose = (query) => {
    dispatch(listScheduleClose(query))
      .unwrap()
      .then((result) => {
        const { data } = result;
        let scheduleCloseObjNew = [];
        data?.scheduleCloses?.forEach((group) => {
          group.dates.forEach((date) => {
            scheduleCloseObjNew.push({ date, status: 'DEFAULT' });
          });
        });
        setScheduleCloseObj(scheduleCloseObjNew);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const submitScheduleClose = () => {
    const body = {
      groupDate,
      dates: scheduleCloseObj?.map((item) => {
        return item.date;
      }),
    };
    dispatch(createUpdateScheduleClose(body))
      .unwrap()
      .then((result) => {
        const { data } = result;
        history.push({ pathname: '/store/orders', search: `?groupDate=${groupDate}` });
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const handleDateRange = (data) => {
    setSelectedDateRange(data);
  };

  const addDates = () => {
    let scheduleCloseObjNew = [...scheduleCloseObj];
    for (var m = moment(selectedDateRange[0]); m.diff(selectedDateRange[1], 'days') <= 0; m.add(1, 'days')) {
      const date = m.format('YYYY-MM-DD');
      scheduleCloseObjNew.push({ date, status: 'NEW' });
    }
    scheduleCloseObjNew = _.uniqBy(scheduleCloseObjNew, function (e) {
      return e.date;
    });
    scheduleCloseObjNew = _.sortBy(scheduleCloseObjNew, ['date']);
    setScheduleCloseObj(scheduleCloseObjNew);
    setSelectedDateRange([null, null]);
    setDisabledSubmit(false);
  };
  const deleteDate = (date) => {
    const scheduleCloseObjNew = scheduleCloseObj.filter((item) => item.date !== date);
    setScheduleCloseObj(scheduleCloseObjNew);
    setDisabledSubmit(false);
  };

  return (
    <>
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to={`/store/orders?groupDate=${groupDate}`}>Back to Orders</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
      </ul>
      <div className=" bg-white ">
        <div class="grid grid-cols-3 gap-1 py-2 px-2">
          <div class="... flex items-center justify-center col-span-3 ">
            <h2>{moment(`${groupDate}-01`).format('MMMM YYYY')}</h2>
          </div>
          <div class="... flex items-center justify-end col-span-2 ">
            <Space direction="vertical" size={20}>
              <RangePicker
                className={!(selectedDateRange[0] && selectedDateRange[1]) && ' border-green-400 border-2'}
                superNextIcon={null}
                superPrevIcon={null}
                nextIcon={null}
                prevIcon={null}
                format="YYYY-MM-DD"
                allowClear={false}
                presets={[moment(`${groupDate}-01`)]}
                inputReadOnly={true}
                disabledDate={(current) => {
                  return current.format('YYYY-MM') !== moment(groupDate).format('YYYY-MM');
                }}
                dateRender={(current) => {
                  const style = {};
                  scheduleCloseObj?.forEach((item) => {
                    if (item.date === current.format('YYYY-MM-DD')) {
                      style.background = '#ffcccc';
                    }
                  });
                  return (
                    <div className="ant-picker-cell-inner" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
                value={selectedDateRange}
                onCalendarChange={handleDateRange}
              />
            </Space>
          </div>
          <div class="... flex items-center justify-center ">
            <button
              type="button"
              onClick={addDates}
              name="addDates"
              disabled={!(selectedDateRange[0] && selectedDateRange[1])}
              className={`disabled:opacity-50 font-semibold bg-white border border-primary-600 text-primary-600 w-full py-1 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
            >
              Add
            </button>
          </div>
          <div class="... flex items-center justify-center col-span-3  ">
            <button
              type="button"
              onClick={submitScheduleClose}
              name="submitScheduleClose"
              disabled={disabledSubmit}
              className={`disabled:opacity-50 w-full py-1 font-semibold border border-transparent shadow-sm text-sm rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            >
              Submit
            </button>
          </div>
          <div class="... flex items-center justify-center col-span-3 ">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className=" bg-primary-50 px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="bg-primary-50 px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {scheduleCloseObj?.map((item, index) => (
                  <tr>
                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">
                      {item.date}
                      {item.status === 'DEFAULT' && <span className=" text-gray-400"> {item.status}</span>}
                      {item.status === 'NEW' && <span className=" text-green-400"> {item.status}</span>}
                    </td>
                    <td className="px-6 py-0 whitespace-nowrap text-left text-sm">
                      <button
                        type="button"
                        onClick={() => deleteDate(item.date)}
                        name="deleteDate"
                        className={`disabled:opacity-50 text-sm font-semibold bg-white text-red-600 py-1 px-2 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreProductsUpdate;
