import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/slices/components.slice';

const Toast = ({ show = false, type, message, errors = [] }) => {
  const dispatch = useDispatch();
  const { components } = useSelector((state) => state);
  const toast = components.toast;

  useEffect(() => {
    if (toast.show && !['warning', 'error'].includes(type)) {
      const timer = setTimeout(() => {
        dispatch(showToast({ ...toast, show: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, dispatch, toast]);

  switch (type) {
    case 'success':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="z-40 fixed top-0 w-full">
            <div className="fixed inset-x-0 flex items-start max-w-2xl mx-4 sm:mx-auto my-4 rounded-xl p-3 ring-1 bg-green-100 ring-green-500 text-green-600 shadow-md">
              <svg className="w-5 h-5 mr-2 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="text-sm font-medium">{message}</span>
              <button type="button" onClick={() => dispatch(showToast({ ...toast, show: false }))} className="ml-auto">
                <svg className="w-5 h-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </Transition>
      );
    case 'warning':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="z-40 fixed top-0 w-full">
            <div className="flex items-start max-w-2xl mx-4 sm:mx-auto my-4 rounded-xl p-3 ring-1 bg-amber-100 ring-amber-500 text-amber-600 shadow-md">
              <svg className="w-5 h-5 mr-2 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="text-sm font-medium">{message}</span>
              <button type="button" onClick={() => dispatch(showToast({ ...toast, show: false }))} className="ml-auto">
                <svg className="w-5 h-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </Transition>
      );
    case 'error':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="z-40 fixed top-0 w-full">
            <div className="fixed inset-x-0 flex flex-col items-start max-w-2xl mx-4 sm:mx-auto my-4 rounded-xl p-3 ring-1 bg-red-100 ring-red-500 text-red-600 shadow-md">
              <div className="flex items-start w-full">
                <svg className="w-5 h-5 mr-2 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-sm font-medium">{message}</span>
                <button type="button" onClick={() => dispatch(showToast({ ...toast, show: false }))} className="ml-auto">
                  <svg className="w-5 h-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className={`${errors.length > 0 ? 'block' : 'hidden'} mt-2 ml-11 text-red-600 text-sm`}>
                <ul className="list-disc space-y-1">
                  {errors.map((error, index) => (
                    <li key={index}>
                      <span className="font-medium">[{error.context.label}]</span> - {error.message}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Transition>
      );
    case 'info':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="z-40 fixed top-0 w-full">
            <div className="flex items-start max-w-2xl mx-4 sm:mx-auto my-4 rounded-xl p-3 ring-1 bg-sky-100 ring-sky-500 text-sky-600 shadow-md">
              <svg className="w-5 h-5 mr-2 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="text-sm font-medium">{message}</span>
              <button type="button" onClick={() => dispatch(showToast({ ...toast, show: false }))} className="ml-auto">
                <svg className="w-5 h-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </Transition>
      );
    default:
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="z-40 fixed top-0 w-full">
            <div className="flex items-start max-w-2xl mx-4 sm:mx-auto my-4 rounded-xl p-3 ring-1 bg-gray-100 ring-gray-500 text-gray-600 shadow-md">
              {/* <svg className="w-5 h-5 mr-2 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg> */}
              <span className="text-sm font-medium">{message}</span>
              <button type="button" onClick={() => dispatch(showToast({ ...toast, show: false }))} className="ml-auto">
                <svg className="w-5 h-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </Transition>
      );
  }
};

export default Toast;
