import { Fragment, useEffect, useRef, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';

export const Modal = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-700 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                  <svg
                    onClick={() => hideModal()}
                    className={`${closeable ? 'block' : 'hidden'} flex-none ml-auto w-5 h-5 cursor-pointer`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 overflow-scroll">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-primary-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
                <button
                  onClick={() => hideModal()}
                  disabled={loading}
                  type="button"
                  className={`${
                    closeable ? 'inline-flex' : 'hidden'
                  } disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalDiscard = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, closeAction, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-600 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium text-white">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 ">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={() => closeAction()}
                  disabled={loading}
                  type="button"
                  className={`disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-red-600 hover:bg-red-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalContact = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-700 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-xl font-medium text-white">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                  <svg
                    onClick={() => hideModal()}
                    className={`${closeable ? 'block' : 'hidden'} flex-none ml-auto w-5 h-5 cursor-pointer`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 ">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-primary-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
                <button
                  onClick={() => hideModal()}
                  disabled={loading}
                  type="button"
                  className={`${
                    closeable ? 'inline-flex' : 'hidden'
                  } disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalVendor = ({
  show = false,
  closeable = true,
  loading = false,
  hide,
  title,
  description,
  value,
  confirmText,
  cancelText,
  closeAction,
  confirmAction,
  children,
}) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-600 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium text-white">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 ">
                <div className="grid grid-cols-4 gap-4 sm:gap-4 p-6">
                  <div className="col-span-4 sm:col-span-4">
                    <span className="font-medium text-sm">Customer:</span>
                    <p className="text-sm text-gray-500">{value?.customer?.name}</p>
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <span className="font-medium text-sm">Delivery Date:</span>
                    <p className="text-sm text-gray-500">{value?.deliverDate}</p>
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <span className="font-medium text-sm">Delivery Time:</span>
                    <p className="text-sm text-gray-500">{value?.deliverTime}</p>
                  </div>
                  <div className="col-span-4 sm:col-span-4">
                    <span className="font-medium text-sm">Delivery Address:</span>
                    <p className="text-sm text-gray-500">
                      {value?.address1} {value?.address2}, {value?.postalCode} {value?.city} {value?.state}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={() => closeAction()}
                  disabled={loading}
                  type="button"
                  className={`disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-orange-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-orange-600 hover:bg-orange-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalUsage = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, closeAction, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-600 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium text-white">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 ">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={() => closeAction()}
                  disabled={loading}
                  type="button"
                  className={`disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-red-600 hover:bg-red-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalLogin = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, closeAction, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();
  const loginUrl = `${process.env.REACT_APP_AUTH_BASE_URL}/login?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-600 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium text-white">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 ">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={() => closeAction()}
                  disabled={loading}
                  type="button"
                  className={`disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
                <a
                  href={loginUrl}
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-primary-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-primary-600 hover:bg-primary-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </a>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalBack = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-600 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium text-white">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                  <svg
                    onClick={() => hideModal()}
                    className={`${closeable ? 'block' : 'hidden'} flex-none ml-auto w-5 h-5 cursor-pointer`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="bg-white sm:flex flex-col sm:items-start h-auto max-h-96 text-lg text-red-600">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-primary-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
                <button
                  onClick={() => hideModal()}
                  disabled={loading}
                  type="button"
                  className={`${
                    closeable ? 'inline-flex' : 'hidden'
                  } disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalQuote = ({ show = false, closeable = true, loading = false, hide, title, description, confirmText, cancelText, cancelAction, confirmAction, children }) => {
  const [isOpen, setOpen] = useState(show);
  const cancelButtonRef = useRef();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const hideModal = () => {
    if (hide) {
      setOpen(false);
      hide();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        id="modal"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        static
        open={isOpen}
        onClose={() => (closeable ? hideModal() : null)}
      >
        <div className="items-end sm:block h-screen p-4 sm:p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
              <div className="flex flex-col w-full bg-primary-700 text-primary-100">
                <div className="p-4 sm:p-6 flex">
                  <div>
                    <h2 className="text-lg font-medium">{title}</h2>
                    <p className="text-sm leading-relaxed text-white">{description}</p>
                  </div>
                  <svg
                    onClick={() => cancelAction()}
                    className={`${closeable ? 'block' : 'hidden'} flex-none ml-auto w-5 h-5 cursor-pointer`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="bg-white flex flex-col items-center h-auto max-h-96 my-5">{children}</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse border-t">
                <button
                  onClick={(e) => confirmAction(e)}
                  disabled={loading}
                  type="submit"
                  className="disabled:opacity-50 ml-1.5 w-full inline-flex justify-center rounded-md border border-primary-600 shadow-sm px-4 py-2 text-base font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    confirmText
                  )}
                </button>
                <button
                  onClick={() => cancelAction()}
                  disabled={loading}
                  type="button"
                  className={`${
                    closeable ? 'inline-flex' : 'hidden'
                  } disabled:opacity-50 mr-1.5 w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    cancelText
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
