import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { chunkingOperationHours } from '../../../../utils';

const About = ({ vendorData }) => {
  const { vendorApi } = useSelector((state) => state);

  const days = [
    { name: 'Sun', value: 'sunday' },
    { name: 'Mon', value: 'monday' },
    { name: 'Tue', value: 'tuesday' },
    { name: 'Wed', value: 'wednesday' },
    { name: 'Thu', value: 'thursday' },
    { name: 'Fri', value: 'friday' },
    { name: 'Sat', value: 'saturday' },
  ];

  const chunkedOperationHours =
    vendorData?.storeOperationSchedules &&
    Object.keys(vendorData?.storeOperationSchedules).reduce((acc, key) => {
      acc[key] = chunkingOperationHours(vendorData?.storeOperationSchedules[key]);
      return acc;
    }, {});

  return (
    <>
      <dl>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-5">
          <span className="text-base font-medium text-gray-700">
            Store description
            <p className="text-sm font-normal">{vendorData?.storeDescription}</p>
          </span>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-5">
          <span className="text-base font-medium text-gray-700">
            Based in
            <p className="text-sm font-normal">
              {vendorData?.storeCity} , {vendorData?.storeState}
            </p>
          </span>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-5">
          <span className="text-base font-medium text-gray-700">
            Operation Hours
            <p className="text-sm font-normal  py-5">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Day
                    </th>
                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {days.map((day, index) => (
                    <tr key={index}>
                      <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{day.name}</td>
                      {vendorData?.storeOperationSchedules && vendorData.storeOperationSchedules[day.value].length > 0 ? (
                        <td className="px-6 py-3 whitespace-nowrap text-left text-sm">
                          {chunkedOperationHours[day.value].map((hours, index) => {
                            if (hours.length > 1)
                              return <p key={index}>{`${moment(hours[0], 'HH:mm:ss').format('hh:mm A')} - ${moment(hours[hours.length - 1], 'HH:mm:ss').format('hh:mm A')}`}</p>;
                            else return <p key={index}>{`${moment(hours[0], 'HH:mm:ss').format('hh:mm A')}`}</p>;
                          })}
                        </td>
                      ) : (
                        <td className="px-6 py-3 whitespace-nowrap text-left text-sm">Closed</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </p>
          </span>
        </div>
      </dl>
    </>
  );
};

export default About;
