import { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import _ from 'lodash';
import moment from 'moment';
import { classNames } from '../../utils';
import { Form, Input } from '../Form';

const Table = ({
  children,
  dataInput = [],
  headers = [],
  pagination = { limit: 10, pageNeighbours: 1 },
  loading = false,
  selectable = false,
  enableFilter = false,
  enableDownload = false,
  title = null,
  dateFilter = null,
}) => {
  let [originalData, setOriginalData] = useState([]);
  let [currentData, setCurrentData] = useState([]);
  let [displayData, setDisplayData] = useState([]);
  let [tableHeaders, setTableHeaders] = useState(headers);
  let [updateColumn, setUpdateColumn] = useState(false);
  let [resetTable, setResetTable] = useState(false);

  useEffect(() => {
    const offset = (1 - 1) * pagination.limit;
    setDisplayData(dataInput.slice(offset, offset + pagination.limit));
    setCurrentData(dataInput);
    setOriginalData(dataInput);
    setTableHeaders(headers);
  }, [loading, headers, dataInput]);

  const onChange = (value) => {
    const { currentPage, pageLimit } = value;
    const offset = (currentPage - 1) * pageLimit;
    setDisplayData(currentData.slice(offset, offset + pageLimit));
  };

  const handleTableColumn = (data) => {
    // To filtered table header
    setTableHeaders(data);
    // To re-render table
    setUpdateColumn(!updateColumn);
  };

  const handleTableFilter = (data) => {
    setCurrentData(data);
  };

  const handleResetTable = () => {
    const offset = (1 - 1) * pagination.limit;
    setDisplayData(originalData.slice(offset, offset + pagination.limit));
    setCurrentData(originalData);
    setOriginalData(originalData);
    headers.forEach((header) => {
      header.hide = false;
    });
    setTableHeaders(headers);
    let inputFields = document.querySelectorAll('input');
    inputFields.forEach((field) => {
      switch (field.type) {
        case 'text':
          field.value = '';
          break;
        case 'checkbox':
          field.checked = false;
          break;
        default:
          field.value = '';
          break;
      }
    });
    setResetTable(!resetTable);
  };

  const handleTableSearch = (data) => {
    setCurrentData(data);
  };

  const handleCheckAll = (event) => {
    let checkboxes = document.getElementsByName('tableItem');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });
  };

  return (
    (headers.length > 0 || dataInput.length > 0) && (
      <>
        <div className="mb-4 grid grid-cols-3">
          <div className="col-span-3 sm:col-span-1">
            <Components.SearchTable data={originalData} onSearch={handleTableSearch} />
          </div>
          <div className="col-span-3 sm:col-span-2 flex items-center mt-4 sm:mt-1 sm:justify-end text-sm">
            <Components.ManageColumns headers={tableHeaders} onManage={handleTableColumn} />
            {enableFilter && <Components.FilterTable data={originalData} headers={tableHeaders} onFiltered={handleTableFilter} reset={resetTable} />}
            {dateFilter && <Components.FilterDate dateFilter={dateFilter} />}
            {enableDownload && <Components.DownloadData data={originalData} title={title} headers={headers} />}
            <button type="button" onClick={handleResetTable} className="ml-6 flex items-center hover:text-primary-600 focus:outline-none">
              <svg className="w-4 h-4 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                ></path>
              </svg>
              Reset
            </button>
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 min-h-screen">
            <div className="overflow-visible rounded-lg border border-black border-opacity-10">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {selectable && (
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheckAll(e)}
                          className="rounded border-gray-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                        />
                      </th>
                    )}
                    {tableHeaders.map((header, index) => (
                      <th key={index} scope="col" className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${header.hide && 'hidden'}`}>
                        {loading ? <div className="animate-pulse h-4 bg-gray-400 rounded w-full" /> : header.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {displayData.map((data, dataIndex) => (
                    <tr key={dataIndex}>
                      {selectable && (
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <input
                            type="checkbox"
                            name="tableItem"
                            className="rounded border-gray-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                          />
                        </td>
                      )}
                      {tableHeaders.map((header, headerIndex) => (
                        <td key={headerIndex} className={`px-6 py-4 whitespace-nowrap text-sm ${header.hide ? 'hidden' : ''}`}>
                          {loading ? (
                            <div className="animate-pulse h-4 bg-gray-400 rounded w-full" />
                          ) : header.render ? (
                            header.render(data[header.objPropKey], displayData[dataIndex])
                          ) : (
                            <>{data[header.objPropKey]}</>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {dataInput.length === 0 && (
                <div className="border-t">
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    children
                  )}
                </div>
              )}
              {pagination && (
                <Components.Pagination
                  data={currentData}
                  totalRecords={currentData.length}
                  pageLimit={pagination.limit}
                  pageNeighbours={pagination.pageNeighbour}
                  onPageChanged={onChange}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

const Components = {
  Pagination: ({ totalRecords = 0, pageLimit = 10, pageNeighbours = 1, loading = false, data = [], onPageChanged }) => {
    const LEFT_PAGE = 'LEFT';
    const RIGHT_PAGE = 'RIGHT';

    /**
     * Helper method for creating a range of numbers
     * range(1, 5) => [1, 2, 3, 4, 5]
     */
    const range = (from, to, step = 1) => {
      let i = from;
      const range = [];

      while (i <= to) {
        range.push(i);
        i += step;
      }

      return range;
    };

    const [currentPage, setCurrentPage] = useState(1);
    let [dataRange, setDataRange] = useState([totalRecords ? 1 : 0, pageLimit > totalRecords ? totalRecords : pageLimit]);

    useEffect(() => {
      const paginationData = {
        currentPage: 1,
        totalPages: totalPages,
        pageLimit: pageLimit,
        totalRecords: totalRecords,
      };

      onPageChanged(paginationData);
      setCurrentPage(1);
      setDataRange([totalRecords ? 1 : 0, pageLimit > totalRecords ? totalRecords : pageLimit]);
    }, [totalRecords]);

    /**
     * pageNeighbours: 0 -----> 1 < 5 > 14
     * pageNeighbours: 1 -----> 1 < 4 5 6 > 14
     * pageNeighbours: 2 -----> 1 < 3 4 5 6 7> 14
     */
    pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;
    pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
    totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

    let totalPages = Math.ceil(totalRecords / pageLimit);

    const goToPage = (page) => {
      const thisPage = Math.max(0, Math.min(page, totalPages));

      const paginationData = {
        currentPage: thisPage,
        totalPages: totalPages,
        pageLimit: pageLimit,
        totalRecords: totalRecords,
      };

      const offset = (thisPage - 1) * pageLimit;
      let currentData = data.slice(offset, offset + pageLimit);
      // console.log(thisPage, totalPages);
      if (thisPage === totalPages) {
        dataRange[1] = totalRecords;
        dataRange[0] = dataRange[1] - (currentData.length - 1);
      } else {
        dataRange[1] = thisPage * currentData.length;
        dataRange[0] = dataRange[1] - (currentData.length - 1);
      }

      onPageChanged(paginationData);
      setCurrentPage(thisPage);
      setDataRange(dataRange);
    };

    const handlePageClick = (page, evt) => {
      evt.preventDefault();
      goToPage(page);
    };

    const handleLeftJump = (evt) => {
      evt.preventDefault();
      goToPage(currentPage - pageNeighbours * 2 - 1);
    };

    const handleRightJump = (evt) => {
      evt.preventDefault();
      goToPage(currentPage + pageNeighbours * 2 + 1);
    };

    const fetchPageNumbers = () => {
      const totalNumbers = pageNeighbours * 2 + 3;
      const totalBlocks = totalNumbers + 2;

      if (totalPages > totalBlocks) {
        let pages = [];

        const leftBound = currentPage - pageNeighbours;
        const rightBound = currentPage + pageNeighbours;
        const beforeLastPage = totalPages - 1;

        const startPage = leftBound > 2 ? leftBound : 2;
        const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

        pages = range(startPage, endPage);

        const pagesCount = pages.length;
        const singleSpillOffset = totalNumbers - pagesCount - 1;

        const leftSpill = startPage > 2;
        const rightSpill = endPage < beforeLastPage;

        const leftSpillPage = LEFT_PAGE;
        const rightSpillPage = RIGHT_PAGE;

        if (leftSpill && !rightSpill) {
          const extraPages = range(startPage - singleSpillOffset, startPage - 1);
          pages = [leftSpillPage, ...extraPages, ...pages];
        } else if (!leftSpill && rightSpill) {
          const extraPages = range(endPage + 1, endPage + singleSpillOffset);
          pages = [...pages, ...extraPages, rightSpillPage];
        } else if (leftSpill && rightSpill) {
          pages = [leftSpillPage, ...pages, rightSpillPage];
        }

        return [1, ...pages, totalPages];
      }

      return range(1, totalPages);
    };

    const pages = fetchPageNumbers();

    return (
      <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg">
        <div className="flex-1 flex justify-between sm:hidden">
          {currentPage > 1 && (
            <button
              type="button"
              onClick={(e) => handlePageClick(currentPage - 1, e)}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
          )}
          {currentPage !== pages[pages.length - 1] && (
            <button
              type="button"
              onClick={(e) => handlePageClick(currentPage + 1, e)}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          )}
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm leading-5 text-gray-700">
              Showing
              <span className="font-semibold mx-1">{dataRange[0]}</span>
              to
              <span className="font-semibold mx-1">{dataRange[1]}</span>
              of
              <span className="font-semibold mx-1">{totalRecords}</span>
              results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              {pages.map((page, index) => {
                if (page === LEFT_PAGE) {
                  return (
                    <div
                      key={index}
                      onClick={(e) => handleLeftJump(e)}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Previous</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  );
                }
                if (page === RIGHT_PAGE) {
                  return (
                    <div
                      key={index}
                      onClick={(e) => handleRightJump(e)}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Next</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    onClick={(e) => handlePageClick(page, e)}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer ${
                      currentPage === page ? 'z-10 bg-primary-100 border-primary-500 text-primary-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                    } ${page === 1 && 'rounded-l-md'} ${page === totalPages && 'rounded-r-md'}`}
                  >
                    {page}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    );
  },
  SearchTable: ({ data = [], onSearch }) => {
    const handleSearch = (event) => {
      event.preventDefault();
      let input = event.target.value;

      let result = _.filter(data, (d) => {
        let values = Object.values(d);
        let booleanArray = values.map((value) => {
          if (value) {
            if (typeof value === 'object') {
              return JSON.stringify(value).toString().toLowerCase().includes(input.toLowerCase());
            } else {
              return value.toString().toLowerCase().includes(input.toLowerCase());
            }
          }
        });

        return booleanArray.includes(true);
      });

      onSearch(result);
    };

    return (
      <>
        <label htmlFor="search-table" className="sr-only">
          Search table
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">
              <svg className="w-5 h-5 sm:w-4 sm:h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </span>
          </div>
          <input
            onInput={(e) => handleSearch(e)}
            type="text"
            name="search-table"
            className="focus:ring-primary-600 focus:border-primary-600 block w-full pl-10 sm:pl-9 pr-16 sm:text-sm border-gray-300 rounded-md"
            placeholder="Search table"
          />
        </div>
      </>
    );
  },
  ManageColumns: ({ headers, onManage }) => {
    const [expand, setExpand] = useState(false);
    const node = useRef();

    let headerNames = headers
      .map((header) => {
        return header.title;
      })
      .filter(Boolean);

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleDropdownClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleDropdownClick);
      };
    }, []);

    const handleDropdownClick = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpand(false);
    };

    const handleCheckbox = (index) => {
      headers[index].hide = !headers[index].hide;
      onManage(headers);
    };

    return (
      <div className="relative inline-block text-left" ref={node}>
        <div>
          <button type="button" onClick={() => setExpand(!expand)} className="z-0 flex items-center hover:text-primary-600 focus:outline-none">
            <svg className="w-4 h-4 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
              ></path>
            </svg>
            Columns
          </button>
        </div>
        <Transition
          show={expand}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className="z-10 sm:origin-top-right sm:right-0 absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div className="text-sm px-4 py-2 border-b font-medium">
              <span>Manage columns</span>
            </div>
            <div className="px-4 py-2 my-2">
              <ul className="space-y-3">
                {headerNames.map((name, index) => (
                  <li key={index} className="flex items-center cursor-pointer">
                    <input
                      id={index}
                      name={index}
                      defaultChecked={!headers[index].hide}
                      onChange={() => handleCheckbox(index)}
                      type="checkbox"
                      className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded cursor-pointer"
                    />
                    <label htmlFor={index} className="ml-2 block text-sm text-gray-900 cursor-pointer">
                      {name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Transition>
      </div>
    );
  },
  FilterTable: ({ data, headers, onFiltered, reset }) => {
    const [expand, setExpand] = useState(false);
    const [collapseTitle, setCollapseTitle] = useState(false);
    const [filters, setFilters] = useState({});
    const [headerFilters, setHeaderFilters] = useState([]);
    const node = useRef();

    let newHeaderFilters = headers
      .map((header) => {
        if (header.hasOwnProperty('filters')) {
          let newFilter = header.filters.map((filter) => {
            return { title: filter, hide: false };
          });
          return { ...header, filters: newFilter };
        }
      })
      .filter(Boolean);

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleDropdownClick);
      setHeaderFilters(newHeaderFilters);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleDropdownClick);
      };
    }, [reset]);

    const handleDropdownClick = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpand(false);
    };

    const handleFilterSelect = (headerIndex, filterIndex) => {
      let header = headerFilters[headerIndex];
      let filteredKey = header.objPropKey;
      header.filters[filterIndex].hide = !header.filters[filterIndex].hide;

      let filteredValues = header.filters.reduce((a, o) => (o.hide && a.push(o.title), a), []);

      filters[filteredKey] = filteredValues;
      setFilters(filters);
      onFiltered(multiFilter(data, filters));
    };

    /**
     * Multiple array filter
     * @link https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
     */
    const multiFilter = (arr = [], filters = {}) => {
      const filterKeys = Object.keys(filters);
      return arr.filter((eachObj) => {
        return filterKeys.every((eachKey) => {
          if (!filters[eachKey].length) {
            return true; // passing an empty filter means that filter is ignored.
          }
          return filters[eachKey].includes(eachObj[eachKey]);
        });
      });
    };

    return (
      <div className="ml-6 relative inline-block text-left" ref={node}>
        <div>
          <button type="button" onClick={() => setExpand(!expand)} className="flex items-center hover:text-primary-600 focus:outline-none">
            <svg className="w-4 h-4 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
              ></path>
            </svg>
            Filters
          </button>
        </div>
        <Transition
          show={expand}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className="sm:origin-top-right sm:right-0 absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div className="text-sm px-4 py-2 border-b font-medium">
              <span>Filters</span>
            </div>
            <div className={classNames(headerFilters.length > 0 ? 'px-4 py-2 my-2' : 'hidden')}>
              {headerFilters.map((header, headerIndex) => (
                <>
                  <button
                    type="button"
                    onClick={() => setCollapseTitle(collapseTitle === header.title ? null : header.title)}
                    className={`w-full flex items-center justify-between bg-primary-100 text-primary-600 rounded-md px-2 py-1 focus:outline-none ${
                      headerIndex !== headerFilters.length - 1 ? 'mb-2' : 'mb-0'
                    } `}
                  >
                    <h3 className="font-medium text-sm">{header.title}</h3>
                    {header.title !== collapseTitle ? (
                      <svg className="w-4 h-4 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    ) : (
                      <svg className="w-4 h-4 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </button>
                  <ul key={headerIndex} className={`mt-3 space-y-3 ${headerIndex !== headerFilters.length - 1 ? 'mb-3' : 'mb-0'} ${header.title !== collapseTitle && 'hidden'}`}>
                    {header.filters.map((filter, filterIndex) => (
                      <li key={`${filterIndex}_${filter.title}`} className="flex items-center cursor-pointer">
                        <input
                          id={`${filterIndex}_${filter.title}`}
                          name="filterItem"
                          defaultChecked={filter.hide}
                          onChange={() => handleFilterSelect(headerIndex, filterIndex)}
                          type="checkbox"
                          className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded cursor-pointer"
                        />
                        <label htmlFor={`${filterIndex}_${filter.title}`} className="ml-2 block text-sm text-gray-900 cursor-pointer">
                          {filter.title.toString()}
                        </label>
                      </li>
                    ))}
                  </ul>
                </>
              ))}
            </div>
          </div>
        </Transition>
      </div>
    );
  },
  DownloadData: ({ data, headers, title, parameter }) => {
    return (
      <div className="ml-6 relative inline-block text-left">
        <div>
          <button type="button" onClick={() => console.log('download')} className="flex items-center hover:text-primary-600  focus:outline-none">
            <svg className="w-4 h-4 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              ></path>
            </svg>
            Download
          </button>
        </div>
      </div>
    );
  },
  FilterDate: ({ dateFilter }) => {
    const [expand, setExpand] = useState(false);
    const node = useRef();

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleDropdownClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleDropdownClick);
      };
    }, []);

    const handleDropdownClick = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpand(false);
    };

    const handleDateFilter = (data) => {
      dateFilter(data);
      setExpand(false);
    };

    return (
      <div className="ml-6 relative inline-block text-left" ref={node}>
        <div>
          <button type="button" onClick={() => setExpand(!expand)} className="flex items-center hover:text-primary-600 focus:outline-none">
            <svg className="w-4 h-4 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
            Date filter
          </button>
        </div>
        <Transition
          show={expand}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className="sm:origin-top-right sm:right-0 absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div className="text-sm px-4 py-2 border-b font-medium">
              <span>Date filter</span>
            </div>
            <div className="px-4 py-2 my-2">
              <Form className="space-y-4" onSubmit={handleDateFilter}>
                <Input.DatePicker name="startDate" label="Start date" />
                <Input.DatePicker name="endDate" label="End date" />
                <button
                  type="submit"
                  className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Filter
                </button>
              </Form>
            </div>
          </div>
        </Transition>
      </div>
    );
  },
};

export default Table;
