import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Form, Input } from '../../../components/Form';
import { showToast } from '../../../store/slices/components.slice';
import { updateVendor } from '../../../store/slices/vendor.slice';
import { getAddressGeocode } from '../../../utils';
import Tooltip from '../../../components/Tooltip';

const AddressForm = ({ addresses, loading, address, action }) => {
  const formNode = useRef();
  const mapNode = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { vendorApi } = useSelector((state) => state);

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  let [tempAddress, setTempAddress] = useState({});
  let [listAddresses, setListAddresses] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [tooltipShow, setTooltipShow] = useState(true);
  const [tooltipLocation, setTooltipLocation] = useState(false);

  const [addressName, setAddressName] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [postalCode, setPostalCode] = useState(null);

  useEffect(() => {
    setListAddresses(addresses);
    setTempAddress(address);

    setAddressName(formNode?.current?.getFormData()?.name);
    setAddress1(formNode?.current?.getFormData()?.address1);
    setCity(formNode?.current?.getFormData()?.city);
    setState(formNode?.current?.getFormData()?.state);
    setPostalCode(formNode?.current?.getFormData()?.postalCode);

    if (formNode?.current?.getFormData()?.location?.lat && formNode?.current?.getFormData()?.location?.lon) {
      setTooltipShow(false);
    }
  }, [loading, addresses, address]);

  const handleSubmit = (data) => {
    let firstAddress = false;

    delete data.locationHiddenInput;

    // Create deep copy of array listAddresses
    listAddresses = listAddresses?.map((address) => Object.assign({}, address)) || [];

    if (listAddresses.length > 0) {
      if (data.isDefault) {
        listAddresses = listAddresses.map((address) => {
          return { ...address, isDefault: false };
        });
      }
    } else {
      firstAddress = true;
      data.isDefault = true;
    }

    if (action === 'edit' && id !== undefined) {
      listAddresses[parseInt(id)] = data;
    } else {
      listAddresses.push(data);
    }

    dispatch(updateVendor({ addresses: listAddresses, action: 'SAVE' }))
      .unwrap()
      .then(({ message }) => {
        dispatch(showToast({ show: true, type: 'success', message: message }));
        setTempAddress({});
        setListAddresses([]);
        if (firstAddress) {
          history.go(0);
        } else {
          history.push('/store/addresses');
        }
      })
      .catch(({ message, validate }) => {
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details);
        setTimeout(() => {
          const element = document.getElementById('rule-error');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      });
  };

  const updateFormData = (data) => {
    setTempAddress({ ...formNode.current.getFormData(), ...data });
    setTriggerUpdate(!triggerUpdate);
  };

  const handleAddress = async () => {
    let currentFormData = formNode.current.getFormData();
    let addressObj = {
      address1: currentFormData['address1'],
      address2: currentFormData['address2'] || '',
      city: currentFormData['city'],
    };

    if (mapNode) {
      const geocoder = new mapNode.current.map.maps_.Geocoder();
      let { state, postalCode, coordinate } = await getAddressGeocode(geocoder, addressObj);
      updateFormData({
        // state: state || '',
        // postalCode: postalCode || '',
        location: coordinate || {},
      });

      if (!coordinate) {
        setTooltipLocation(true);
      } else {
        setTooltipLocation(false);
      }

      setShowMap(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit} defaultValues={tempAddress} setRef={formNode} setErrors={formErrors} triggerUpdateData={triggerUpdate}>
      <div className="p-6">
        <Input.GoogleMap setRef={mapNode} className="sr-only" name="locationHiddenInput" fieldType="address" />
        <div className="mt-2 sm:mt-0 sm:col-span-2 grid grid-cols-1 sm:grid-cols-6 gap-6">
          {/* <div className="col-span-1 sm:col-span-2">
            {tempAddress?.isDefault ? (
              <Input.Switch
                name="isDefault"
                label="Default address"
                disabled
                tooltipComponent={<p>Cannot deselect this default address, you can only choose another address as a default address</p>}
              />
            ) : (
              <Input.Switch name="isDefault" label="Default address" />
            )}
          </div> */}
          <div className="col-span-1 sm:col-span-2">
            <Input.Switch name="disable" label="Disable" />
          </div>
          <div className="col-span-1 sm:col-span-5">
            <Input.Text
              name="name"
              label="Branch Name"
              placeholder="KL Branch, Setia Alam Branch, etc"
              retrieveValue={(data) => {
                setAddressName(data.target.value);
              }}
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
          <div className="col-span-1 sm:col-span-3">
            <Input.Text
              name="address1"
              label="Address Line 1"
              placeholder="Address Line 1"
              value={address1}
              retrieveValue={(data) => {
                setAddress1(data.target.value);
              }}
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
          <div className="col-span-1 sm:col-span-3">
            <Input.Text name="address2" label="Address Line 2" placeholder="Address Line 2" disabled={vendorApi.loading} />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Input.Text
              name="city"
              label="City"
              placeholder="City"
              value={city}
              retrieveValue={(data) => {
                setCity(data.target.value);
              }}
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Input.Select
              name="state"
              label="State"
              placeholder="Select a state"
              options={[
                'Perlis',
                'Pulau Pinang',
                'Kedah',
                'Perak',
                'Selangor',
                'Kuala Lumpur',
                'Negeri Sembilan',
                'Melaka',
                'Johor',
                'Kelantan',
                'Terengganu',
                'Pahang',
                'Putrajaya',
                'Labuan',
                'Sabah',
                'Sarawak',
              ]}
              value={state}
              retrieveValue={(data) => {
                setState(data.target.value);
              }}
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Input.Text
              name="postalCode"
              label="Postal code"
              placeholder="Postal code"
              value={postalCode}
              retrieveValue={(data) => {
                setPostalCode(data.target.value);
              }}
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
          {addressName && address1 && city && state && postalCode ? (
            <div className="col-span-1 sm:col-span-5">
              <button
                type="button"
                onClick={() => {
                  handleAddress();
                  setTooltipShow(false);
                }}
                className="mb-4 font-medium text-primary-500 hover:text-primary-600 border-primary-600 border-b border-dotted"
              >
                Get address coordinate
              </button>
              {tooltipShow && (
                <Tooltip from="bottom" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                  Click here to get address coordinate
                </Tooltip>
              )}
              {tooltipLocation && (
                <Tooltip from="bottom" bgColor="bg-red-600/95 text-white" chevronColor="text-amber-500">
                  Cannot find location from your address. Please check your address.
                </Tooltip>
              )}
              <Input.GoogleMap
                name="location"
                label="Coordinate"
                hideCurrentLocationButton
                showMap={showMap}
                tooltipLocation={tooltipLocation}
                disabled={vendorApi.loading}
                rules={[{ required: true, message: 'This field is required' }]}
              />
            </div>
          ) : (
            <div className="col-span-1 sm:col-span-5">
              <button disabled type="button" onClick={handleAddress} className="mb-4 font-medium text-gray-300 hover:text-gray-300 border-primary-600 border-b border-dotted">
                Get address coordinate
              </button>
              {formNode?.current?.getFormData()?.location?.lat && formNode?.current?.getFormData()?.location?.lon && (
                <Input.GoogleMap
                  name="location"
                  label="Coordinate"
                  hideCurrentLocationButton
                  showMap={showMap}
                  disabled
                  rules={[{ required: true, message: 'This field is required' }]}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 px-4 py-3 bg-gray-50 text-right sm:px-6 border-t">
        <button
          type="submit"
          disabled={vendorApi.loading}
          onClick={() => {
            setTimeout(() => {
              const element = document.getElementById('rule-error');
              element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
          }}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {vendorApi.loading ? (
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : action === 'edit' ? (
            'Update'
          ) : (
            'Add'
          )}
        </button>
      </div>
    </Form>
  );
};

export default AddressForm;
