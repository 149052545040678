import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '../../components/Routes';
import { classNames } from '../../utils';

import Layout from '../../components/Layout';

import Home from './Home';
import Profile from './Profile';
import Register from './Register';
import Products from './Products';
import Orders from './Orders';
import ScheduleClose from './ScheduleClose';
import Addresses from './Addresses';
import Address from './Addresses/Address';

import { getVendor, saveProfilePicture } from '../../store/slices/vendor.slice';
import { showToast } from '../../store/slices/components.slice';
import LoadingScreen from '../../components/LoadingScreen';
import StoreQuote from './Quote';

const VendorStore = ({ vendorData }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [vendorProfile, setVendorProfile] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStoreProfile();
  }, [vendorData]);

  const setStoreProfile = () => {
    if (vendorData?.data) {
      const { status } = vendorData.data;
      if (['REGISTER', 'PENDING'].includes(status)) {
        history.push({
          pathname: '/store/register',
          state: vendorData.data,
        });
      } else {
        setVendorProfile(vendorData.data);
        dispatch(saveProfilePicture(vendorData.data.storeProfilePic));
      }
    }
  };

  return (
    <div className="bg-white text-gray-700 font-sans antialiased">
      <div className="min-h-screen flex">
        <LoadingScreen show={loading} />
        <Layout.Sidebar path={location.pathname} vendorData={vendorData} />
        <div className="min-h-screen flex-1 min-w-0 flex flex-col">
          <Layout.Header />
          <Layout.Content>
            <Switch>
              <ProtectedRoute exact path={path} component={Home} />
              <ProtectedRoute exact path={`${path}/profile`} component={Profile} vendorData={vendorData} />
              <ProtectedRoute exact path={`${path}/products`} component={Products} />
              <ProtectedRoute exact path={`${path}/products/create`} component={Products.Create} />
              <ProtectedRoute exact path={`${path}/products/:id`} component={Products.Update} />
              <ProtectedRoute exact path={`${path}/orders`} component={Orders} />
              <ProtectedRoute exact path={`${path}/schedule_close/:groupDate`} component={ScheduleClose} />
              <ProtectedRoute exact path={`${path}/orders/:id`} component={Orders.Info} />
              <ProtectedRoute exact path={`${path}/quote`} component={StoreQuote} />
              <ProtectedRoute exact path={`${path}/quote/create`} component={StoreQuote.Create} />
              <ProtectedRoute exact path={`${path}/quote/:id`} component={StoreQuote.Detail} />
              {vendorData?.data?.multiLocation === true && (
                <>
                  <ProtectedRoute exact path={`${path}/addresses`} component={Addresses} vendorData={vendorData} />
                  <ProtectedRoute exact path={`${path}/addresses/new`} component={Address} vendorData={vendorData} />
                  <ProtectedRoute exact path={`${path}/addresses/edit/:id`} component={Address} vendorData={vendorData} />
                </>
              )}
              <ProtectedRoute component={NotFound} />
            </Switch>
          </Layout.Content>
        </div>
      </div>
    </div>
  );
};

VendorStore.Register = Register;

const NotFound = () => {
  return (
    <section id="protected-page" className="-m-6 sm:-m-8 flex items-center justify-center relative min-h-full">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-7xl">
        <p className="text-base font-semibold tracking-wider text-primary-500 uppercase">404 Error</p>
        <h2 className="mt-2 text-3xl sm:text-5xl font-extrabold text-primary-600 tracking-tight">Not Found.</h2>
        <p className="my-5 text-base text-gray-500 sm:leading-10 sm:text-xl lg:text-lg xl:text-xl">The page that you look for is not found</p>
      </div>
    </section>
  );
};

export default VendorStore;
