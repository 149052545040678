import axios from 'axios';
import qs from 'querystring';
import { useHistory } from 'react-router';

import authService from './auth.service';
import userService from './user.service';
import TokenService from './token.service';

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getToken();
    if (config.url === '/auth/login') {
      const { email, password } = config.data;
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      config.headers['Authorization'] = `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`; // for Login api only
      config.headers['Content-Type'] = 'application/json';
    } else if (config.url === '/oauth2/token') {
      config.baseURL = process.env.REACT_APP_AUTH_BASE_URL;
      if (token?.impersonate_email) {
        config.baseURL = process.env.REACT_APP_VAULT_BASE_URL;
      }
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (/^\/customer\/public/.test(config.url) || /^\/admin\/service\/retrieve-impersonate-session/.test(config.url) || /^\/customer\/quote/.test(config.url)) {
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      config.headers['Content-Type'] = 'application/json';
      const guest = JSON.parse(localStorage.getItem('guest'));
      if (guest?.email) {
        config.headers['email-guest'] = guest.email;
      }
    } else {
      if (token) {
        if (config.headers['content-type'] !== 'application/octet-stream') {
          config.baseURL = process.env.REACT_APP_API_BASE_URL;
          config.headers['Authorization'] = 'Bearer ' + token?.access_token; // for any other Bearer required api
          config.headers['Content-Type'] = 'application/json';
        } else {
          // File upload API requires 'application/octet-stream' Content-Type header and no Authorization header
          delete config.headers['Authorization'];
        }
        if (token?.impersonate_email) {
          config.headers['impersonate-email'] = token.impersonate_email;
        }
      } else {
        const guest = JSON.parse(localStorage.getItem('guest'));
        if (guest?.email) {
          config.headers['email-guest'] = guest.email;
          config.baseURL = process.env.REACT_APP_API_BASE_URL;
          config.headers['Content-Type'] = 'application/json';
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const token = TokenService.getToken();
          // if(token?.impersonate_email){
          //   alert("Your impersonate session is expired")
          //   setTimeout(() => {
          //     window.location.reload(true);
          //   }, 100);
          //   authService.logout(useHistory);
          //   return
          // }
          let refreshToken = token.refresh_token; //TokenService.getLocalRefreshToken();
          let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
          if (token?.impersonate_email) {
            // alert('Your impersonate session is expired');
            clientId = process.env.REACT_APP_VAULT_CLIENT_ID;
          }
          let urlencodedData = qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: clientId,
          });

          const resp = await instance.post('/oauth2/token', urlencodedData);
          // notification fcm call here
          const { data } = resp;
          TokenService.updateLocalAccessToken(data.access_token);
          return instance(originalConfig);
        } catch (_error) {
          const { data } = _error.response;
          if (data.error === 'invalid_grant') {
            setTimeout(() => {
              window.location.reload(true);
            }, 100);
            authService.logout(useHistory);
          }
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default instance;
