import { isArrayNotEmpty } from '../../helper';
import StoreCard from '../StoreCard/StoreCard';
import React from 'react';

const StoreListing = ({ productList }) => {
  return (
    <div className="grid-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-6 lg:gap-8">
      {productList.reduce((result, store, index) => {
        if (isArrayNotEmpty(store.products)) {
          result.push(<StoreCard key={index} store={store} />);
        }
        return result;
      }, [])}
    </div>
  );
};

export default StoreListing;
