import { useEffect } from 'react';

let times = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'];
export const PresetTimeDummy = ({ selfPickup = false }) => {
  useEffect(() => {}, [selfPickup]);
  return (
    <div>
      <label className={`flex items-center text-sm font-medium text-gray-700 ${!true && 'sr-only'}`}>
        {true && <span className="text-red-500 mr-1">*</span>}
        {selfPickup ? 'Pickup time' : 'Delivery time'}
      </label>
      <div className="mt-1 grid grid-cols-4 gap-4">
        {times.map((time, index) => (
          <label
            key={index}
            id={index}
            className={`group relative border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:flex-1 bg-white shadow-sm text-gray-300 cursor-pointer`}
          >
            <p>{time}</p>
          </label>
        ))}
      </div>
    </div>
  );
};
