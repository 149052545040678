import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { MapIcon } from '@iconicicons/react';
import _ from 'lodash';

import { getVendorProfile, listVendorProducts } from '../../../store/slices/customer.slice';
import { showToast } from '../../../store/slices/components.slice';
import LoadingScreen from '../../../components/LoadingScreen';
import Product from './Products';
import About from './About';
import { goToTop } from '../../../utils';
import ShareButton from '../../../components/ShareButton';

const VendorProfile = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [vendorData, setVendorData] = useState({});
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const { userApi } = useSelector((state) => state);
  const [deliveryLocation, setDeliveryLocation] = useState(false);
  const changeTooltipAddressValue = props.changeTooltipAddressValue;

  useEffect(() => {
    switch (new URLSearchParams(location.search).get('view')) {
      case 'about':
        setCurrentTab(1);
        break;
      default:
        setCurrentTab(0);
    }
    if (!_.isEqual(deliveryLocation, userApi.deliverToAddress?.location)) {
      handleGetVendorProfile();
    }
    if (userApi?.payload !== null && userApi?.addresses?.length == 0) {
      changeTooltipAddressValue(true);
    }
    setDeliveryLocation(userApi.deliverToAddress?.location);
  }, [userApi.deliverToAddress]);

  const handleGetVendorProfile = () => {
    if (userApi?.deliverToAddress?.location || userApi?.currentCoordinate) {
      let vendorProfileParams = {
        id: id,
      };

      if (userApi?.deliverToAddress?.location) {
        vendorProfileParams['location'] = JSON.stringify(userApi?.deliverToAddress?.location);
      } else {
        vendorProfileParams['location'] = JSON.stringify(userApi?.currentCoordinate);
      }

      dispatch(getVendorProfile(vendorProfileParams))
        .unwrap()
        .then((result) => {
          const { data } = result;
          setVendorData(data);
          handleGetVendorProductList(data.uid);
          if (data?.username) {
            const pathnameArray = location.pathname.split('/');
            pathnameArray.pop();
            const newUrl = `${pathnameArray.join('/')}/${data?.username}`;
            window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
          }
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    } else {
      dispatch(getVendorProfile({ id: id }))
        .unwrap()
        .then((result) => {
          const { data } = result;
          setVendorData(data);
          handleGetVendorProductList(data.uid);
          if (data?.username) {
            const pathnameArray = location.pathname.split('/');
            pathnameArray.pop();
            const newUrl = `${pathnameArray.join('/')}/${data?.username}`;
            window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
          }
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    }

    setLoading(false);
  };

  const handleGetVendorProductList = (vendorUid) => {
    dispatch(listVendorProducts(vendorUid))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setProductList(data.products);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  async function handleActionClickAddress() {
    goToTop();
    changeTooltipAddressValue(false);
    await setTimeout(() => changeTooltipAddressValue(true), 1000);
    await setTimeout(() => changeTooltipAddressValue(false), 1500);
    await setTimeout(() => changeTooltipAddressValue(true), 1800);
  }

  const tabs = [
    {
      id: 0,
      key: 'products',
      title: 'Products',
      action: () => history.push(`${location.pathname}?view=products`),
      panel: <Product id={id} vendorData={vendorData} productList={productList} addressAction={handleActionClickAddress} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      id: 1,
      key: 'about',
      title: 'About',
      action: () => history.push(`${location.pathname}?view=about`),
      panel: <About id={id} vendorData={vendorData} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
  ];

  return (
    <>
      <LoadingScreen show={loading} />
      <div className="max-w-7xl mx-auto flex-start justify-center bg-white border-l border-r">
        <div className="w-full">
          <div className="border-b">
            <img src={vendorData?.storeBannerPic?.path?.s} className="w-full h-[100px] sm:hidden object-cover object-center" alt="banner" />
            <img src={vendorData?.storeBannerPic?.path?.m} className="hidden md:hidden sm:block sm:w-full sm:h-[150px] object-cover object-center" alt="banner" />
            <img src={vendorData?.storeBannerPic?.path?.m} className="hidden sm:hidden md:block md:w-full md:h-[200px] object-cover object-center" alt="banner" />
          </div>
          <div className="flex justify-start ">
            <div className="justify-between px-6 md:px-3">
              <div className="rounded-full border-4 border-gray-300 inline-block -mt-12 md:-mt-16 w-24 md:w-40">
                <img className="block w-24 md:hidden rounded-full ring-2 ring-white" src={vendorData?.storeProfilePic?.path?.s} alt="" />
                <img className="hidden md:block md:w-40 rounded-full ring-2 ring-white" src={vendorData?.storeProfilePic?.path?.m} alt="" />
              </div>
              {/* <div>
              <button className="rounded-full px-3 py-2 mt-3 transition hover:bg-blue-50 inline-block border-2 border-blue-400 text-blue-400 font-bold">Edit Profile</button>
            </div> */}
            </div>
            <div class=" md:py-4 flex items-center">
              <div class="px-1">
                <MapIcon className="h-9 w-9" />
              </div>
              <div>
                <div class=" px-2">
                  <span className="text-base font-medium text-black">
                    Coverage Area Delivery
                    <p className="text-sm font-normal">
                      <span className="text-base font-medium text-black">{vendorData?.availabilityRadiusKm} Km</span>
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-4 px-6 md:px-8 ">
            <p className="font-bold text-2xl">{vendorData?.storeName}</p> <ShareButton vendorPath={vendorData.username || vendorData.uid} storeName={vendorData?.storeName} />
            {vendorData?.username && <p className=" text-gray-500 font-bold text-l sm:px-3">@{vendorData?.username}</p>}
            <div className="space-x-2 sm:px-2">
              <span
                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md  
                  ${vendorData?.status === 'REGISTER' ? 'bg-blue-100 text-blue-600' : ''}
                  ${vendorData?.status === 'PENDING' ? 'bg-primary-100 text-primary-600' : ''}
                  ${vendorData?.status === 'ONLINE' ? 'bg-green-100 text-green-600' : ''}
                  ${vendorData?.status === 'SUSPEND' ? 'bg-red-100 text-red-600' : ''}
                `}
              >
                {vendorData?.status}
              </span>
              {vendorData?.distance && vendorData?.distance !== (null || undefined) ? (
                vendorData?.distance > vendorData?.availabilityRadiusKm ? (
                  <>
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">
                      {vendorData?.distance?.toFixed(2)} KM
                    </span>
                  </>
                ) : (
                  <>
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">
                      {vendorData?.distance?.toFixed(2)} KM
                    </span>
                  </>
                )
              ) : null}
              {vendorData?.distance && vendorData?.distance > vendorData?.availabilityRadiusKm ? (
                <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Out Of Range</span>
              ) : (
                <>
                  {vendorData?.deliveryEnable ? (
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Delivery</span>
                  ) : (
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Delivery Unavailable</span>
                  )}
                </>
              )}
              {vendorData?.selfPickupEnable ? (
                <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Self Pickup</span>
              ) : (
                <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Self Pickup Unavailable</span>
              )}
            </div>
            <div className="py-12 ">
              <Tab.Group defaultIndex={currentTab}>
                <Tab.List className="flex space-x-4 border-b pb-4 px-4 sm:px-2">
                  {tabs.map((tab, index) => (
                    <Tab key={index} as={Fragment}>
                      {({ selected }) => (
                        <button
                          type="button"
                          onClick={tab.action}
                          className={
                            selected
                              ? 'flex items-center bg-primary-100 text-primary-600 focus:outline-none px-3 py-2 rounded-md text-sm font-medium'
                              : 'flex items-center text-gray-500 hover:bg-primary-100 hover:text-primary-600 focus:outline-none px-3 py-2 rounded-md text-sm font-medium'
                          }
                        >
                          {tab.title}
                        </button>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels>
                  {tabs.map((tab, index) => (
                    <Tab.Panel className="focus:outline-none">{tab.panel}</Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorProfile;
