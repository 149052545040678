import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fileService from '../../services/file.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const getUploadRequest = createAsyncThunk('file/get_upload_request', async (data, { rejectWithValue }) => {
  try {
    return await fileService.getUploadRequest(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getPrivateFile = createAsyncThunk('file/get_private_file', async (data, { rejectWithValue }) => {
  try {
    return await fileService.getPrivateFile(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const uploadFile = createAsyncThunk('file/upload_file', async (data, { rejectWithValue }) => {
  try {
    return await fileService.uploadFile(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'file',
  initialState,
  reducers: {},
  extraReducers: {
    /** Get upload request reducer **/
    [getUploadRequest.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getUploadRequest.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getUploadRequest.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get private file reducer **/
    [getPrivateFile.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getPrivateFile.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getPrivateFile.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Upload file reducer **/
    [uploadFile.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [uploadFile.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [uploadFile.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
