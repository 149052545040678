import { Link } from 'react-router-dom';
import Image from '../../../../components/Image';
import ReadMore from '../../../../components/ReadMore';
import { useSelector } from 'react-redux';
import { ModalLogin } from '../../../../components/Modal';
import { useState } from 'react';

import MerdekaPromoFrame from '../../../../assets/images/merdeka-promo-frame.png';

const Product = ({ vendorData, productList, addressAction }) => {
  const { userApi } = useSelector((state) => state);
  const [modalOpenNeedLogin, setModalOpenNeedLogin] = useState(false);

  return (
    <>
      <dl className="px-4 py-5 sm:px-2">
        <div className="col-span-6">
          {vendorData?.storeDiscountQuantityEnable && (
            <div class="block p-6 bg-primary-100 border border-primary-300 rounded-lg shadow">
              {vendorData?.storeDiscountQuantityTier?.map((item, index) => (
                <>
                  {index + 1 === vendorData.storeDiscountQuantityTier.length ? (
                    <p className="font-bold">
                      * Minimum quantity {item.begin} get discount {item.discount}%{' '}
                    </p>
                  ) : (
                    <p className="font-bold">
                      * Minimum quantity {item.begin} to up {item.end} get discount {item.discount}%{' '}
                    </p>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {productList.length > 0 ? (
            productList.map((product) => (
              <div key={product?.uid}>
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-7">
                    {product?.merdeka_promo_frame ? (
                      <div class="w-full h-full object-center object-cover rounded-lg">
                        <img src={product?.picture?.path?.m} alt={product?.uid} className="h-full" />
                        <img src={MerdekaPromoFrame} alt={'Merdeka Promo'} className="relative bottom-full h-full w-full" />
                      </div>
                    ) : (
                      <Image src={product?.picture?.path?.m} alt={product?.uid} className="w-full h-full object-center object-cover rounded-lg" />
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <span className="text-base font-medium text-gray-700">{product?.name}</span>
                  <span className="text-base font-medium text-gray-500">
                    <ReadMore maxChar={40}>{product?.description}</ReadMore>
                  </span>
                  <div className="flex flex-row">
                    <p className="mt-4 text-lg font-semibold text-gray-900">RM {product?.price?.toFixed(2)}</p>
                    {product?.display_price_dummy && (
                      <p className="mt-4 text-sm line-through font-light text-orange-600 ml-2 relative top-1">RM {product?.display_price_dummy?.toFixed(2)}</p>
                    )}
                  </div>
                  {vendorData?.distance && vendorData?.distance > vendorData?.availabilityRadiusKm ? (
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Out Of Range</span>
                  ) : (
                    <>
                      {vendorData?.deliveryEnable ? (
                        <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Delivery</span>
                      ) : (
                        <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Delivery Unavailable</span>
                      )}
                    </>
                  )}
                  {vendorData?.selfPickupEnable ? (
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Self Pickup</span>
                  ) : (
                    <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Self Pickup Unavailable</span>
                  )}
                </div>
                {userApi?.payload !== null ? (
                  userApi?.addresses.length !== 0 ? (
                    product?.isAvailable && vendorData?.availabilityRadiusKm && vendorData?.distance !== (null || undefined) ? (
                      <>
                        {vendorData?.distance && vendorData?.distance > vendorData?.availabilityRadiusKm && !vendorData?.selfPickupEnable ? (
                          <button
                            type="button"
                            disabled={product?.isAvailable}
                            className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                          >
                            Unavailable
                          </button>
                        ) : (
                          <Link
                            to={`/orders/vendor/${vendorData?.uid}?product_selected=${product?.uid}`}
                            className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                          >
                            Place order
                          </Link>
                        )}
                      </>
                    ) : (
                      <button
                        type="button"
                        disabled={product?.isAvailable}
                        className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                      >
                        Not available
                      </button>
                    )
                  ) : (
                    <button
                      type="button"
                      onClick={addressAction}
                      className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                    >
                      Please add address first
                    </button>
                  )
                ) : product?.isAvailable && vendorData?.availabilityRadiusKm && vendorData?.distance !== (null || undefined) ? (
                  <>
                    {vendorData?.distance && vendorData?.distance > vendorData?.availabilityRadiusKm && !vendorData?.selfPickupEnable ? (
                      <button
                        type="button"
                        disabled={product?.isAvailable}
                        className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                      >
                        Unavailable
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setModalOpenNeedLogin(true)}
                        className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                      >
                        Place order
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type="button"
                    disabled={product?.isAvailable}
                    className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                  >
                    Not available
                  </button>
                )}
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
        <ModalLogin
          show={modalOpenNeedLogin}
          title="Alert!"
          description="Placing an Order?"
          // hide={() => this.setState({ showModalForgotPassword: false })}
          closeable={false}
          confirmText="Register/Log In"
          cancelText="Cancel"
          closeAction={() => setModalOpenNeedLogin(false)}
        >
          <div className="w-full">
            <div className="px-4 py-5 space-y-6 sm:p-6">
              <p>You need to register/log in first to make an order.</p>
            </div>
          </div>
        </ModalLogin>
      </dl>
    </>
  );
};

export default Product;
