import moment from 'moment';
import { useState } from 'react';
import userService from '../../services/user.service';

const getGreetingTime = (currentTime) => {
  if (!currentTime || !currentTime.isValid()) {
    return 'Hi';
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return 'Good afternoon';
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return 'Good evening';
  }
  // Between dawn and noon
  return 'Good morning';
};

const Home = () => {
  return (
    <>
      <h2 className="text-xl sm:text-2xl font-bold text-gray-900">
        {getGreetingTime(moment())}, {userService.getLocalCurrentUser().name}!
      </h2>
    </>
  );
};

export default Home;
