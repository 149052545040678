import { configureStore } from '@reduxjs/toolkit';

import componentsReducer from './slices/components.slice';
import vendorReducer from './slices/vendor.slice';
import userReducer from './slices/user.slice';
import fileReducer from './slices/file.slice';
import customerReducer from './slices/customer.slice';
import orderReducer from './slices/order.slice';

export const store = configureStore({
  reducer: {
    components: componentsReducer,
    vendorApi: vendorReducer,
    userApi: userReducer,
    fileApi: fileReducer,
    customerApi: customerReducer,
    orderApi: orderReducer,
  },
});
