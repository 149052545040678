import { set } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Input } from '../../../components/Form';
import { Modal, ModalDiscard, ModalQuote, ModalUsage, ModalVendor } from '../../../components/Modal';

import { showToast } from '../../../store/slices/components.slice';
import { createProduct, updateProduct, deleteProduct, getVendor, listProducts, createQuote, getQuote } from '../../../store/slices/vendor.slice';
import { ServiceChargePercentage } from '../../../utils';
import QRCode from 'react-qr-code';
import LoadingScreen from '../../../components/LoadingScreen';

const StoreQuoteDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { vendorApi } = useSelector((state) => state);
  const [openQR, setOpenQR] = useState(false);
  const [quote, setQuote] = useState({});

  useEffect(() => {
    handleViewDetail();
  }, []);

  const handleViewDetail = () => {
    dispatch(getQuote(id))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setQuote(data.quote);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  return (
    <>
      <LoadingScreen show={vendorApi.loading} />
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to="/store/quote">Quote</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium">{id}</li>
      </ul>

      <div className="space-y-6 max-w-3xl mx-auto py-6 mb-20 px-4 sm:px-6 lg:px-8 min-h-screen">
        <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md ">
          <div className="grid grid-cols-4 py-6 px-4 sm:px-6 gap-4 sm:gap-4 border-t">
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Quote ID</span>
              <p className="text-sm text-gray-500">{id}</p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Quote Link</span>
              <p onClick={() => setOpenQR(true)} className="text-sm text-gray-500 cursor-pointer break-all">
                {quote?.link}
              </p>
              {quote?.link && (
                <button type="submit" className="bg-primary-600 hover:bg-primary-700 text-gray-100 text-sm font-medium py-2 px-4 rounded mt-2" onClick={() => setOpenQR(true)}>
                  Open QRCode Link
                </button>
              )}
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Date Created</span>
              <p className="text-sm text-gray-500">
                {quote?.date} {quote?.time}
              </p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Customer Phone Number</span>
              <p className="text-sm text-gray-500">{quote?.customerContactNumber}</p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm mr-5">Quote Status</span>
              <div
                className={`block text-sm leading-5 font-semibold rounded-md  
                  ${quote?.orderUid ? 'text-green-600' : quote?.isExpired ? 'text-gray-400' : 'text-primary-600'}
                `}
              >
                {quote?.orderUid ? 'ACCEPTED' : quote?.isExpired ? 'EXPIRED' : 'PENDING'}
              </div>
            </div>
            {quote?.orderUid && (
              <div className="col-span-4 sm:col-span-2">
                <span className="font-medium text-sm">Order ID</span>
                <Link to={`/store/orders/${quote?.orderUid}`}>
                  <div className="text-sm font-medium text-primary-600 hover:text-primary-700">{quote?.orderUid}</div>
                </Link>
              </div>
            )}
          </div>

          <div className="border-t flex-1 py-6 overflow-y-auto px-4 sm:px-6">
            <div>
              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {quote?.products &&
                    quote?.products.map((product, index) => (
                      <li key={index} className="py-6 flex">
                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                          <img src={product.info.picture.path.s} alt={product.info.name} className="w-full h-full object-center object-cover" />
                        </div>

                        <div className="ml-4 flex-1 flex flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>{product.info.name}</h3>
                              <p className="ml-4">RM {Number(product.price * product.quantity).toFixed(2)}</p>
                            </div>
                            <p className="mt-1 text-sm text-gray-500">
                              Qty {product.quantity} x RM {product.price.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  {quote?.discountQuantity > 0 && (
                    <li className="py-6 flex">
                      <div className="ml-4 flex-1 flex flex-col">
                        <div>
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>{quote?.discountDescription}</h3>
                            <p className="ml-4">- RM {quote?.discountQuantity.toFixed(2)}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="text-sm text-gray-900">
                <p className="font-medium">Quote Remark:</p>
                <p className="mt-1 text-gray-500">{quote?.orderRemark || 'Not provided'}</p>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="flex justify-between text-xl font-medium text-gray-900">
                <p>Total</p>
                <p className="font-bold">RM {quote?.totalPrice?.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalQuote
        show={openQR}
        title="Quote Link"
        description="Scan QR below for quote link"
        loading={false}
        closeable={false}
        confirmText="Close"
        confirmAction={() => {
          setOpenQR(false);
        }}
        children={<QRCode title="QuoteLink" value={quote?.link} />}
      />
    </>
  );
};

export default StoreQuoteDetail;
