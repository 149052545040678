import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import OrderForm from './OrderForm';
import OrderList from './OrderList';
import OrderInfo from './OrderInfo';
import { PaymentSuccess, PaymentFailed } from './PaymentStatus';

const Order = () => {
  const location = useLocation();
  const history = useHistory();
  const [distance, setDistance] = useState(0);
  const [allowedToDeliver, setAllowedToDeliver] = useState(true);

  return (
    <>
      <div className="min-h-screen max-w-3xl mx-auto py-6 mb-20 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="flex items-center justify-between px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Order</h3>
            <div className="flex flex-col text-sm text-right">Distance from vendor {<p className="font-semibold">{distance.toFixed(2)} KM</p>}</div>
          </div>
          <div className="border-t border-gray-200">
            {!allowedToDeliver && (
              <div className="mt-6 px-6">
                <div className="flex items-start rounded-lg p-3 ring-1 bg-amber-100 ring-amber-500 text-amber-600">
                  <svg className="w-5 h-5 mr-2 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-sm font-medium">You cannot make an order with this vendor because of the distance gap and vendor does not have self pickup option.</span>
                </div>
              </div>
            )}
            <OrderForm
              showDistance={(distance) => setDistance(distance)}
              canOrder={(allowed) => setAllowedToDeliver(allowed)}
              onSuccess={(data) => history.push(`/orders/${data.uid}`)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Order.List = OrderList;
Order.Info = OrderInfo;
Order.PaymentSuccess = PaymentSuccess;
Order.PaymentFailed = PaymentFailed;

export default Order;
