import _ from 'lodash';
import banner from '../../assets/images/Banner.png';
import vision from '../../assets/images/icons-vision.png';
import mission from '../../assets/images/icons-mission.png';

const AboutUs = () => {
  return (
    <>
      <div className="max-w-full bg-white h-[10rem] md:h-[30rem]">
        <img className="w-full h-full object-center object-cover" src={banner} />
      </div>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto pt-20 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 text-center">Company Information</h2>
          <div className="max-w-7xl mx-auto px-6 md:px-8 py-8 md:py-8">
            <div className="max-w-2xl mx-auto  sm:px-6 lg:max-w-7xl lg:px-8 text-justify">
              <p className="text-xl">
                New Start-Up established on the 30th July 2020 to take advantage of challenges and opportunities due to COVID19 Pandemic scenario. Based on the Blue Ocean Strategy
                approach. NUBESCOM is focusing to identify gap in current business solution in Malaysia and create new added value for vendors/Clients and end customer through
                innovative platform to support small businesses to grow their business. Our target clients are small companies, new enterprises and Home based businesses.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary-100">
        <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 text-center">Mission & Vision</h2>
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 py-8">
            <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md min-h-full">
              <div className="flex justify-center pt-8">
                <img src={mission} />
              </div>
              <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8 text-center">
                <p className="text-3xl font-extrabold tracking-tight text-gray-900">Our Mission</p>
                <p className="text-xl py-8">To be the leading innovative digital marketplace platform in Malaysia</p>
              </div>
            </div>
            <div>
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md min-h-full">
                <div className="flex justify-center pt-8">
                  <img src={vision} />
                </div>
                <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8 text-center">
                  <p className="text-3xl font-extrabold tracking-tight text-gray-900 ">Our Vision</p>
                  <p className="text-xl py-8">Our vision is to create a unique product and solution that contribute to the betterment of the quality of human life</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OUR TEAM SECTION
      <div className="bg-white pb-8">
        <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 text-center py-6">Our Team</h2>
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-1 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            <div className="flex justify-center">
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md w-64 lg:w-80">
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 border-2">
                    <img
                      src={'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center pb-4">
                  <p className="mt-4 text-lg font-semibold text-gray-900">Ali bin Abu</p>
                  <p className="text-sm font-normal">Chief Financial Officer</p>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md w-64 lg:w-80">
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 border-2">
                    <img
                      src={'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center pb-4">
                  <p className="mt-4 text-lg font-semibold text-gray-900">Ali bin Abu</p>
                  <p className="text-sm font-normal">C.E.O</p>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md w-64 lg:w-80">
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 border-2">
                    <img
                      src={'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center pb-4">
                  <p className="mt-4 text-lg font-semibold text-gray-900">Ali bin Abu</p>
                  <p className="text-sm font-normal">Chief Operating Officer</p>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md w-64 lg:w-80">
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 border-2">
                    <img
                      src={'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center pb-4">
                  <p className="mt-4 text-lg font-semibold text-gray-900">Ali bin Abu</p>
                  <p className="text-sm font-normal">Chief Customer Strategy</p>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md w-64 lg:w-80">
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 border-2">
                    <img
                      src={'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center pb-4">
                  <p className="mt-4 text-lg font-semibold text-gray-900">Ali bin Abu</p>
                  <p className="text-sm font-normal">Chief Software Architect</p>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md w-64 lg:w-80">
                <div className="group">
                  <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 border-2">
                    <img
                      src={'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center pb-4">
                  <p className="mt-4 text-lg font-semibold text-gray-900">Ali bin Abu</p>
                  <p className="text-sm font-normal">Head of Project & Operations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutUs;
