import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '../../components/Form';

import { getUser, saveProfilePicture, updateUser } from '../../store/slices/user.slice';
import { showToast } from '../../store/slices/components.slice';
import { useHistory } from 'react-router';

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [profile, setProfile] = useState({});
  const [allowToUpdate, setAllowToUpdate] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const { userApi } = useSelector((state) => state);

  useEffect(() => {
    setProfile(props?.userData);
    localStorage.setItem('user', JSON.stringify(props?.userData));
  }, [props.userData]);

  const handleUpdate = (data) => {
    let attributes = Object.keys(data);

    attributes.forEach((attribute) => {
      if (!data[attribute]) {
        delete data[attribute];
      }
    });

    dispatch(updateUser(data))
      .unwrap()
      .then(({ data }) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your profile updated successfully!` }));
        dispatch(saveProfilePicture(data.profilePic));
        localStorage.setItem('user', JSON.stringify(data));
      })
      .catch(({ message, validate }) => {
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details);
        setTimeout(() => {
          const element = document.getElementById('rule-error');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      });
  };

  return (
    <>
      <Form defaultValues={profile} onSubmit={handleUpdate} setErrors={formErrors} className="space-y-6 max-w-3xl mx-auto py-6 mb-20 px-4 sm:px-6 lg:px-8 min-h-screen">
        <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md">
          <div className="py-4 px-6 flex items-center justify-between">
            <h2 className="font-medium text-xl">My profile</h2>
            <button
              type="submit"
              onClick={() => {
                setTimeout(() => {
                  const element = document.getElementById('rule-error');
                  element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 100);
              }}
              disabled={allowToUpdate}
              className="disabled:opacity-50 disabled:cursor-not-allowed flex items-center text-amber-100 bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 px-3 py-2 rounded-md text-sm font-medium"
            >
              Update
            </button>
          </div>
          <div className="border-t grid grid-cols-4 py-6 px-4 sm:px-6 gap-4 sm:gap-4">
            <div className="col-span-4">
              <Input.Avatar
                overlayMode={(data) => setAllowToUpdate(data)}
                name="profilePic"
                label="Profile photo"
                apiParams={{ object: 'user.profilePic' }}
                disabled={userApi.loading}
              />
            </div>
            <div className="col-span-4">
              <h2>Email</h2>
              <p>{profile?.email}</p>
            </div>
            <div className="col-span-4">
              <Input.Text name="name" label="Name" placeholder="Your name" disabled={userApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
            </div>
            <div className="col-span-4">
              <Input.Phone
                name="phoneNumber"
                label="Phone number"
                placeholder="Your phone number"
                disabled={userApi.loading}
                rules={[{ required: true, message: 'This field is required' }]}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default UserProfile;
