import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';
import { showToast } from '../../store/slices/components.slice';
import { listOrders } from '../../store/slices/customer.slice';
import { statusFormatter } from '../../utils';
import { ModalSubmitReview } from '../../pages/Order/components/SubmitReview';

const OrderList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const { customerApi } = useSelector((state) => state);
  const [modalRatingOpen, setModulRatingOpen] = useState(false);
  const [id, setid] = useState(null);

  useEffect(() => {
    dispatch(listOrders())
      .unwrap()
      .then((result) => {
        const { data } = result;
        setOrders(data.orders);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  }, []);

  return (
    <>
      <LoadingScreen show={customerApi.loading} />
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center">
          <h1 className="text-2xl lg:text-3xl font-bold text-gray-900">My orders</h1>
        </div>
      </div>
      <div className="space-y-6 max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:px-8 min-h-screen">
        {orders.map((order, index) => (
          <div class="relative">
            <Link to={`/orders/${order.uid}`} key={index} className="p-6 flex flex-col bg-white border rounded-lg shadow-md">
              <div className="flex justify-between">
                <h2 className="font-medium">{order.vendorStoreName}</h2>
                <p className="text-gray-500">RM {order.totalPrice.toFixed(2)}</p>
              </div>
              <p className="mt-1 text-sm font-medium text-gray-900">
                {order?.selfPickup ? 'Pickup date' : 'Delivery date'}
                <span className="font-normal text-gray-500"> {moment(`${order.deliverDate} ${order.deliverTime}`).format('DD MMM YYYY @ hh:mm A')}</span>
              </p>
              <div className="flex justify-between">
                <p className="mt-1 text-sm font-medium text-gray-900">
                  Status:
                  <span style={{ color: statusFormatter(order.status).color }} className="font-normal">
                    {' '}
                    {statusFormatter(order.status).text}
                  </span>
                </p>
              </div>
            </Link>
            <div class="absolute bottom-3 right-3">
              {order.status == 'DELIVERED' ? (
                <button
                  type="delete"
                  onClick={() => {
                    setid(order.uid);
                    setModulRatingOpen(true);
                  }}
                  className="disabled:opacity-50 disabled:cursor-not-allowed inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-green-500 hover:bg-green-700"
                >
                  Order Received
                </button>
              ) : null}
              {order.status == 'COMPLETED' && !order.reviewRating && (
                <button
                  type="delete"
                  onClick={() => {
                    setid(order.uid);
                    setModulRatingOpen(true);
                  }}
                  className="disabled:opacity-50 disabled:cursor-not-allowed inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-green-500 hover:bg-green-700"
                >
                  Review now
                </button>
              )}
            </div>
          </div>
        ))}
        <ModalSubmitReview id={id} show={modalRatingOpen} closeAction={() => setModulRatingOpen(false)} />
      </div>
    </>
  );
};

export default OrderList;
