import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory, useLocation, useParams,  } from 'react-router';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';
import { showToast } from '../../../store/slices/components.slice';

import { getUser, updateUser } from '../../../store/slices/user.slice';
import AddressForm from './AddressForm';
import { ModalBack } from '../../../components/Modal';

const Address = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { userApi } = useSelector((state) => state);
  const [modalOpen, setModulOpen] = useState(false);

  let action = location.pathname.split('/')[3];

  const [loading, setLoading] = useState(true);
  let [formData, setFormData] = useState({ addresses: [] });

  useEffect(() => {
    if (props.vendorData) {
      setFormData(props?.vendorData?.data);
      setLoading(false);
    }
  }, [props.vendorData]);

  const handleRemoveAddress = () => {
    let addresses = formData.addresses.map((address) => Object.assign({}, address));
    addresses.splice(parseInt(id), 1);
    if (formData.addresses.length > 1) {
      const isDefaultExist = addresses.find((item) => item.isDefault);
      if (!isDefaultExist) {
        addresses[0].isDefault = true;
      }
      dispatch(updateUser({ addresses }))
        .unwrap()
        .then(({ message }) => {
          dispatch(showToast({ show: true, type: 'success', message: message }));
          setFormData(formData);
          history.push('/addresses');
        });
    } else {
      setModulOpen(true);
    }
  };

  return (
    <>
      <LoadingScreen show={loading} />
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to="/store/addresses">Store Addresses</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium">{formData?.newData?.name || formData?.name || id}</li>
      </ul>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="flex items-center justify-between px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{action === 'edit' ? 'Edit address' : 'Add new address'}</h3>
            {action !== 'new' && (
              <button
                type="button"
                onClick={() => handleRemoveAddress(action)}
                disabled={userApi.loading}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {userApi.loading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <>
                    <svg className="-ml-1 mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      ></path>
                    </svg>
                    Remove
                  </>
                )}
              </button>
            )}
          </div>
          <div className="border-t border-gray-200">
            <AddressForm addresses={formData.addresses} loading={loading} address={location.state} action={action} />
          </div>
        </div>
      </div>
      <ModalBack show={modalOpen} title="Alert!" loading={userApi.loading} closeable={false} confirmText="Back" confirmAction={() => setModulOpen(false)}>
        <div className="w-full">
          <div className="px-4 py-5 space-y-6 sm:p-6">
            <p>Cannot remove this address. You need to have at least ONE address.</p>
          </div>
        </div>
      </ModalBack>
    </>
  );
};

export default Address;
