import tokenService from '../services/token.service';
import { useState, useEffect } from 'react';

export const ServiceChargePercentage = 15;

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const getRoundedCanvas = (sourceCanvas) => {
  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  let width = sourceCanvas.width;
  let height = sourceCanvas.height;
  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();
  return canvas;
};

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

export const validate = {
  url: (str) => {
    const matchpattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return matchpattern.test(str);
  },
};

export const chunkingOperationHours = (dayOperationHour) => {
  let newArray = [];
  let isNextArrayNew = false;
  return dayOperationHour
    .map((hour, index) => {
      if (isNextArrayNew) newArray = [];
      if (parseInt(hour) + 1 === parseInt(dayOperationHour[index + 1])) {
        newArray.push(hour);
        isNextArrayNew = false;
      } else {
        newArray.push(hour);
        isNextArrayNew = true;
        return newArray;
      }
    })
    .filter(Boolean);
};

export const getAddressGeocode = async (geocoder, addressObj) => {
  return await geocoder
    .geocode({
      address: Object.keys(addressObj)
        .map((key) => {
          return addressObj[key];
        })
        .join(' '),
    })
    .then(({ results }) => {
      let { address_components, geometry } = results[0];
      let stateName = null;

      const kualaLumpurNames = ['Federal Territory of Kuala Lumpur', 'Wilayah Persekutuan Kuala Lumpur', 'Kuala Lumpur'];
      const pulauPinangNames = ['Penang', 'Pulau Pinang'];

      if (kualaLumpurNames.includes(address_components.find(({ types }) => types.includes('administrative_area_level_1'))?.long_name)) {
        stateName = 'Kuala Lumpur';
      } else if (pulauPinangNames.includes(address_components.find(({ types }) => types.includes('administrative_area_level_1'))?.long_name)) {
        stateName = 'Pulau Pinang';
      } else {
        stateName = address_components.find(({ types }) => types.includes('administrative_area_level_1'))?.long_name;
      }

      return {
        state: stateName,
        postalCode: address_components.find(({ types }) => types.includes('postal_code'))?.long_name,
        coordinate: { lat: geometry.location.lat(), lon: geometry.location.lng() },
      };
    })
    .catch((error) => {
      console.warn('getAddressGeocode failed: ', error);
      return {
        // state: null,
        // postalCode: null,
        coordinate: null,
      };
    });
};

export const statusFormatter = (status) => {
  let params = {
    text: humanize(status || ''),
    color: 'grey',
  };
  switch (status) {
    case 'CHECK_OUT':
      params.color = '#ff9900';
      break;
    case 'PAID':
      params.color = '#33cc33';
      break;
    case 'EXPIRED':
      params.color = '#ff3300';
      break;
    case 'CANCELED':
      params.color = '#ff3300';
      break;
    case 'ON_THE_WAY':
      params.color = '#ff9900';
      break;
    case 'DELIVERED':
      params.color = '#33cc33';
      break;
    case 'PICKED_UP':
      params.color = '#33cc33';
      break;
    case 'COMPLETED':
      params.color = '#33cc33';
      break;
    default:
      params.color = 'grey';
  }
  return params;
};

export const humanize = (str) => {
  var i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

export const monthToText = (month) => {
  let monthText;
  switch (month) {
    case 0:
      monthText = 'JAN';
      break;
    case 1:
      monthText = 'FEB';
      break;
    case 2:
      monthText = 'MAR';
      break;
    case 3:
      monthText = 'APR';
      break;
    case 4:
      monthText = 'MAY';
      break;
    case 5:
      monthText = 'JUN';
      break;
    case 6:
      monthText = 'JUL';
      break;
    case 7:
      monthText = 'AUG';
      break;
    case 8:
      monthText = 'SEP';
      break;
    case 9:
      monthText = 'OCT';
      break;
    case 10:
      monthText = 'NOV';
      break;
    case 11:
      monthText = 'DEC';
      break;
  }
  return monthText;
};

export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const isSessionExist = () => {
  return tokenService?.getToken() != null && tokenService?.getToken() != '';
};

export const isNewPage = (currentPath) => ['/', '/search'].some((value) => value === currentPath);
