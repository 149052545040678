import api from './index';

class UserService {
  getUser() {
    return api.get('/user').then((response) => {
      return response.data;
    });
  }

  updateUser(data) {
    return api.put('/user', data).then((response) => {
      return response.data;
    });
  }

  getLocalCurrentUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }
}

export default new UserService();
