import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { classNames } from '../../../utils';
import { Link } from 'react-router-dom';
import AddressForm from './AddressForm';
import LoadingScreen from '../../../components/LoadingScreen';

const Addresses = ({ vendorData }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  let [formData, setFormData] = useState();

  useEffect(() => {
    getAddressesData();
  }, [vendorData]);

  const getAddressesData = () => {
    setFormData(vendorData?.data?.addresses);
    setLoading(false);
  };

  return (
    <>
      <LoadingScreen show={loading} />
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center">
          <h1 className="text-2xl lg:text-3xl font-bold text-gray-900">Store Addresses</h1>
          {formData?.length > 0 && (
            <Link
              to="/store/addresses/new"
              type="button"
              className="ml-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Add new address
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {!loading && (!formData || formData?.length === 0) && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Set new address</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Please enter your new address information below</p>
              </div>
              <div className="border-t border-gray-200">
                <AddressForm addresses={formData} loading={loading} />
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4 md:px-6">
            {formData?.map((address, index) => (
              <button
                type="button"
                onClick={() => history.push({ pathname: `/store/addresses/edit/${index}`, state: address })}
                className="col-span-1 flex text-left items-start w-full p-6 bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow"
              >
                <div>
                  <span className="text-base font-medium">{address?.name}</span>
                  <p className="mt-1 text-sm text-gray-700">{`${address?.address1}, ${address?.address2}, ${address?.postalCode} ${address?.city}, ${address?.state}`}</p>
                  <p className="mt-2 text-xs text-gray-500">{`${address?.location?.lat}, ${address?.location?.lon}`}</p>
                </div>
                {/* <span className={classNames(address?.isDefault ? 'ml-auto px-2 text-xs leading-5 font-semibold rounded-md bg-rose-100 text-rose-600 uppercase' : 'hidden')}>
                  Default
                </span> */}
                <span className={classNames(address?.disable ? 'ml-auto px-2 text-xs leading-5 font-semibold rounded-md bg-rose-100 text-rose-600 uppercase' : 'hidden')}>
                  Disable
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Addresses;
