import Alert from '@reach/alert';
import { classNames } from '../../utils';

const Tooltip = ({ children, from, bgColor = 'bg-amber-600 text-white', chevronColor = 'text-amber-600' }) => {
  let position = null;

  switch (from) {
    case 'bottom':
      position = 'rotate-180 bottom-full';
      break;
    default:
      position = 'top-full';
      break;
  }

  return (
    <Alert className={classNames(bgColor, 'relative text-xs leading-6 font-medium px-1.5 rounded-lg sm:w-60 flex items-center justify-center')}>
      {children}
      <svg aria-hidden="true" width="16" height="6" viewBox="0 0 16 6" className={classNames(position, chevronColor, 'absolute left-1/2 -mt-px -ml-2')}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0H1V1.00366V1.00366V1.00371H1.01672C2.72058 1.0147 4.24225 2.74704 5.42685 4.72928C6.42941 6.40691 9.57154 6.4069 10.5741 4.72926C11.7587 2.74703 13.2803 1.0147 14.9841 1.00371H15V0Z"
          fill="currentColor"
        />
      </svg>
    </Alert>
  );
};

export default Tooltip;
