const PrivacyPolicy = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto flex-start justify-center bg-white border-l border-r">
        <div className="w-full sm:grid sm:place-content-center ">
          <div className="max-w-6xl text-left px-8 lg:px-0 space-y-6">
            <h1 className="font-bold text-3xl pt-8">Privacy Policy</h1>

            <p className="font-bold">Last updated: 23 June 2023</p>

            <p>Dear NUBES Vendors and NUBES Customers (collectively referred to as &quot;Users&quot; below),</p>

            <p>
              Thank you for your interest in data protection. This privacy policy provides you with an easily understandable overview of our data processing practices and privacy
              compliance measures in relation to our services (referred to as the &quot;Platform&quot; below). Our aim is to provide you with an exceptional user experience while
              ensuring the security of your personal data. Trust, transparency, and honesty are the guiding principles behind our operations. Your trust in our product enables us
              to deliver an exceptional user experience.
            </p>
            <h1 className="font-bold text-xl pt-4">1. Who We Are</h1>

            <p class="ml-6">
              We are Nubescom Sdn Bhd (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), commonly known as NUBES.MY. As the data controller responsible for the processing
              activities on our Platform, we determine how your personal data is processed, the purposes for which it is processed, and the means by which it is processed. We
              provide this information not only to fulfil our legal obligations but also to foster an open and honest partnership.
            </p>

            <p class="ml-6">
              If you have any questions about data protection at NUBES, please feel free to contact our staff via our user support channels stated on NUBES.MY and our social media
              platforms.
            </p>
            <h1 className="font-bold text-xl pt-4">2. Privacy is Your Right and the Choice is Yours</h1>

            <p class="ml-6">
              As a User, you have the choice of which information to share with us. However, please note that by signing up for and/or accessing our Platform, you are required to
              accept our terms of use, which form a contractual agreement between you and us. Some information is necessary for us to fulfil our obligations under this contract.
              Nevertheless, it is entirely up to you to decide whether you wish to provide such information or opt not to use our Platform.
            </p>

            <p class="ml-6">To control and manage the personal data you share with us, you can take the following steps:</p>
            <h2 className="font-bold text-l ml-2">I. Cookies &amp; web-tracking</h2>
            <p class="ml-6">
              You can configure your device or web browser to decline cookies and other web-tracking technologies. This can be done through our consent manager. However, disabling
              web-tracking will result in the loss of personalised content, offers, and ads.
            </p>
            <h2 className="font-bold text-l ml-2">II. Direct marketing</h2>
            <p class="ml-6">If you do not wish to receive newsletters from us, you can unsubscribe at any time. In this case, you will not receive any promotional offers.</p>
            <h2 className="font-bold text-l ml-2">III. Withdrawal of consent</h2>

            <p class="ml-6">You may withdraw your consent for the processing of your personal data for certain purposes, such as marketing, by contacting our user support.</p>

            <h1 className="font-bold text-xl pt-4">3. Your Legal Rights</h1>

            <h2 className="font-bold text-l ml-2">i. Right to access</h2>

            <p class="ml-6">You may access your stored data which you have provided to us, as displayed in the Platform.</p>

            <h2 className="font-bold text-l ml-2">ii. Right to rectification</h2>

            <p class="ml-6">If you notice any incorrect stored data, you can request us to correct it. We will respond to your request as soon as possible.</p>

            <h2 className="font-bold text-l ml-2">iii. Right to withdraw consent</h2>

            <p class="ml-6">
              You can withdraw your consent to the collection, use, and disclosure of your personal data at any time. Upon receiving your withdrawal request, we may require a
              reasonable amount of time to process your request and notify you of the consequences, including any legal implications. Depending on the nature of your request, we
              may not be able to continue providing our services.
            </p>

            <p class="ml-6">To exercise your rights, please contact our support. Please note that we may charge a reasonable fee for handling and processing your requests.</p>

            <h1 className="font-bold text-xl pt-4">4. Collecting and Using Your Personal Data</h1>

            <h2 className="font-bold text-l ml-2">I. Types of Data Collected</h2>

            <h3 className="font-bold text-l ml-4">A. Personal Data:</h3>

            <p class="ml-6">
              While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally
              identifiable information may include, but is not limited to:
            </p>
            <p class="ml-6">- Email address</p>
            <p class="ml-6">- First name and last name</p>
            <p class="ml-6">- Phone number</p>
            <p class="ml-6">- Address, State, Province, ZIP/Postal code, City</p>

            <h3 className="font-bold text-l ml-4">B. Usage Data:</h3>

            <p class="ml-6">
              We also collect Usage Data automatically when You use the Service. Usage Data may include information such as Your Device&apos;s Internet Protocol address (e.g., IP
              address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device
              identifiers, and other diagnostic data. When You access the Service through a mobile device, We may collect additional information, including the type of mobile
              device You use, Your mobile device&apos;s unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use,
              unique device identifiers, and other diagnostic data.
            </p>

            <h3 className="font-bold text-l ml-4">C. Tracking &amp; Cookies Data:</h3>

            <p class="ml-6">
              We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data that
              are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and
              to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <h3 className="font-bold text-l ml-4">D. We source personal data from various channels, including:</h3>
            <h4 className="font-bold text-l ml-6">i. Website and online forms:</h4>
            <p class="ml-6">When you visit our website or fill out online forms, we collect personal data based on the information you provide.</p>

            <h4 className="font-bold text-l ml-6">ii. Manual forms:</h4>
            <p class="ml-6">When you fill out manual forms, such as sign-up sheets or questionnaires, we collect personal data based on the information you provide.</p>

            <h4 className="font-bold text-l ml-6">iii. Cookies:</h4>
            <p class="ml-6">We use cookies and similar technologies to collect personal data automatically. For more information on how we use cookies.</p>
            <h4 className="font-bold text-l ml-6">iv. Phone calls:</h4>
            <p class="ml-6">When you contact our support via phone, we may collect personal data based on the information you provide during the call.</p>

            <h4 className="font-bold text-l ml-6">v. Live chats:</h4>
            <p class="ml-6">When you contact our support via live chat, we may collect personal data based on the information you provide during the chat.</p>

            <h4 className="font-bold text-l ml-6">vi. Third parties:</h4>
            <p class="ml-6">
              We may receive personal data from third parties with your consent. For example, if you choose to sign up or log in to our platform using your social media account, we
              may receive certain information from the social media platform.
            </p>

            <h2 className="font-bold text-l ml-2">II. Use of Your Personal Data</h2>

            <p class="ml-6">We use the collected data for various purposes, including but not limited to:</p>
            <h3 className="font-bold text-l ml-4">A. Creating and operating your account:</h3>
            <p class="ml-6">
              We process personal data to create and manage your account, enable login functionality, offer single sign-on with Google, and/or any other similar functioning web
              platform, manage your profile, process your orders, store your cart, deliver your orders, and save your payment methods. Please note that Stripe, our Payment Gateway
              provider, also retains user, transaction, and order records as necessary for their services.
            </p>
            <h3 className="font-bold text-l ml-4">B. To provide and maintain the Platform:&nbsp;</h3>
            <p class="ml-6">
              We utilise the data we collect to manage your account, process transactions, and deliver the services you request. This involves ensuring the smooth functioning of
              the Platform and enabling you to effectively use its features and functionalities.
            </p>
            <h3 className="font-bold text-l ml-4">C. To personalise your experience:&nbsp;</h3>
            <p class="ml-6">
              We strive to enhance your experience on the Platform by tailoring the content and features to your preferences and interests. By analysing the data we collect, such
              as your usage patterns and interactions with the Platform, we can customize and optimize your user experience.
            </p>
            <h3 className="font-bold text-l ml-4">D. To communicate with you:&nbsp;</h3>
            <p class="ml-6">
              We may use your data to send you important service-related notifications, updates, and relevant information about the Platform. This includes notifying you about
              changes to our services, updates to our terms and policies, and responding to your inquiries or requests.
            </p>
            <h3 className="font-bold text-l ml-4">E. To improve our services:&nbsp;</h3>
            <p class="ml-6">
              By analyzing aggregated data and feedback from our Users, we gain insights that help us enhance the functionality, user interface, and overall user experience of the
              Platform. This may involve identifying and addressing any issues, optimizing performance, and introducing new features and improvements.
            </p>
            <h3 className="font-bold text-l ml-4">F. To enforce our terms and policies:&nbsp;</h3>
            <p class="ml-6">
              We may need to utilize the data we collect to enforce our terms of service and policies. This includes investigating potential violations, addressing fraudulent
              activities or security concerns, and safeguarding the rights, property, and safety of our Users and the Platform.
            </p>

            <p class="ml-6">
              We handle your data with the utmost care and ensure that it is processed in accordance with applicable data protection laws. We only use your data for legitimate
              purposes and do not intentionally and purposely engage in any unauthorized or intrusive activities.
            </p>
            <h2 className="font-bold text-l ml-2">III. Legal Basis for Processing Personal Data</h2>
            <p class="ml-6">We process your personal data based on the following legal grounds:</p>
            <h3 className="font-bold text-l ml-4">A. Performance of a contract:&nbsp;</h3>
            <p class="ml-6">
              In certain instances, the processing of your personal data is necessary for the performance of a contract between you and us, or for taking steps at your request
              before entering into a contract. This means that we need to process your data to fulfil our obligations and provide you with the services you have requested through
              the Platform.
            </p>
            <h3 className="font-bold text-l ml-4">B. Compliance with a legal obligation:&nbsp;</h3>
            <p class="ml-6">
              In some cases, we are required to process your personal data to comply with a legal obligation to which we are subjected to. This includes, for example, obligations
              related to tax compliance, fraud prevention, and other regulatory requirements.
            </p>
            <h3 className="font-bold text-l ml-4">C. Legitimate interests:&nbsp;</h3>
            <p class="ml-6">
              We may process your personal data for our legitimate interests, as long as it does not override your fundamental rights and freedoms. These legitimate interests
              include, but are not limited to:
            </p>
            <h4 className="font-bold text-l ml-6">i. Fraud detection and prevention:&nbsp;</h4>
            <p class="ml-6">
              We may process your data to detect and prevent fraudulent activities, unauthorized access, or other security breaches, protecting both our Users and the Platform.
            </p>
            <h4 className="font-bold text-l ml-6">ii. Security:&nbsp;</h4>
            <p class="ml-6">
              We may process your personal data to ensure the security and integrity of the Platform, including monitoring of suspicious activities, investigating potential
              violations, and implementing security measures to protect against threats.
            </p>
            <h4 className="font-bold text-l ml-6">iii. Improvement of our services:&nbsp;</h4>
            <p class="ml-6">
              We may use your personal data to analyze usage patterns, gather feedback, and conduct research and development activities. This helps us improve the functionality,
              features, and overall user experience of the Platform.
            </p>

            <p class="ml-6">
              We ensure that the processing of your personal data is carried out in a fair and proportionate manner, taking into consideration your rights and interests. We conduct
              regular assessments to ensure that the legal basis for processing your data remains valid and relevant.
            </p>

            <h2 className="font-bold text-l ml-2">IV. Information from Third-Party Social Media Services</h2>

            <p class="ml-6">
              We may allow you to log in to our Service using third-party social media services (e.g., Facebook, Google). If you choose to log in using these services, we may
              collect certain information from your social media account, as permitted by the terms of the social media platform and your privacy settings. This may include your
              name, email address, profile picture, and other information that you choose to make public on your social media profile. We will use and protect this information in
              accordance with this Privacy Policy.
            </p>

            <h2 className="font-bold text-l ml-2">V. To achieve these purposes, we collect the following categories of personal data:</h2>
            <h3 className="font-bold text-l ml-4">A. Profile data (master data)</h3>
            <p class="ml-6">
              This includes your name, email address, password, telephone number, delivery addresses, interests, and age. We require this information to create your profile and
              provide our services.
            </p>
            <h3 className="font-bold text-l ml-4">B. Delivery data</h3>
            <p class="ml-6">This includes your name, delivery address, phone number, order details, and order ID. We collect this information to fulfil your orders.</p>
            <h3 className="font-bold text-l ml-4">C. Order history data</h3>
            <p class="ml-6">
              This includes your order history, selected shops or restaurants, invoices, order ID, payment information, and delivery address. We collect this information to improve
              our services and provide you with personalised recommendations.
            </p>
            <h3 className="font-bold text-l ml-4">D. Location data</h3>
            <p class="ml-6">
              This includes your address, postcode, city, country, and device&apos;s longitude and latitude. We collect this information to offer location-based services and
              personalise your experience.
            </p>
            <h3 className="font-bold text-l ml-4">E. Device information and access data</h3>
            <p class="ml-6">
              This includes your device ID, operating system, IP address, and access details. We collect this information to ensure the security and functionality of our platform.
            </p>
            <h3 className="font-bold text-l ml-4">F. User care data</h3>
            <p class="ml-6">
              This includes your name, address, telephone number, email address, and social media ID. We collect this information to provide support and respond to your inquiries.
            </p>
            <h3 className="font-bold text-l ml-4">G. Marketing contact and communications data</h3>
            <p class="ml-6">
              This includes your name, email address, telephone number, and device ID. We collect this information to send you marketing communications if you have opted in.
            </p>
            <h3 className="font-bold text-l ml-4">H. Payment data</h3>
            <p class="ml-6">
              This includes your payment method, encrypted credit card information, and Stripe transaction details. We use Stripe as our Payment Gateway to securely process your
              payments.
            </p>

            <h1 className="font-bold text-xl pt-4">5. Retention of Your Personal Data</h1>
            <p class="ml-6">
              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data
              to the extent necessary to comply with our legal obligations (for example, if we are required to retain Your data to comply with applicable laws), resolve disputes,
              and enforce our legal agreements and policies.
            </p>

            <p class="ml-6">
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to
              strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>
            <h1 className="font-bold text-xl pt-4">6. Transfer of Your Personal Data:</h1>

            <p class="ml-6">
              Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of Your state, province, country, or other
              governmental jurisdiction where the data protection laws may differ from those of Your jurisdiction.
            </p>

            <p class="ml-6">
              If You are located outside Malaysia and choose to provide information to Us, please note that We transfer the data, including Personal Data, to Malaysia and process
              it there.
            </p>

            <p class="ml-6">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>

            <p class="ml-6">
              The Company will take all the steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of
              Your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of Your data and other personal
              information.
            </p>

            <h1 className="font-bold text-xl pt-4">7. Disclosure of Your Personal Data</h1>

            <p class="ml-6">
              At NUBES, we take your privacy seriously and strive to protect your personal data. However, under certain circumstances, we may need to disclose your personal data as
              outlined below:
            </p>

            <h2 className="font-bold text-l ml-2">A. Service Providers:</h2>
            <p class="ml-6">
              We may engage trusted third-party service providers who assist us in operating the Platform and delivering our services. These service providers have access to your
              personal data only to perform tasks on our behalf and are obligated to protect it.
            </p>

            <h2 className="font-bold text-l ml-2">B. Compliance with Laws:</h2>
            <p class="ml-6">We may disclose your personal data if required by law or in response to valid legal requests, such as court orders or government regulations.</p>

            <h2 className="font-bold text-l ml-2">C. Business Transfers:</h2>
            <p class="ml-6">
              In the event of a merger, acquisition, or sale of our assets, your personal data may be transferred to the relevant third party as part of the transaction. We will
              provide notice before your personal data is transferred and becomes subject to a different privacy policy.
            </p>

            <h2 className="font-bold text-l ml-2">D. Law Enforcement:</h2>
            <p class="ml-6">
              Under certain circumstances, we may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities,
              such as a court or a government agency.
            </p>

            <h2 className="font-bold text-l ml-2">E. Other Legal Requirements:</h2>
            <p class="ml-6">
              We may disclose your personal data in good faith belief that such action is necessary to comply with a legal obligation, protect and defend our rights or property,
              prevent or investigate possible wrongdoing in connection with the service, protect the personal safety of users of the service or the public, or protect against legal
              liability.
            </p>

            <h1 className="font-bold text-xl pt-4">8. Security of Your Personal Data</h1>

            <p class="ml-6">
              The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
              While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            </p>

            <h1 className="font-bold text-xl pt-4">9. Use of Stripe Payment Gateway</h1>

            <p class="ml-6">
              We use the Stripe payment gateway to process transactions on our platform. When you make a payment, your payment information, such as credit card details or other
              financial account information, may be collected and processed by Stripe. We do not store or have access to your complete payment information. The collection and
              processing of your payment information by Stripe are subject to their own privacy policies and terms of service. We recommend reviewing&nbsp;
              <a href="https://stripe.com/en-my/privacy">Stripe&apos;s privacy policy</a> to understand how your payment information is handled.
            </p>

            <h1 className="font-bold text-xl pt-4">10. Third-Party Links and Services</h1>
            <p class="ml-6">
              The Platform may contain links to third-party websites or services that are not operated or controlled by us. This Privacy Policy does not apply to those third-party
              platforms. We recommend reviewing the privacy policies of those platforms before providing any personal data.
            </p>

            <h1 className="font-bold text-xl pt-4">11. Children&apos;s Privacy</h1>

            <p class="ml-6">
              Our Service does not address anyone under the age of 18 (&quot;Children&quot;). We do not knowingly collect personally identifiable information from anyone under the
              age of 18. If you are a parent or guardian and you are aware that your Child has provided us with personal data, please contact us. If we become aware that we have
              collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.
            </p>

            <h1 className="font-bold text-xl pt-4">12. Changes to This Privacy Policy</h1>

            <p class="ml-6">
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email
              and/or a prominent notice on our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy. You
              are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>

            <p class="ml-6">
              We reserve the right to change this privacy notice to ensure compliance with relevant legal and statutory provisions, including the Personal Data Protection Act 2010
              (PDPA). We will inform you of any significant changes, such as changes of purpose or new purposes of processing.
            </p>

            <h1 className="font-bold text-xl pt-4">13. Contact Us</h1>

            <p class="ml-6">
              If you have any questions about this Privacy Policy, please contact us by visiting our support channels stated on NUBES.MY and our social media platforms.
            </p>
            <p class="ml-6">Thank you for taking the time to read our Privacy Policy. We value your privacy and appreciate your trust in us.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
