import SearchProduct from '../../components/SearchProduct/SearchProduct';
import BaseContainer from '../../components/BaseContainer/BaseContainer';
import { isArrayNotEmpty } from '../../helper';
import DeliveryLocationPicker from '../../components/DeliveryLocationPicker/DeliveryLocationPicker';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useHistory, useLocation } from 'react-router-dom';
import { isSessionExist } from '../../utils';
import { searchProduct } from '../../store/slices/customer.slice';
import StoreListing from '../../components/StoreListing/StoreListing';
import { createUrlWithParams } from '../../helper/url';

const SearchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userApi, customerApi } = useSelector((state) => state);
  const [searchParam, setSearchParam] = useState({
    search: new URLSearchParams(location.search).get('keyword') ?? '',
    sortBy: new URLSearchParams(location.search).get('sortBy') ?? '',
    filterBy: new URLSearchParams(location.search).get('filterBy') ?? '',
  });

  useEffect(() => {
    /* Session not exist, no need to wait for user payload */
    if (!isSessionExist()) {
      dispatch(
        searchProduct({
          location: {
            lat: userApi.currentCoordinate.lat,
            lon: userApi.currentCoordinate.lon,
          },
          search: searchParam?.search,
          sortBy: searchParam?.sortBy,
          filterBy: searchParam?.filterBy,
        }),
      );
      return;
    }

    /* Session exist, wait for user payload */
    if (userApi.payload) {
      dispatch(
        searchProduct({
          location: userApi?.deliverToAddress?.location ?? '', // location from user address
          search: searchParam?.search,
          sortBy: searchParam?.sortBy,
          filterBy: searchParam?.filterBy,
        }),
      );
      return;
    }
    /* ignore effect */
  }, [userApi.deliverToAddress, userApi.currentCoordinate, searchParam]);

  const onSearchSubmit = (data) => {
    let endpoint = createUrlWithParams('/search', {
      keyword: data.searchKeyword,
      sortBy: data?.activeSelection?.get('Sort by').value ?? '',
      // filterBy: data?.activeSelection?.get('Filter by').value ?? '',
    });
    let search_params = endpoint.split('/search');
    history.push({ pathname: '/search', search: search_params[1] });
    setSearchParam({
      search: data.searchKeyword,
      sortBy: data?.activeSelection?.get('Sort by').value ?? '',
      // filterBy: data?.activeSelection?.get('Filter by').value ?? '',
    });
  };

  return (
    <>
      <BaseContainer className="mt-4 mb-6 md:mt-6 md:mb-8">
        <SearchProduct
          className="mb-4"
          showFilters={true}
          onSubmit={onSearchSubmit}
          initialSearch={searchParam.search}
          initialSort={searchParam.sortBy}
          initialCategory={searchParam.filterBy}
        />
        <DeliveryLocationPicker />
      </BaseContainer>

      <BaseContainer className="pb-4 md:pb-8">
        {isArrayNotEmpty(customerApi?.searchProductList) ? (
          <StoreListing productList={customerApi?.searchProductList} />
        ) : (
          <div className="flex justify-center my-4">{customerApi.isRequestingSearchProduct ? <LoadingSpinner /> : <div>No Product Found</div>}</div>
        )}
      </BaseContainer>
    </>
  );
};

export default SearchPage;
